import { useContext, useMemo, useState } from 'react'
import { useFieldArray, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Add } from '@mui/icons-material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Grid,
  Typography,
} from '@mui/material'

import { getAvailableVariablesObject } from '@pages/GanttChart/components/GanttCreateOrEdit/components/GanttConfiguration/components/TimelineAdditionalFields/helpers'

import { Snackbar } from '@components/Snackbar'

import { PageContext } from '@gantt/components/GanttCreateOrEdit'
import { VariableItem } from '@gantt/components/GanttCreateOrEdit/components/GanttConfiguration/components/TimelineAdditionalFields/VariableItem'
import { ConfigField } from '@gantt/components/GanttCreateOrEdit/types'

type TimelineAdditionalFieldsProps = {
  barIndex: number
}

export const TimelineAdditionalFields = ({ barIndex }: TimelineAdditionalFieldsProps) => {
  const { control, watch } = useFormContext()
  const {
    fields,
    append,
    remove: handleRemoveItem,
  } = useFieldArray({
    name: `timeline.${barIndex}.additionalFields`,
    control,
  })
  const { t } = useTranslation()

  const timelineData = watch(`timeline.${barIndex}`)

  const [isShownCopyAlert, setIsShownCopyAlert] = useState(false)

  const availableVariablesObject: Record<string, ConfigField> = useMemo(
    () => getAvailableVariablesObject(timelineData),
    [watch()]
  )

  const { currentBarIndex } = useContext(PageContext)

  const watchedObject = watch(`timeline.${currentBarIndex}.data`)

  const handleAddItem = () => {
    append({})
  }

  async function handleWriteClipboardText(text: string) {
    if (navigator?.clipboard) {
      await navigator.clipboard.writeText(text)
      setIsShownCopyAlert(true)
    }
  }

  const handleCloseAlert = () => {
    setIsShownCopyAlert(false)
  }

  return (
    <>
      <Snackbar
        autoHideDuration={1500}
        message={t('ganttCreate.timelineForm.additionalFieldsForm.copyAlert')}
        open={isShownCopyAlert}
        type={'success'}
        onClose={handleCloseAlert}
      />
      <Grid container columnSpacing={4} xs={12}>
        <Grid item xs={5.5}>
          <Typography variant={'h5'}>
            {t('ganttCreate.timelineForm.additionalFieldsForm.variableHeader')}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant={'h5'}>
            {t('ganttCreate.timelineForm.additionalFieldsForm.pathHeader')}
          </Typography>
        </Grid>
        <Grid item xs={0.5}>
          <Button onClick={handleAddItem}>
            <Add fontSize='small' />
            {t('ganttCreate.common.add')}
          </Button>
        </Grid>
      </Grid>
      {fields.length === 0 ? (
        <Box
          alignItems={'center'}
          display={'flex'}
          justifyContent={'center'}
          sx={{ minHeight: '100px', height: '100%', userSelect: 'none' }}
        >
          <Typography>{t('ganttCreate.timelineForm.additionalFieldsForm.noFieldItems')}</Typography>
        </Box>
      ) : (
        fields.map((field, index) => {
          return (
            <VariableItem
              key={field.id}
              controllerNameField={`timeline.${barIndex}.additionalFields.${index}.field`}
              controllerNamePathArray={`timeline.${barIndex}.additionalFields.${index}.pathArray`}
              controllerNamePathStr={`timeline.${barIndex}.additionalFields.${index}.pathStr`}
              field={field}
              index={index}
              watchValue={watch(`timeline.${barIndex}.additionalFields.${index}`)}
              watchedObject={watchedObject}
              onRemove={handleRemoveItem}
            />
          )
        })
      )}
      <Box mb={1} mr={1}>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            {t('ganttCreate.timelineForm.additionalFieldsForm.availableVariables')}
          </AccordionSummary>
          <AccordionDetails>
            {Object.entries(availableVariablesObject).map(([key, value]) => (
              <Grid
                key={key}
                container
                columnSpacing={4}
                sx={{ cursor: 'copy' }}
                xs={12}
                onClick={() => handleWriteClipboardText(key)}
              >
                <Grid item xs={5.5}>
                  {key}
                </Grid>
                <Grid item xs={6}>
                  {value?.pathStr}
                </Grid>
              </Grid>
            ))}
          </AccordionDetails>
        </Accordion>
      </Box>
    </>
  )
}
