import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  ConfirmModal,
  DataGrid,
  GridCellParams,
  GridColumns,
} from '@microservices/wiskey-react-components'
import { Grid, SxProps, Theme } from '@mui/material'

import { generateDataGridActionsColumnForContext } from '@pages/ContextCreateOrEdit/helpers'

type TableWithActionsProps<T> = {
  columns: GridColumns<any>
  rows: T[]
  onDelete?: (id: number) => void
  onCopy?: (data: any) => void
  onEdit?: (code: number) => void
  onChangeDefault?: (data: any) => void
  onAdd?: (data: any) => void
  loading?: boolean
  tableHeight?: number
  skipDeleteModal?: boolean
  sx?: SxProps<Theme>
  sxGrid?: SxProps<Theme>
  headerNameActions?: string
  minWidthActions?: number
  disabledDelete?: boolean
  disabledChangeDefault?: boolean
  defaultGanttChartId?: number
}

export const TableWithActions = <T extends { id: string | number }>({
  columns,
  rows,
  onDelete,
  onCopy,
  onEdit,
  onChangeDefault,
  onAdd,
  loading,
  tableHeight,
  skipDeleteModal,
  sx,
  sxGrid,
  headerNameActions,
  minWidthActions,
  disabledDelete,
  disabledChangeDefault,
  defaultGanttChartId,
}: TableWithActionsProps<T>) => {
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false)
  const [currentRowId, setCurrentRowId] = useState<number>()
  const { t } = useTranslation()

  const handleCopy = (params: GridCellParams<T>) => {
    const { id, title } = params.row
    onCopy?.({ id, title })
  }

  const handleEdit = (params: GridCellParams<T>) => {
    onEdit?.(params.row.id)
  }

  const handleClickDelete = (params: GridCellParams<T>) => {
    if (params.row.id) {
      if (skipDeleteModal) {
        onDelete?.(params.row.id)

        return
      }

      setShowDeleteModal(true)
      setCurrentRowId(params.row.id)
    }
  }

  const handleDelete = () => {
    if (currentRowId) {
      onDelete?.(currentRowId)
      setShowDeleteModal(false)
    }
  }

  const handleChangeDefault = (params: GridCellParams<T>) => {
    onChangeDefault?.(params.row.id)
  }

  const handleAdd = (params: GridCellParams<T>) => {
    onAdd?.(params.row.id)
  }

  const handleClose = () => setShowDeleteModal(false)

  const columnsWithActions = useMemo(
    () => [
      ...columns,
      generateDataGridActionsColumnForContext({
        headerName: headerNameActions,
        minWidth: minWidthActions,
        disabledDelete,
        disabledChangeDefault,
        defaultGanttChartId,
        onAdd: onAdd ? handleAdd : undefined,
        onEdit: onEdit ? handleEdit : undefined,
        onCopy: onCopy ? handleCopy : undefined,
        onDelete: onDelete ? handleClickDelete : undefined,
        onChangeDefault: onChangeDefault ? handleChangeDefault : undefined,
      }),
    ],
    [columns, defaultGanttChartId, disabledDelete, disabledChangeDefault]
  )

  return (
    <Grid container sx={sx}>
      {showDeleteModal && (
        <ConfirmModal
          actionBtnText={t('modal.delete.btn')}
          containerStyle={{ px: 0.5, py: 0.5, borderRadius: 0 }}
          isShow={showDeleteModal}
          text={t('modal.delete.warning')}
          title={t('modal.delete.title')}
          onClose={handleClose}
          onConfirm={handleDelete}
        />
      )}
      <Grid container item>
        <DataGrid
          hideFooter
          columns={columnsWithActions}
          loading={loading}
          rows={rows}
          sx={{ ...sxGrid, borderRadius: 0 }}
          tableHeight={tableHeight}
        />
      </Grid>
    </Grid>
  )
}
