import { useContext, useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { MODEL_TYPE } from '@constants'
import { usePrompt } from '@hooks'
import { AutocompleteOption } from '@microservices/wiskey-react-components/dist/AutoComplete'
import { ObjetCommandDTO, SelectOption } from '@types'

import { PageContext } from '@pages/GanttChart/components/GanttCreateOrEdit'

import { useFetchAllFormQuery, useFetchObjectByCodeNoCacheMutation } from '@redux/api'

import { GanttActionType } from '@gantt/components/GanttCreateOrEdit/types'
import { tempCommandOptions } from '@gantt/constants'
import { getOptionByStr } from '@gantt/helpers'

import { getDefaultValue } from '../helpers'

import { useInputs } from './useInputs'

export const useAddGanttActionDialog = () => {
  const defaultValues = getDefaultValue()
  const methods = useForm<GanttActionType>({
    defaultValues,
  })

  const [key, setKey] = useState(false)

  const {
    formState: { isDirty },
    watch,
    handleSubmit,
    reset,
  } = methods
  const {
    onSetModalProperty,
    bindingValuesResourceAction,
    bindingValuesActionResourceEvents,
    objectCodeResource,
    onSetCurrentActionResource,
    currentRow,
    currentColumnsList,
  } = useContext(PageContext)

  const [fetchObjectByCode, { data: object }] = useFetchObjectByCodeNoCacheMutation({})

  const watchEventCode = watch('eventCode')
  const watchActionCode = watch('actionCode')

  const objectCode = objectCodeResource

  const {
    data: forms,
    isFetching: isFetchingAllForm,
    isLoading: isLoadingAllForm,
  } = useFetchAllFormQuery(
    { objectCode },
    {
      skip: !objectCodeResource,
    }
  )

  const isLoading = isFetchingAllForm || isLoadingAllForm

  usePrompt({ when: isDirty })

  useEffect(() => {
    if (objectCodeResource) {
      fetchObjectByCode({ code: objectCodeResource })
    }
  }, [objectCodeResource])

  const commandOptions = useMemo(() => {
    return object?.commands
      .map((command: ObjetCommandDTO) => ({
        id: command.code,
        label: command.code,
      }))
      .concat(tempCommandOptions)
  }, [object?.commands])

  const formCodeOptions: AutocompleteOption[] = useMemo(
    () =>
      forms?.data.map((form: { code: string; title: string }) => ({
        id: form.code,
        label: form.title,
      })) ?? [],
    [forms]
  )

  const actionTypeOptions: SelectOption[] = useMemo(
    () =>
      bindingValuesResourceAction?.map(cat => ({
        id: cat.id,
        name: cat.code,
      })) || [],
    [bindingValuesResourceAction]
  )

  const eventOptions: SelectOption[] = useMemo(
    () =>
      bindingValuesActionResourceEvents?.map(cat => ({
        id: cat.id,
        name: cat.code,
      })) || [],
    [bindingValuesActionResourceEvents]
  )

  const targetOptions = useMemo(
    () => currentColumnsList?.map(column => getOptionByStr(column.code, column.title)),
    [currentColumnsList]
  )

  const actionInputs = useInputs({
    actionTypeOptions,
    eventOptions,
    watchEventCode,
    targetOptions,
    methods,
    watchActionCode,
    commandOptions,
  })

  useEffect(() => {
    onSetModalProperty?.(MODEL_TYPE.GANTT_ACTION_RESOURCE)
  }, [])

  useEffect(() => {
    setKey(!key)
  }, [])

  useEffect(() => {
    if (
      currentRow &&
      formCodeOptions.length !== -1 &&
      !isLoading &&
      actionTypeOptions.length &&
      eventOptions.length
    ) {
      //todo  TS2339: Property target does not exist on type  TS2339: Property objectFormCode does not exist on type
      const objectForm = formCodeOptions.find(item => item.id === currentRow.objectFormCode)
      const target = targetOptions.find(item => item.id == currentRow.target)
      const dataToReset = structuredClone(currentRow)

      dataToReset.objectFormCode = objectForm
      dataToReset.target = target

      reset(dataToReset)
      setKey(!key)
    }
  }, [currentRow, isLoading, formCodeOptions, actionTypeOptions, eventOptions])

  const handleSave = (data: GanttActionType) => {
    const { id = Date.now(), code, title, actionCode, eventCode, actionField, target } = data

    onSetCurrentActionResource({
      id,
      code,
      title,
      actionCode,
      eventCode,
      target,
      actionField,
    })
  }

  return {
    isDirty,
    actionInputs,
    methods,
    handleSave,
    handleSubmit,
    isLoading,
    key,
    formCodeOptions,
    watchActionCode,
    commandOptions,
  }
}
