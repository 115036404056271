import { useMemo } from 'react'
import every from 'lodash/every'
import isObject from 'lodash/isObject'
import set from 'lodash/set'
import { ACTION_CODE, EVENT_CODE_GANTT } from '@constants'
import { getParamsStringSafe } from '@helpers'
import { Config } from '@microservices/gantt-react-component'

import {
  BindTypeJson,
  ContextMenuAction,
  GanttActionType,
  GanttFormValues,
} from '@gantt/components/GanttCreateOrEdit/types'

export const usePrepareConfigActions = (config: GanttFormValues): Config => {
  return useMemo(() => {
    if (!config) {
      return config
    }
    const preparedConfig: Config = structuredClone(config)

    config?.timeline?.forEach((timelineItem, index) => {
      timelineItem?.actions?.forEach((action: GanttActionType) => {
        switch (action.actionCode) {
          case ACTION_CODE.OPEN_CONTEXT_MENU: {
            const actionFieldValue: ContextMenuAction[] = getParamsStringSafe(
              action?.actionField as BindTypeJson
            )
            const isArrayOfObjects = actionFieldValue && every(actionFieldValue, isObject)

            if (action.eventCode === EVENT_CODE_GANTT.ON_BAR_RIGHT_CLICK && isArrayOfObjects) {
              set(preparedConfig, ['timeline', index, 'contextMenu'], actionFieldValue)
            }

            break
          }

          case ACTION_CODE.OPEN_TOOLTIP: {
            //todo next stage
            //on_tooltip_bar
            //on_tooltip_segment
            break
          }
        }
      })
    })

    config?.resource?.actions?.forEach((action: GanttActionType) => {
      switch (action.actionCode) {
        case ACTION_CODE.OPEN_CONTEXT_MENU: {
          const actionFieldValue: ContextMenuAction[] = getParamsStringSafe(
            action?.actionField as BindTypeJson
          )
          const isArrayOfObjects = actionFieldValue && every(actionFieldValue, isObject)

          if (action.eventCode === EVENT_CODE_GANTT.ON_ROW_RIGHT_CLICK && isArrayOfObjects) {
            set(preparedConfig, 'resource.contextMenu', actionFieldValue)
          }

          if (action.eventCode === EVENT_CODE_GANTT.ON_HEADER_RIGHT_CLICK && isArrayOfObjects) {
            set(preparedConfig, 'resource.headContextMenu', actionFieldValue)
          }

          break
        }

        case ACTION_CODE.OPEN_TOOLTIP: {
          if (action.eventCode === EVENT_CODE_GANTT.ON_TOOLTIP_HEADER) {
            set(preparedConfig, 'resource.headTooltip', action?.actionField)
          }

          if (action.eventCode === EVENT_CODE_GANTT.ON_TOOLTIP_ROW) {
            set(preparedConfig, 'resource.tooltip', action?.actionField)
          }

          break
        }
      }
    })

    preparedConfig.resource.columns =
      preparedConfig?.resource?.columns.filter(column => column.visibility) || []

    return preparedConfig
  }, [config])
}
