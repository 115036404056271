import { useContext, useEffect, useMemo } from 'react'
import { useForm } from 'react-hook-form'
import isString from 'lodash/isString'
import { BIND_TYPE, FIELD_VALUE_FORMAT, FIELD_VALUE_TYPE, SEARCH_FIELD_TYPE } from '@constants'
import { mapValueTypeToValueFormat } from '@helpers'
import { usePrompt } from '@hooks'

import { SearchFilterValue } from '@pages/ContextCreateOrEdit/components/SearchAssistant/types'

import { POSTCriteriaValueType, SelectOption } from '../../../../../../../types'
import { SearchAssistantConfigContext } from '../../../SearchAssistant'
import { SearchFilterForm } from '../AddSearchFilterDialog'
import { getDefaultValues } from '../helpers'

import { useInputs } from './useInputs'

export const useAddSearchFilterDialog = () => {
  const defaultValues = getDefaultValues()
  const methods = useForm<SearchFilterForm>({ defaultValues })
  const {
    handleSubmit,
    reset,
    formState: { isDirty },
  } = methods

  usePrompt({ when: isDirty })
  const {
    closeAddSearchFilterModal,
    objects,
    createOrUpdateCriteria,
    editCriteria,
    setCriteriaTable,
    searchAssistantId,
  } = useContext(SearchAssistantConfigContext)

  useEffect(() => {
    if (editCriteria) {
      reset({
        id: editCriteria.id,
        title: editCriteria.title,
        objectTitle: editCriteria.objectTitle,
        code: editCriteria.code,
        isPinning: editCriteria.isPinning || false,
        visibility: editCriteria.visibility,
        values: editCriteria.values.map(value => {
          return {
            id: value.id,
            name: value.name ? { id: value.name, label: value.name } : null,
            fieldType: value.fieldType ? { id: value.fieldType, label: value.fieldType } : null,
            objectValue: value.objectValue
              ? { id: value.objectValue, label: value.objectValue }
              : null,
            linkedObjectCode: value.linkedObjectCode || '',
            isDefaultPlaceholder: Boolean(value.isDefaultPlaceholder),
            isGroupRelated: Boolean(value.isGroupRelated),
            placeholderValue: value.placeholderValue || '',
            placeholderStart: value.placeholderStart || '',
            placeholderEnd: value.placeholderEnd || '',
            dropDownListCode: value.dropDownList
              ? { id: value.dropDownList.code, label: value.dropDownList.title }
              : null,
            dropDownWindowCode: value.dropDownWindow
              ? { id: value.dropDownWindow.code, label: value.dropDownWindow.title }
              : null,
            dropDownListFilters: value.dropDownListFilters || [],
            dropDownWindowFilters: value.dropDownWindowFilters || [],
            valueType: value.valueType,
            asCheckbox:
              value.valueFormat === FIELD_VALUE_FORMAT.NUMBER ||
              value.valueFormat === FIELD_VALUE_FORMAT.BOOLEAN,
            asDuration: value.valueFormat === FIELD_VALUE_FORMAT.TIME,
          }
        }),
      })
    }
  }, [editCriteria])

  const objectOptions = useMemo((): SelectOption[] => {
    return objects ? objects.map(object => ({ id: object.code, name: object.title })) : []
  }, [objects])

  const prepareValues = (
    rawValues: SearchFilterValue[],
    objectCode: string
  ): POSTCriteriaValueType[] => {
    return rawValues.map(value => {
      const isLinkedField = value.valueType === FIELD_VALUE_TYPE.OBJ_PK_LINK
      // Берется value.name.id, т.к. в name хранится объект филда, где id это его objectCode (ref или не ref)
      // Если поле не реф, то прокидывается рутовый (критерии) objectCode
      const linkedObjectCodeWhenCreate =
        isLinkedField && isString(value.name?.id) ? value.name.id : objectCode

      const transformedValue = {
        id: value.id,
        name: value.name ? value.name.label : '',
        objectValue: value.objectValue ? value.objectValue.label : undefined,
        fieldType: value.fieldType ? (value.fieldType.label as SEARCH_FIELD_TYPE) : undefined,
        linkedObjectCode: value.linkedObjectCode
          ? value.linkedObjectCode
          : linkedObjectCodeWhenCreate,
        dropDownListCode: value.dropDownListCode ? String(value.dropDownListCode.id) : undefined,
        dropDownWindowCode: value.dropDownWindowCode
          ? String(value.dropDownWindowCode.id)
          : undefined,
        placeholderValue: value.placeholderValue || '',
        dropDownListFilters: value.dropDownListFilters || [],
        dropDownWindowFilters: value.dropDownWindowFilters || [],
        placeholderStart: value.placeholderStart || '',
        placeholderEnd: value.placeholderEnd || '',
        isDefaultPlaceholder: Boolean(value.isDefaultPlaceholder),
        isGroupRelated: value.isGroupRelated,
        asCheckbox: value.asCheckbox,
        asDuration: value.asDuration,
        valueType: value.valueType,
      }

      const isLinkedObjectValue = transformedValue.objectValue && transformedValue.linkedObjectCode

      const isDateValue = Boolean(transformedValue.fieldType)

      const {
        name,
        placeholderValue,
        isDefaultPlaceholder,
        placeholderEnd,
        placeholderStart,
        isGroupRelated,
        asCheckbox,
        asDuration,
        valueType,
      } = transformedValue

      const valueFormat = {
        valueFormat: transformedValue
          ? mapValueTypeToValueFormat(valueType, asCheckbox, asDuration)
          : null,
      }

      // На данный момент могут быть такие name, как
      // bound (ENUM)
      // или
      // _id (PK)
      // Для корректности данных, текст в скобках отправлять не нужно
      const nameForValue = name.split(' ')[0]

      return {
        name: nameForValue,
        placeholderValue,
        isDefaultPlaceholder,
        placeholderStart,
        placeholderEnd,
        id: transformedValue.id,
        dropDownListFilters: isLinkedObjectValue ? transformedValue.dropDownListFilters : undefined,
        dropDownWindowFilters: isLinkedObjectValue
          ? transformedValue.dropDownWindowFilters
          : undefined,
        dropDownListCode: isLinkedObjectValue ? transformedValue.dropDownListCode : undefined,
        dropDownWindowCode: isLinkedObjectValue ? transformedValue.dropDownWindowCode : undefined,
        objectValue: isLinkedObjectValue ? transformedValue.objectValue : undefined,
        linkedObjectCode: transformedValue.linkedObjectCode,
        fieldType: isDateValue ? transformedValue.fieldType : undefined,
        bindType: BIND_TYPE.FIELD,
        isGroupRelated,
        ...valueFormat,
      }
    })
  }

  const handleSave = handleSubmit((data: SearchFilterForm) => {
    const currentObject = objectOptions.find(object => object.name === data.objectTitle)
    const currentObjectCode = currentObject?.id as string | undefined

    if (currentObjectCode && searchAssistantId) {
      createOrUpdateCriteria({
        id: data.id,
        searchAssistantId,
        code: data.code,
        objectCode: currentObjectCode,
        objectTitle: data.objectTitle,
        title: data.title,
        isPinning: data.isPinning,
        values: prepareValues(data.values, currentObjectCode),
        visibility: data.visibility,
      })
        .unwrap()
        .then(result => {
          if (data.id) {
            setCriteriaTable(prev =>
              prev.map(template => (template.id === result.id ? result : template))
            )
            closeAddSearchFilterModal()
            reset()

            return
          }
          setCriteriaTable(prev => {
            const newData = [...prev]
            newData.unshift(result)

            return newData
          })
          closeAddSearchFilterModal()
          reset()
        })
    }
  })

  const searchFilterInputs = useInputs({
    objectOptions,
  })

  return {
    isDirty,
    handleSave,
    methods,
    searchFilterInputs,
  }
}
