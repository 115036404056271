import { createContext, FC } from 'react'
import { FormProvider } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import check from '@assets/images/check.svg'
import { GENERATOR_INPUT_TYPE } from '@constants'
import { ConfirmModal, FormInput } from '@microservices/wiskey-react-components'
// import { DataGrid } from '@microservices/wiskey-react-components/DataGrid'
import { DataGrid } from '@microservices/wiskey-react-components'
import { Box, Button, Grid } from '@mui/material'

import { CustomSvgIcon } from '@components/CustomSvgIcon'

import { PageContentLayout } from '@layouts/PageContentLayout'

import { AddDefaultSettingsDialog } from './components/AddDefaultSettingsDialog/AddDefaultSettingsDialog'
import { AddSearchFilterDialog } from './components/AddSearchFilterDialog'
import { useSearchAssistant } from './hooks'
import { SearchAssistantConfigContextType } from './types'

export const SearchAssistantConfigContext = createContext<SearchAssistantConfigContextType>(
  {} as SearchAssistantConfigContextType
)

export const SearchAssistant: FC = () => {
  const { t } = useTranslation()

  const { state, data, handlers, mutations } = useSearchAssistant()

  const {
    showDeleteModal,
    viewsOptions,
    templateTable,
    editCriteria,
    page,
    currentSort,
    showAddSearchFilterModal,
    showDefaultSetting,
    isEditMode,
    isLoadingUpdateVisibility,
    watchedView,
  } = state

  const {
    objects,
    isLoading,
    loadingData,
    countPage,
    methods,
    objectsValues,
    columns,
    contextSA,
    contextId,
  } = data

  const {
    handleOpenAddSearchFilterModal,
    handleCloseAddSearchFilterModal,
    handleCloseDefaultSettings,
    handleDeleteCriteria,
    handlePage,
    handleSetSorting,
    handleVisibility,
    handleChangeObjectCode,
    handleSetCriteriaTable,
    handleNew,
    handleClose,
    handleDelete,
    handleChangeModelSort,
    handleShowDefaultSettings,
  } = handlers

  const { createOrUpdateCriteria } = mutations

  const contextValue = {
    showAddSearchFilterModal,
    showDefaultSetting,
    openAddSearchFilterModal: handleOpenAddSearchFilterModal,
    closeAddSearchFilterModal: handleCloseAddSearchFilterModal,
    closeDefaultSettings: handleCloseDefaultSettings,
    objects,
    templates: templateTable,
    createOrUpdateCriteria,
    onDelete: handleDeleteCriteria,
    editCriteria,
    isLoadingRow: isLoading,
    countPage,
    onChangePage: handlePage,
    currentPage: page,
    currentSort,
    onSetSorting: handleSetSorting,
    setCriteriaTable: handleSetCriteriaTable,
    onUpdateVisibility: handleVisibility,
    loadingData,
    onChangeObjectCode: handleChangeObjectCode,
    searchAssistantId: contextSA?.id,
    contextId,
  }

  return (
    <SearchAssistantConfigContext.Provider value={contextValue}>
      <Box pt={1}>
        {showAddSearchFilterModal && <AddSearchFilterDialog isEdit={isEditMode} />}
        {showDefaultSetting && <AddDefaultSettingsDialog />}
        {/*<PageTitle*/}
        {/*  end={*/}
        {/*    <Button sx={{ mr: 1 }} variant='outlined' onClick={handleShowDefaultSettings}>*/}
        {/*      {t('searchAssistant.defaultSetting.button')}*/}
        {/*    </Button>*/}
        {/*  }*/}
        {/*/>*/}
        <PageContentLayout>
          <Box p={1}>
            <Grid container spacing={1}>
              <Grid item flex={1}>
                <FormProvider {...methods}>
                  <FormInput
                    autocompleteOptions={viewsOptions}
                    inputType={GENERATOR_INPUT_TYPE.AUTOCOMPLETE}
                    label={t('searchAssistant.defaultView')}
                    labelSx={{ mr: 1, minWidth: 130, width: 130 }}
                    name='view'
                    placeholder={t('searchAssistant.defaultView')}
                    size='small'
                    renderAutocompleteOption={(props, option) =>
                      option.id === watchedView?.id ? (
                        <li {...props} key={option.id} style={{ paddingLeft: '28px' }}>
                          <Grid item alignSelf={'end'} left={3} position={'absolute'}>
                            <CustomSvgIcon imgStyle={{}} src={check} />
                          </Grid>
                          {option.label}
                        </li>
                      ) : (
                        <li {...props} key={option.id} style={{ paddingLeft: '28px' }}>
                          {option.label}
                        </li>
                      )
                    }
                  />
                  <FormInput
                    autocompleteOptions={objectsValues}
                    inputType={GENERATOR_INPUT_TYPE.AUTOCOMPLETE}
                    label={t('label.objectsField')}
                    labelSx={{ mr: 1, minWidth: 130, width: 130 }}
                    name='objects'
                    placeholder={t('placeholder.objectsField')}
                    size='small'
                  />
                </FormProvider>
              </Grid>
              <Grid item>
                <Grid container direction={'column'} spacing={1}>
                  <Grid item>
                    <Button fullWidth variant='outlined' onClick={handleShowDefaultSettings}>
                      {t('searchAssistant.defaultSetting.button')}
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button fullWidth variant={'contained'} onClick={handleNew}>
                      {t('searchAssistant.addBtnText')}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            {showDeleteModal && (
              <ConfirmModal
                actionBtnText={t('modal.delete.btn')}
                containerStyle={{ px: 0.5, py: 0.5, borderRadius: 0 }}
                isShow={showDeleteModal}
                text={t('modal.delete.warning')}
                title={t('modal.delete.title')}
                onClose={handleClose}
                onConfirm={handleDelete}
              />
            )}
            <Grid container>
              <DataGrid
                disableMultipleColumnsSorting
                hideFooter
                visibility
                columns={columns}
                disableColumnResize={false}
                loading={loadingData}
                pageCount={countPage}
                rows={templateTable}
                sortModel={currentSort}
                sortingMode={'server'}
                sx={{ borderRadius: 0 }}
                onDisableVisibilityCheckbox={() => isLoadingUpdateVisibility}
                onSortModelChange={handleChangeModelSort}
                onVisibility={handleVisibility}
              />
            </Grid>
          </Box>
        </PageContentLayout>
      </Box>
    </SearchAssistantConfigContext.Provider>
  )
}
