import { FC } from 'react'
import isNumber from 'lodash/isNumber'
import { FIELD_VALUE_TYPE } from '@constants'
import { Skeleton } from '@mui/material'

import { useFetchEnumForObjectQuery } from '@redux/api'

import { EnumCellType } from '../../types'

export const EnumCell: FC<EnumCellType> = ({
  valueType,
  value,
  isDialogWindow,
  objectCode,
  name,
}) => {
  const { data: enumData, isLoading } = useFetchEnumForObjectQuery({ code: objectCode, name })

  const getEnumData = () => {
    if (!enumData) {
      return []
    }

    const getId = (value: number | string) => {
      switch (valueType) {
        case FIELD_VALUE_TYPE.INTEGER:
          return Number(value)
        default:
          return value
      }
    }

    return enumData.map(data => ({
      id: getId(data['value']),
      label: data['desc'] as string,
    }))
  }

  const data = getEnumData()

  if (isLoading) {
    return (
      <Skeleton
        sx={{ width: '100%', height: isDialogWindow ? 25 : 40, borderRadius: '4px' }}
        variant={'rectangular'}
      />
    )
  }

  return <>{data.length > 0 && isNumber(value) ? data[value].label : value}</>
}
