import { ChangeEvent, useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ConfirmModal } from '@microservices/wiskey-react-components'
import { Button, Grid } from '@mui/material'
import { CellContext, ColumnDef } from '@tanstack/react-table'

import { AddActionDialog } from '@pages/EntityCreateOrEdit/components/AddActionDialog'
import { AddColumnDialog } from '@pages/EntityCreateOrEdit/components/AddColumnDialog'
import { AddFieldDialog } from '@pages/FormCreateOrEdit/components/AddFieldDialog'
import { AddGanttActionDialog } from '@pages/GanttChart/components/GanttCreateOrEdit/components/AddGanttActionDialog'
import { AddGanttActionTimelinesDialog } from '@pages/GanttChart/components/GanttCreateOrEdit/components/AddGanttActionTimelinesDialog'
import { AddGanttFieldDialog } from '@pages/GanttChart/components/GanttCreateOrEdit/components/AddGanttFieldDialog'
import { AddSegmentDialog } from '@pages/GanttChart/components/GanttCreateOrEdit/components/GanttConfiguration/components/AddSegmentDialog'

import { SearchInTable } from '@components/SwitchTable/SearchInTable'

import { ENTITY, MODAL_TYPE, MODEL_TYPE } from '@constants'

import { ModalType, ShareActionType } from '../../types'
import { ReactTable, SortItem } from '../ui/ReactTable'

type SwitchTableProps<T> = {
  columns: ColumnDef<T>[]
  rows: T[]
  btnText?: string
  loading?: boolean
  type: ShareActionType
  page?: number
  isCrud?: boolean
  pageSize?: number
  btnDisabled?: boolean
  disablePagination?: boolean
  useDragAndDrop?: boolean
  disableSort?: boolean
  manualSorting?: boolean
  sortModel?: SortItem[] | null
  showActionsColumn?: boolean
  showVisibilityColumn?: boolean
  onChangePage?: (event: ChangeEvent<unknown>, value: number) => void
  onEdit?: (props: CellContext<T, unknown>) => void
  onHide?: (props: CellContext<T, unknown>, checked: boolean) => void
  onDelete?: (id: string | number) => void
  onDropChange?: (rows: T[]) => T[]
  onShowDialog: (type: ModalType, id?: string | number, isAction?: boolean) => void
  showDialog: boolean
  showSearch?: boolean
  disabledVisibilityColumn?: boolean
}

const getDialogType = (type: ShareActionType): MODEL_TYPE => {
  switch (type) {
    case MODEL_TYPE.VIEW:
      return MODEL_TYPE.COLUMN
    case MODEL_TYPE.FORM:
      return MODEL_TYPE.FIELD
    case MODEL_TYPE.ACTION:
      return MODEL_TYPE.ACTION
    case MODEL_TYPE.GANTT:
      return MODEL_TYPE.GANTT
    case MODEL_TYPE.GANTT_ACTION_RESOURCE:
      return MODEL_TYPE.GANTT_ACTION_RESOURCE
    case MODEL_TYPE.GANTT_ACTION_TIMELINES:
      return MODEL_TYPE.GANTT_ACTION_TIMELINES
    case MODEL_TYPE.GANTT_SEGMENT:
      return MODEL_TYPE.GANTT_SEGMENT
    default:
      return MODEL_TYPE.COLUMN
  }
}

export const SwitchTable = <T extends { id: string | number }>({
  columns,
  rows,
  btnText,
  type,
  btnDisabled,
  loading,
  showDialog,
  useDragAndDrop,
  disablePagination,
  disableSort,
  manualSorting,
  showActionsColumn,
  showVisibilityColumn,
  onEdit,
  onHide,
  onDropChange,
  onDelete,
  onShowDialog,
  showSearch,
  disabledVisibilityColumn,
}: SwitchTableProps<T>) => {
  const { t } = useTranslation()
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false)
  const [currentRowId, setCurrentRowId] = useState<number | string | null>()
  const [globalFilter, setGlobalFilter] = useState('')
  const [sortModelState, setSortModelState] = useState(null)
  const dialogType: MODEL_TYPE = getDialogType(type)

  const handleClickDelete = (params?: CellContext<T, unknown>) => {
    if (params?.row.original.id && !showDeleteModal) {
      setCurrentRowId(params?.row.original.id)
      setShowDeleteModal(true)

      return
    }
    if (currentRowId) {
      onDelete?.(currentRowId)
      setCurrentRowId(null)
      setShowDeleteModal(false)
    }
  }

  const isShowSearch = (type: ShareActionType | ENTITY): boolean => {
    return (Object.values(ENTITY).includes(type as ENTITY) && showSearch) || type === 'forms'
  }

  const handleClose = () => setShowDeleteModal(false)

  const handleSetSearch = (pattern: string) => {
    setGlobalFilter(pattern)
  }

  const handleSortModelChange = (model: SortItem[] | null) => setSortModelState(model)

  const renderDialog = useCallback(() => {
    switch (dialogType) {
      case 'field':
        return <AddFieldDialog />
      case 'action':
        return <AddActionDialog />
      case 'column':
        return <AddColumnDialog />
      case 'gantt':
        return <AddGanttFieldDialog />
      case 'gantt_action_resource':
        return <AddGanttActionDialog />
      case 'gantt_action_timelines':
        return <AddGanttActionTimelinesDialog />
      case 'gantt_segment':
        return <AddSegmentDialog />
    }
  }, [dialogType])

  return (
    <Grid container flexDirection='column' spacing={1}>
      <Grid
        container
        item
        alignItems='center'
        justifyContent='space-between'
        sx={{ pl: '0px !important' }}
      >
        <Grid item>
          {isShowSearch(type) && (
            <SearchInTable searchValue={globalFilter} setGlobalFilter={handleSetSearch} />
          )}
        </Grid>
        {btnText && (
          <Grid item>
            <Button
              disabled={btnDisabled}
              variant='contained'
              onClick={() => onShowDialog(MODAL_TYPE.CREATE)}
            >
              {btnText}
            </Button>
          </Grid>
        )}
      </Grid>
      {showDialog && renderDialog()}
      {showDeleteModal && (
        <ConfirmModal
          actionBtnText={t('modal.delete.btn')}
          containerStyle={{ px: 0.5, py: 0.5, borderRadius: 0 }}
          isShow={showDeleteModal}
          text={t('modal.delete.warning')}
          title={t('modal.delete.title')}
          onClose={handleClose}
          onConfirm={handleClickDelete}
        />
      )}
      <Grid item sx={{ pl: '0px !important' }}>
        <ReactTable
          columns={columns}
          data={rows}
          disablePagination={disablePagination}
          disableSort={disableSort}
          disabledVisibilityColumn={disabledVisibilityColumn}
          globalFilter={globalFilter}
          loading={loading}
          manualSorting={manualSorting}
          setGlobalFilter={setGlobalFilter}
          showActionsColumn={showActionsColumn}
          showVisibilityColumn={showVisibilityColumn}
          sortModel={sortModelState}
          useDragAndDrop={useDragAndDrop}
          onDelete={handleClickDelete}
          onDropChange={onDropChange}
          onEdit={onEdit}
          onHide={onHide}
          onSortModelChange={handleSortModelChange}
        />
      </Grid>
    </Grid>
  )
}
