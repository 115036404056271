import { createContext, FC } from 'react'
import { Outlet } from 'react-router-dom'
import { ENTITY, VIEW_ACTION_COLUMNS, VIEW_COLUMNS } from '@constants'

import { PageContentLayout } from '@layouts/PageContentLayout'

import { useEntityCreateOrEdit } from './hooks'
import { PageContextType } from './types'

export const PageContext = createContext<PageContextType>({} as PageContextType)

type EntityCreateOrEditProps = {
  type: ENTITY
}

export const EntityCreateOrEdit: FC<EntityCreateOrEditProps> = ({ type }) => {
  const { state, data, handlers, mutations } = useEntityCreateOrEdit({ type })
  const { currentEntity, currentRow, showDialog, modalType } = state

  const {
    entityCode,
    objectCode,
    objects,
    object,
    bindingValues,
    bindingValuesTypes,
    bindingValuesColumnsTypes,
    bindingValuesColumns,
    bindingValuesAction,
    bindingValuesActionEvents,
    actionRows,
    mainRows,
    entityIsLoading,
    entityUpdateLoading,
    entityCreateLoading,
    isSuccessCreate,
    isSuccessUpdate,
    initialColumnHeaderStyles,
    initialColumnTextStyles,
    methods,
    watchUseParameters,
    watchQuickSearch,
    isDirtyUseParametersModal,
  } = data

  const {
    handleSetModalProperty,
    handleSetObjectCode,
    handleAddEntity,
    handleAddColumn,
    handleDeleteColumn,
    handleDeleteAction,
    handleEditColumn,
    handleCloseModal,
    handleOpenDialog,
    handleCancel,
    handleSetModelCode,
  } = handlers

  const { createAction, editAction, updateColumnOrder, updateVisibilityColumn } = mutations

  const contextValue = {
    entityCode,
    objectCode: objectCode || '',
    currentEntity,
    entityIsLoading,
    entityCreateLoading: entityUpdateLoading || entityCreateLoading,
    currentRow: currentRow,
    showDialog: showDialog,
    modalType: modalType,
    isSuccessAddModel: isSuccessUpdate || isSuccessCreate,
    objects: objects,
    rawObjectFields: object?.fields,
    bindingValues,
    bindingValuesTypes,
    bindingValuesColumnsTypes,
    bindingValuesColumns,
    bindingValuesAction,
    bindingValuesActionEvents,
    actionTable: { rows: actionRows, columns: VIEW_ACTION_COLUMNS },
    mainTable: { rows: mainRows, columns: VIEW_COLUMNS },
    addEntity: handleAddEntity,
    addColumn: handleAddColumn,
    editColumn: handleEditColumn,
    createAction,
    editAction,
    updateColumnOrder,
    updateVisibilityColumn,
    deleteColumn: handleDeleteColumn,
    deleteAction: handleDeleteAction,
    onCancel: handleCancel,
    onSetObjectCode: handleSetObjectCode,
    onCloseModal: handleCloseModal,
    onShowDialog: handleOpenDialog,
    onSetModalProperty: handleSetModalProperty,
    onSetModelCode: handleSetModelCode,
    initialColumnHeaderStyles,
    initialColumnTextStyles,
    methods,
    watchUseParameters,
    watchQuickSearch,
    isDirtyUseParametersModal,
  }

  return (
    <PageContext.Provider value={contextValue}>
      <PageContentLayout>
        <Outlet />
      </PageContentLayout>
    </PageContext.Provider>
  )
}
