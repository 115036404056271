import { useContext, useState } from 'react'
import { FormProvider } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import isEqual from 'lodash/isEqual'
import { DEFAULT_COMMON_PARAMETERS, GENERATOR_INPUT_TYPE } from '@constants'
import { Button, ConfirmModal, FormGenerator } from '@microservices/wiskey-react-components'
import { Grid, Typography } from '@mui/material'

import { CommonParametersFormType } from '@pages/Parameters/hooks'
import { ParametersContext } from '@pages/Parameters/Parameters'

export const CommonParameters = () => {
  const { t } = useTranslation()
  const [isShowResetConfirmModal, setShowResetConfirmModal] = useState(false)

  const { methods, isEdit, initialCommonParametersData, onResetCommonParams } =
    useContext(ParametersContext)

  const { handleSubmit } = methods

  const handleResetCommonParams = () => {
    onResetCommonParams()
    setShowResetConfirmModal(false)
  }

  const handleClickResetCommonParams = (data: CommonParametersFormType) => {
    if (isEqual(data, DEFAULT_COMMON_PARAMETERS)) {
      return
    }
    setShowResetConfirmModal(true)
  }

  return (
    <Grid container item flexDirection={'column'} pt={1}>
      <ConfirmModal
        actionBtnText={t('parameters.resetModal.actionBtnText')}
        containerStyle={{ px: 0.5, py: 0.5, borderRadius: 0 }}
        isShow={isShowResetConfirmModal}
        text={t('parameters.resetModal.text')}
        title={t('parameters.resetModal.title')}
        onClose={() => setShowResetConfirmModal(false)}
        onConfirm={handleResetCommonParams}
      />
      <Typography variant='h6'>{t('parameters.common.title')}</Typography>
      <FormProvider {...methods}>
        <FormGenerator
          mainGridSpacing={0}
          inputs={[
            {
              inputType: GENERATOR_INPUT_TYPE.AUTOCOMPLETE,
              name: 'numberOfWindows',
              autocompleteOptions: initialCommonParametersData.numberOfWindows,
              label: t('parameters.common.numberOfWindows'),
              labelSx: { flex: 1, maxWidth: 200 },
              sx: { flex: 2 },
              disabled: !isEdit,
            },
            {
              inputType: GENERATOR_INPUT_TYPE.AUTOCOMPLETE,
              name: 'timezone',
              autocompleteOptions: initialCommonParametersData.timezone,
              label: t('parameters.common.timeZone'),
              labelSx: { flex: 1, maxWidth: 200 },
              sx: { flex: 2 },
              disabled: !isEdit,
            },
            {
              inputType: GENERATOR_INPUT_TYPE.AUTOCOMPLETE,
              name: 'pagination',
              autocompleteOptions: initialCommonParametersData.pagination,
              label: t('parameters.common.pagination'),
              labelSx: { flex: 1, maxWidth: 200 },
              sx: { flex: 2 },
              disabled: !isEdit,
            },
            {
              inputType: GENERATOR_INPUT_TYPE.AUTOCOMPLETE,
              name: 'ganttChart',
              autocompleteOptions: initialCommonParametersData.ganttChart,
              label: t('parameters.common.ganttChart'),
              labelSx: { flex: 1, maxWidth: 200 },
              sx: { flex: 2 },
              disabled: !isEdit,
            },
          ]}
        />
      </FormProvider>
      <Grid item alignSelf={'flex-end'}>
        <Button
          disabled={!isEdit}
          variant='outlined'
          onClick={handleSubmit(handleClickResetCommonParams)}
        >
          {t('parameters.resetButton')}
        </Button>
      </Grid>
    </Grid>
  )
}
