import { useEffect } from 'react'
import { ObjectWithRecordsType } from 'src/types'

import { useFetchObjectDataWithFixedKey } from '@components/DisplayForm/hooks/useFetchObjectDataWithFixedKey'

type UseFetchRowByUpdateProps = {
  objectsWithDirtyRecords: ObjectWithRecordsType
  objectCode: string
  objectId?: string
  globalId: string
  // fetchRow: MutationType<GETObjectDataRecordParams, ObjectDataRecord>
}

export const useFetchRowByUpdate = ({
  objectsWithDirtyRecords,
  objectCode,
  objectId,
  globalId,
}: UseFetchRowByUpdateProps) => {
  const [fetchRow] = useFetchObjectDataWithFixedKey(globalId)

  useEffect(() => {
    const object = objectsWithDirtyRecords[objectCode]

    if (object && Object.keys(object).length) {
      if (objectCode && objectId) {
        fetchRow({ objectCode, objectId })
      }
      return
    }
  }, [objectsWithDirtyRecords, objectCode, objectId])
}
