import { useTranslation } from 'react-i18next'
import { useAppDispatch } from '@hooks'

import { showMessage } from '@redux/reducers/snackbar.reducer'

export const useCopy = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  async function handleWriteClipboardText(text: string) {
    try {
      await navigator.clipboard.writeText(text)
      dispatch(
        showMessage({
          type: 'success',
          text: t('ganttCreate.timelineForm.additionalFieldsForm.copyAlert'),
        })
      )
    } catch (error) {
      console.error(error)
    }
  }

  return handleWriteClipboardText
}
