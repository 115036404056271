import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ModalWrapper } from '@microservices/wiskey-react-components'
import CheckCircle from '@mui/icons-material/CheckCircle'
import { Badge, Box, Button, Grid } from '@mui/material'

import { CustomSvgIcon } from '@components/CustomSvgIcon'

import { ICONS } from '../constants'

type IconMenu = {
  selectedIcon: string | null | undefined
  isShow: boolean
  onClose: () => void
  onChange: (event: React.SyntheticEvent<EventTarget> | null, value: string | null) => void
}

export const IconMenu: FC<IconMenu> = ({ selectedIcon, isShow, onClose, onChange }) => {
  const { t } = useTranslation()

  const [icon, setIcon] = useState<string | null>(selectedIcon || null)

  const handleChangeIcon = (value: string) => {
    if (value === icon) {
      setIcon(null)

      return
    }

    setIcon(value)
  }

  const handleSave = () => {
    onChange(null, icon)
    onClose()
  }

  return (
    <ModalWrapper
      disabledSave={selectedIcon === icon}
      isShow={isShow}
      title={t('iconPickerMenu')}
      containerStyle={{
        minWidth: '365px !important',
        maxWidth: '365px !important',
      }}
      onClose={onClose}
      onSave={handleSave}
    >
      <Grid container alignItems='center' gap={1.5} paddingY={1}>
        {Object.values(ICONS).map((i, index) => (
          <Grid key={index} item alignItems='center' justifyContent='center'>
            <Button
              size='small'
              sx={{ p: '1px', minWidth: 40 }}
              title={i.name}
              onClick={() => handleChangeIcon(i.name)}
            >
              <Badge
                invisible={icon !== i.name}
                badgeContent={
                  <Box sx={{ background: 'white', borderRadius: '50%' }}>
                    <CheckCircle sx={{ height: 14, width: 14, color: 'green' }} />
                  </Box>
                }
              >
                <CustomSvgIcon src={i.src} sx={{ width: '100%', height: 26 }} />
              </Badge>
            </Button>
          </Grid>
        ))}
      </Grid>
    </ModalWrapper>
  )
}
