import { FC } from 'react'
import { Menu, SxProps, Theme } from '@mui/material'

import NestedMenu, { CursorPosition, NestedMenuProps } from '@components/NestedMenu'

export const selectorMenuContent = 'menuContent' + Date.now()

export const sxNestedMenu: SxProps<Theme> = {
  '& .MuiPaper-root': {
    width: 'maxContent',
    boxSizing: 'borderBox',
    boxShadow: '1px 1px 3px black',
    border: '1px solid gray',
    '& .MuiList-root': {
      py: 0,
    },
    '& .MuiListItemButton-root': {
      py: '3px',
      pl: '40px',
      pr: '15px',
      '&:hover': {
        bgcolor: theme => theme.palette.background.hovered,
      },
    },
    '& .MuiDivider-root': {
      mr: '4px',
      my: '3px',
    },
  },
}

export const MenuPortal: FC<
  Omit<NestedMenuProps, 'position'> & { position?: CursorPosition | null }
> = ({ position, onClose, ...props }) => {
  if (!position) {
    return null
  }

  return (
    <Menu
      anchorPosition={position}
      anchorReference='anchorPosition'
      open={Boolean(position)}
      sx={sxNestedMenu}
      onClose={onClose}
    >
      <NestedMenu onClose={onClose} {...props} />
    </Menu>
  )
}
