import { useEffect } from 'react'
import { ActionData } from '@microservices/gantt-react-component'

import { dispatchSelfUpdate } from '@pages/ConfiduredGantt/customEvent'

import { useUpdateGanttBoxMutation } from '@redux/api/ganttChart.api'

import { GanttFormValues } from '@gantt/components/GanttCreateOrEdit/types'

export const useMutateBox = (config: GanttFormValues) => {
  const [updateGantBox, response] = useUpdateGanttBoxMutation()

  useEffect(() => {
    if (response?.originalArgs && ['rejected', 'fulfilled'].includes(response.status)) {
      dispatchSelfUpdate(response?.originalArgs)
    }
  }, [response?.status])

  return (params: ActionData) => {
    const changes: Record<string, string | null | undefined> = {}

    Object.values(params.changes ?? {})?.forEach(
      item => item.pathStr && (changes[item.pathStr] = item.value)
    )

    const timelineConfig = config?.timeline?.find(
      item => item.data?.id === params?.data?.objectCode
    )
    const pathId = timelineConfig?.key?.field?.field ?? ''

    updateGantBox({
      viewId: config.viewId,
      timelineId: params?.data?.[pathId],
      objectCode: params?.data?.objectCode,
      changes,
    })
  }
}
