import { useContext, useEffect } from 'react'
import { UseFormReset } from 'react-hook-form'
import { ModalType } from '@types'

import { PageContext } from '@pages/GanttChart/components/GanttCreateOrEdit'
import { SectionType } from '@pages/GanttChart/components/GanttCreateOrEdit/types'

type UseAddGanttSegmentDialogProps = {
  reset: UseFormReset<SectionType>
  onDuplicateError: (error: boolean) => void
  modalType: ModalType
}

export const useAddGanttSegmentDialog = ({
  reset,
  onDuplicateError,
  modalType,
}: UseAddGanttSegmentDialogProps) => {
  const { onSetCurrentSegment, currentRow, currentSectionsList } = useContext(PageContext)

  useEffect(() => {
    if (currentRow) {
      reset(currentRow)
    }
  }, [currentRow])

  const handleSave = (data: SectionType) => {
    const { title, tooltip, duration, datetimeStart, datetimeEnd, background, link, labels } = data

    const newData = {
      id: link.systemName,
      title,
      tooltip,
      duration,
      datetimeStart,
      datetimeEnd,
      background,
      link,
      labels,
    }

    const findDuplicates = currentSectionsList.filter(section => section.id === newData.id)
    const isDuplicateError = findDuplicates.length >= 1 && modalType !== 'edit'

    onDuplicateError(isDuplicateError)

    !isDuplicateError && onSetCurrentSegment(newData)
  }
  return {
    handleSave,
  }
}
