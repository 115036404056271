import { FormValues } from '../types'

export const getDefaultValues = (): FormValues => {
  return {
    id: '',
    method: 'GET',
    code: '',
    title: '',
    object: null,
    viewIds: [],
    url: '',
    dataType: 'json',
    isJsTitle: false,
    titleJsValue: '',
    contextMenu: null,
  }
}
