import { FC, useContext } from 'react'
import { FormProvider } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { ConfirmModal, FormGenerator } from '@microservices/wiskey-react-components'
import { Box, Button, Grid, Typography } from '@mui/material'

import { PageContext } from '@pages/GanttChart/components/GanttCreateOrEdit'

import { BindTypeInputs } from '@components/BindTypeInputs'
import { ModalWrapper } from '@components/ModalWrapper'
import { TextSettings } from '@components/TextSettings'

import { BIND_TYPE_INPUTS_FORM_TYPE } from '@gantt/components/GanttCreateOrEdit/types'

import { useAddGanttFieldDialog } from './hooks/useAddGanttFieldDialog'

export const AddGanttFieldDialog: FC = () => {
  const { showDialog, handleCloseModal, modalType, watchedObjectResource } = useContext(PageContext)

  const {
    isDirty,
    isShowResetConfirmModal,
    columnInputs,
    columnHeaderSettingsRef,
    columnTextSettingsRef,
    initialColumnHeaderStyles,
    initialColumnTextStyles,
    watchUseParameters,
    otherColumnInputs,
    methods,
    handleHeaderExternalDirty,
    handleTextExternalDirty,
    handleClickReset,
    handleSave,
    handleResetParams,
    handleSetShowResetConfirmModal,
  } = useAddGanttFieldDialog()

  const { t } = useTranslation()

  return (
    <ModalWrapper
      btnText={t('ganttCreate.common.apply')}
      disabledSave={!isDirty}
      isShow={showDialog}
      title={`${t(`addDialog.${modalType}`)} Column`}
      onClose={() => handleCloseModal(isDirty)}
      onSave={handleSave}
    >
      <Box maxHeight={500} overflow={'auto'} pb={2} pr={2} pt={2}>
        <ConfirmModal
          actionBtnText={t('parameters.resetModal.actionBtnText')}
          containerStyle={{ px: 0.5, py: 0.5, borderRadius: 0 }}
          isShow={isShowResetConfirmModal}
          text={t('parameters.resetModal.text')}
          title={t('parameters.resetModal.title')}
          onClose={() => handleSetShowResetConfirmModal(false)}
          onConfirm={handleResetParams}
        />
        <FormProvider {...methods}>
          <form>
            <FormGenerator inputs={columnInputs} />
            <BindTypeInputs
              bindTypeOptions={[]}
              containerName={'field'}
              formType={BIND_TYPE_INPUTS_FORM_TYPE.RESOURCE}
              isDisabled={!watchedObjectResource}
              watchedObject={watchedObjectResource}
            />
            <FormGenerator inputs={otherColumnInputs} />
            <Typography mb={2} variant='h6'>
              {t('addDialog.parameters')}
            </Typography>
            <TextSettings
              ref={columnHeaderSettingsRef}
              backgroundSettings={false}
              borderSettings={false}
              initialTextState={initialColumnHeaderStyles ? initialColumnHeaderStyles : undefined}
              isEdit={watchUseParameters}
              labelPlacement='top'
              labelText={t('parameters.visual.columnHeader')}
              onSetExternalDirty={handleHeaderExternalDirty}
            />
            <TextSettings
              ref={columnTextSettingsRef}
              borderSettings={false}
              initialTextState={initialColumnTextStyles ? initialColumnTextStyles : undefined}
              isEdit={watchUseParameters}
              labelPlacement='top'
              labelText={t('parameters.visual.columnText')}
              onSetExternalDirty={handleTextExternalDirty}
            />
            <Grid container item justifyContent={'flex-end'} my={2}>
              <Button variant='outlined' onClick={handleClickReset}>
                {t('parameters.resetButton')}
              </Button>
            </Grid>
          </form>
        </FormProvider>
      </Box>
    </ModalWrapper>
  )
}
