import { FC, useMemo, useState } from 'react'
import { AutocompleteOption } from 'src/types/common'
import more from '@assets/images/more.svg'
import { generalFieldStylesByMode, getAutoCompletePopperSxByDisplayMethod } from '@helpers'
import { AutoComplete, FormLabel, TextField } from '@microservices/wiskey-react-components'
import { Box, Grid, InputProps } from '@mui/material'

import { CustomSvgIcon } from '@components/CustomSvgIcon'

import { useFetchGroupsForObjectQuery } from '@redux/api'

export type GroupAutocompleteProps = {
  value?: AutocompleteOption | string
  placeholder: string
  disabled: boolean
  onChange?: (details: AutocompleteOption[]) => void
  name: string
  objectCode: string
  isDialogWindow: boolean
  inputProps?: InputProps
}

export const GroupAutocomplete: FC<GroupAutocompleteProps> = ({
  name,
  objectCode,
  value,
  placeholder,
  disabled,
  onChange,
  isDialogWindow,
  inputProps,
  ...props
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const { data: groupsData, isLoading } = useFetchGroupsForObjectQuery(
    { objectCode },
    { skip: !isOpen }
  )
  // const { data: allGroups } = useFetchAllGroupsQuery()
  // console.log('ALL GROUPS: ', allGroups)

  const options: AutocompleteOption[] = useMemo(() => {
    if (!groupsData) {
      return []
    }

    return groupsData.map(groupData => {
      return {
        id: groupData.id,
        label: groupData.title,
      }
    })
  }, [groupsData])

  const handleChange = (details: AutocompleteOption[]) => {
    onChange?.(details)
  }

  const handleDropDownOpen = () => setIsOpen(true)

  const handleDropDownClose = () => setIsOpen(false)

  const renderInput = () => {
    return (
      <AutoComplete
        fullWidth
        multiple
        disabled={disabled}
        isOptionEqualToValue={(value, option) => value.id === option.id}
        loading={isLoading}
        open={isOpen}
        options={options}
        size='small'
        value={value || []}
        onChange={handleChange}
        onClose={handleDropDownClose}
        onOpen={handleDropDownOpen}
        {...props}
        componentsProps={{
          popper: {
            sx: {
              zIndex: theme => theme.zIndex.tooltip,
              ...getAutoCompletePopperSxByDisplayMethod(true),
            },
          },
        }}
        popupIcon={
          <Box height={'100%'} width={24}>
            <CustomSvgIcon src={more} sx={{ fontSize: '1rem' }} />
          </Box>
        }
        renderInput={params => (
          <TextField
            {...params}
            disabled={disabled}
            placeholder={placeholder}
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <>
                  {inputProps?.startAdornment}
                  {params.InputProps.startAdornment}
                </>
              ),
            }}
            sx={theme => ({
              ...generalFieldStylesByMode(theme, undefined, name, false, isDialogWindow),
              minHeight: '24px',
              '.MuiInputBase-root': {
                pr: '57px !important',
                '& .MuiChip-root': {
                  height: '19px',
                },
              },
              '& .MuiAutocomplete-endAdornment': {
                right: '0px !important',
              },
            })}
          />
        )}
        renderOption={(props, option) => (
          <li {...props} key={option.id} style={{ paddingRight: 0 }} title={option.label}>
            <Box sx={{ overflowX: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
              {option.label}
            </Box>
          </li>
        )}
        sx={{
          minHeight: '24px',
          '& .MuiButtonBase-root': {
            transform: 'none',
            padding: '2px',
          },
          '.MuiAutocomplete-popupIndicator': { marginRight: '10px' },
        }}
      />
    )
  }

  return (
    <Grid container item justifyContent='space-between'>
      <FormLabel control={renderInput()} name={name || ''} />
    </Grid>
  )
}
