import { FC, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import i18next from 'i18next'
import { GENERATOR_INPUT_TYPE } from '@constants'
import { FormInput } from '@microservices/wiskey-react-components'
import { Box, Typography } from '@mui/material'

import { ObjectShortDTO } from '../../types'

type DataSourceProps = {
  objects?: ObjectShortDTO[]
  disabledObj: boolean
  loading: boolean
}

export const DataSource: FC<DataSourceProps> = ({ objects = [], disabledObj, loading }) => {
  const { t } = useTranslation()

  const objectOptions = useMemo(
    () => objects.map(obj => ({ id: obj.code, label: obj.title })),
    [objects]
  )

  return (
    <>
      <Box>
        <Typography variant='h5' my={0.5}>
          {t('viewsCreate.datasource')}
        </Typography>
      </Box>
      <FormInput
        name={'object'}
        inputType={GENERATOR_INPUT_TYPE.AUTOCOMPLETE}
        label={i18next.t('label.objectTitle')}
        placeholder={i18next.t('placeholder.selectObject')}
        autocompleteOptions={objectOptions}
        rules={{
          required: true,
        }}
        disabled={disabledObj}
        loading={loading}
      />
    </>
  )
}
