import { useCallback } from 'react'
import { getParamsStringSafe } from '@helpers'

import { ActionParams } from '@pages/ConfiduredGantt/hooks/useGetHandlerMap'
import { useMutateBox } from '@pages/ConfiduredGantt/hooks/useMutateBox'

import { GanttActionType, GanttFormValues } from '@gantt/components/GanttCreateOrEdit/types'

export const useExecuteCommand = (config: GanttFormValues, path: string, id: string) => {
  const mutateBox = useMutateBox(config)

  return useCallback(
    (action: GanttActionType, data: ActionParams, details: any, actions: GanttActionType[]) => {
      const command = getParamsStringSafe(action.actionField)
      console.log('EXECUTE_COMMAND', action.eventCode, command)

      if (command?.id === 'STORAGE') {
        mutateBox(data)
      }

      return true
    },
    [config?.code, id, path]
  )
}
