import { FC, useContext, useRef, useState } from 'react'
import { FormProvider } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import isEqual from 'lodash/isEqual'
import {
  DEFAULT_GLOBAL_COLUMN_HEADER_PARAMETERS,
  DEFAULT_GLOBAL_COLUMN_TEXT_PARAMETERS,
  DEFAULT_INITIAL_STATE_COLUMN_HEADER_PARAMETERS,
  DEFAULT_INITIAL_STATE_COLUMN_TEXT_PARAMETERS,
  GENERATOR_INPUT_TYPE,
} from '@constants'
import { usePrompt } from '@hooks'
import {
  Button,
  ConfirmModal,
  FormInput,
  ModalWrapper,
} from '@microservices/wiskey-react-components'
import { Box, Grid, Typography } from '@mui/material'

import { PageContext } from '@pages/EntityCreateOrEdit/EntityCreateOrEdit'

import {
  ExternalTextSettings,
  GetTextParamsHandle,
  TextParams,
  TextSettings,
  TextSettingsStateType,
} from '@components/TextSettings'

type ParametersModalProps = {
  isShow: boolean
  onClose: () => void
  onSave: (columnHeaderData: TextParams, columnTextData: TextParams) => void
}

export const ParametersModal: FC<ParametersModalProps> = ({ isShow, onClose, onSave }) => {
  const columnHeaderSettingsRef = useRef<GetTextParamsHandle>(null)
  const columnTextSettingsRef = useRef<GetTextParamsHandle>(null)

  const [isDirtyHeader, setDirtyHeader] = useState(false)
  const [isDirtyText, setDirtyText] = useState(false)
  const [isShowResetConfirmModal, setShowResetConfirmModal] = useState(false)

  const { t } = useTranslation()

  const {
    initialColumnHeaderStyles,
    initialColumnTextStyles,
    methods,
    watchUseParameters,
    watchQuickSearch,
    isDirtyUseParametersModal,
  } = useContext(PageContext)

  const isDirty = isDirtyUseParametersModal || isDirtyHeader || isDirtyText

  const { setValue } = methods

  usePrompt({ when: isDirty })

  const handleSave = () => {
    const columnHeaderData = columnHeaderSettingsRef.current?.getTextParams()
    const columnTextData = columnTextSettingsRef.current?.getTextParams()

    if (!columnHeaderData || !columnTextData) {
      return
    }

    onSave(columnHeaderData, columnTextData)
  }

  const handleCloseParameters = () => {
    if (isDirty && !confirm(t('notifications.leave'))) {
      return
    }

    onClose()
  }

  const handleHeaderExternalDirty = (object: ExternalTextSettings) => {
    if (object && initialColumnTextStyles) {
      const isDirty = !isEqual(object, initialColumnHeaderStyles)

      setDirtyHeader(isDirty)
    }
  }

  const handleTextExternalDirty = (object: ExternalTextSettings) => {
    if (object && initialColumnTextStyles) {
      const isDirty = !isEqual(object, initialColumnTextStyles)

      setDirtyText(isDirty)
    }
  }

  const handleResetParams = () => {
    handleSetInitialColumnStyles(
      DEFAULT_INITIAL_STATE_COLUMN_HEADER_PARAMETERS,
      DEFAULT_INITIAL_STATE_COLUMN_TEXT_PARAMETERS
    )
    setValue('hasQuickSearch', true, { shouldDirty: true })
    setValue('useParameters', false, { shouldDirty: true })

    setShowResetConfirmModal(false)
  }

  const handleClickReset = () => {
    const columnHeaderData = columnHeaderSettingsRef.current?.getTextParams()
    const columnTextData = columnTextSettingsRef.current?.getTextParams()

    if (
      isEqual(columnHeaderData, DEFAULT_GLOBAL_COLUMN_HEADER_PARAMETERS) &&
      isEqual(columnTextData, DEFAULT_GLOBAL_COLUMN_TEXT_PARAMETERS)
    ) {
      return
    }

    setShowResetConfirmModal(true)
  }

  const handleSetInitialColumnStyles = (
    columnHeaderStyles: TextSettingsStateType,
    columnTextStyles: TextSettingsStateType
  ) => {
    columnHeaderSettingsRef.current?.setValues(columnHeaderStyles)
    columnTextSettingsRef.current?.setValues(columnTextStyles)
  }

  return (
    <ModalWrapper
      hideDivider
      containerStyle={{ px: 0.5, py: 0.5, borderRadius: 0 }}
      disabledSave={!isDirty}
      isShow={isShow}
      title={<Typography variant='h6'>{t('viewsCreate.parameters.title')}</Typography>}
      onClose={handleCloseParameters}
      onSave={handleSave}
    >
      <ConfirmModal
        actionBtnText={t('parameters.resetModal.actionBtnText')}
        containerStyle={{ px: 0.5, py: 0.5, borderRadius: 0 }}
        isShow={isShowResetConfirmModal}
        text={t('parameters.resetModal.text')}
        title={t('parameters.resetModal.title')}
        onClose={() => setShowResetConfirmModal(false)}
        onConfirm={handleResetParams}
      />
      <Box>
        <FormProvider {...methods}>
          <FormInput
            inputType={GENERATOR_INPUT_TYPE.CHECKBOX}
            label={t('columnInputs.quickSearch.label')}
            labelPlacement={'end'}
            name={'hasQuickSearch'}
          />
          <FormInput
            formInputContainerSx={{ mb: 0 }}
            inputType={GENERATOR_INPUT_TYPE.CHECKBOX}
            label={t('columnInputs.useParameters.label')}
            labelPlacement={'end'}
            name={'useParameters'}
          />
          <TextSettings
            ref={columnHeaderSettingsRef}
            backgroundSettings={false}
            borderSettings={false}
            initialTextState={initialColumnHeaderStyles ? initialColumnHeaderStyles : undefined}
            isEdit={watchUseParameters}
            labelPlacement='top'
            labelText={t('parameters.visual.columnHeader')}
            onSetExternalDirty={handleHeaderExternalDirty}
          />
          <TextSettings
            ref={columnTextSettingsRef}
            borderSettings={true}
            initialTextState={initialColumnTextStyles ? initialColumnTextStyles : undefined}
            isEdit={watchUseParameters}
            labelPlacement='top'
            labelText={t('parameters.visual.columnText')}
            onSetExternalDirty={handleTextExternalDirty}
          />
        </FormProvider>
        <Grid container item justifyContent={'flex-end'}>
          <Button sx={{ mt: 0.5 }} variant='outlined' onClick={handleClickReset}>
            {t('parameters.resetButton')}
          </Button>
        </Grid>
      </Box>
    </ModalWrapper>
  )
}
