import { Component, createRef, RefObject } from 'react'
import { Position, Rnd } from 'react-rnd'
import { ObjectDataRecord, PassedFormData } from 'src/types'
import copy from '@assets/images/copy.svg'
import underline from '@assets/images/underline.svg'
import { DRAGGABLE_FORM_SIZES } from '@constants'
import { Close as CloseIcon, UnfoldMore as UnfoldMoreIcon } from '@mui/icons-material'
import { Box, Grid, IconButton, Paper, Stack, Theme, Typography } from '@mui/material'

import { CustomSvgIcon } from '@components/CustomSvgIcon'
import { DisplayForm } from '@components/DisplayForm'

type DraggableFormProps = {
  passedParams: PassedFormData
  title: string
  rowId: string
  formDataId: number
  onSetSelectedRowId: (value: string) => void
  onRemoveSamePageForm: (formDataId: number, isDirty?: boolean) => void
  isSelected: boolean
  theme: Theme
  disableDragging: boolean
  onSetAllObjectData: (newObjectData: ObjectDataRecord) => void
}

type DraggableFormState = {
  isOpen: boolean
  storedHeight: number
  storedWidth: number
  isDragging: boolean
  isFullscreen: boolean
  isOverlayShown: boolean
  positionBeforeFullscreen: Position | null
  scrollLeftBeforeDragging: number | null
  isDirty: boolean
}

export class DraggableForm extends Component<DraggableFormProps, DraggableFormState> {
  rnd: Rnd | null | undefined
  wrapperRef: RefObject<HTMLDivElement> = createRef()
  containerRef: RefObject<HTMLDivElement> = createRef()
  windowResizeObserver: ResizeObserver | null = null
  buttonResizeRef: RefObject<HTMLButtonElement> = createRef()

  constructor(props: DraggableFormProps) {
    super(props)
    this.blockScrollingWhileDragging = this.blockScrollingWhileDragging.bind(this)

    this.state = {
      isOpen: true,
      storedHeight: 0,
      storedWidth: 0,
      isDragging: false,
      isFullscreen: false,
      isOverlayShown: false,
      positionBeforeFullscreen: null,
      scrollLeftBeforeDragging: null,
      isDirty: false,
    }
  }

  updateSize() {
    if (!this.rnd) {
      return
    }

    this.rnd.updateSize(this.getDimentions())
  }

  blockScrollingWhileDragging() {
    if (this.state.isDragging && this.containerRef.current) {
      const scrollTop = this.containerRef.current.scrollTop
      const scrollLeft = this.state.scrollLeftBeforeDragging ?? this.containerRef.current.scrollLeft
      this.containerRef.current.scrollTo(scrollLeft, scrollTop)
    }
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside)

    this.setState(prev => ({
      ...prev,
      storedHeight: DRAGGABLE_FORM_SIZES.OPENED_HEIGHT,
      storedWidth: DRAGGABLE_FORM_SIZES.OPENED_WIDTH,
    }))

    this.updateSize()

    this.containerRef.current?.addEventListener('scroll', this.blockScrollingWhileDragging)
  }

  componentDidUpdate(prevProps: DraggableFormProps, prevState: DraggableFormState) {
    const formWasClosedBeforeSelection = !prevState.isOpen
    const formWasNotSelected = !prevProps.isSelected

    // Эта логика нужна для кейса, когда мы в таблице выбираем форму, которая была свернута.
    // Если это так, то мы ее раскрываем
    if (this.props.isSelected && formWasClosedBeforeSelection && formWasNotSelected) {
      this.handleOpen()
    }

    this.updateSize()
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside)
    this.containerRef.current?.removeEventListener('scroll', this.blockScrollingWhileDragging)
  }

  getDimentions() {
    return {
      width: this.state.isOpen
        ? this.state.storedWidth
          ? this.state.storedWidth
          : DRAGGABLE_FORM_SIZES.OPENED_WIDTH
        : DRAGGABLE_FORM_SIZES.CLOSED_WIDTH,
      height: this.state.isOpen
        ? this.state.storedHeight
          ? this.state.storedHeight
          : DRAGGABLE_FORM_SIZES.OPENED_HEIGHT
        : DRAGGABLE_FORM_SIZES.CLOSED_HEIGHT,
    }
  }

  handleSetBodyOverflow(value: 'visible' | 'hidden') {
    const body = document.querySelector('body') as HTMLElement

    body.style.overflow = value
  }

  handleWindowResizeWhenFullscreen() {
    this.rnd?.updateSize({ width: window.innerWidth, height: window.innerHeight })
    this.setState(prev => ({
      ...prev,
      storedHeight: window.innerHeight,
      storedWidth: window.innerWidth,
    }))
  }

  handleToggleFullscreen(value: boolean) {
    const body = document.querySelector('body') as HTMLElement

    if (!this.windowResizeObserver) {
      this.windowResizeObserver = new ResizeObserver(() => this.handleWindowResizeWhenFullscreen())
    }

    this.windowResizeObserver.observe(body)

    if (value) {
      this.rnd?.updatePosition({ x: 0, y: 0 })
      window.scrollTo(0, 0)
      this.handleSetBodyOverflow('hidden')
    }

    if (!value && this.state.positionBeforeFullscreen) {
      this.rnd?.updatePosition(this.state.positionBeforeFullscreen)
      this.handleSetBodyOverflow('visible')

      this.windowResizeObserver && this.windowResizeObserver.unobserve(body)
    }

    this.setState(prev => ({
      ...prev,
      storedHeight: value ? window.innerHeight : DRAGGABLE_FORM_SIZES.OPENED_HEIGHT,
      storedWidth: value ? window.innerWidth : DRAGGABLE_FORM_SIZES.OPENED_WIDTH,
      positionBeforeFullscreen: value ? (this.rnd?.getDraggablePosition() as Position) : null,
      isFullscreen: value,
      isOverlayShown: value,
    }))
    this.buttonResizeRef?.current?.blur()
  }

  handleClickOutside(event: MouseEvent) {
    const target = event.target as Node

    if (target === null) {
      return
    }

    if (this.wrapperRef && this.wrapperRef.current && !this.wrapperRef.current.contains(target)) {
      this.props.onSetSelectedRowId('')
    }
  }

  updateDirty = (value: boolean) => {
    this.setState({ isDirty: value })
  }

  handleMinimize() {
    this.handleSetBodyOverflow('visible')

    this.setState({ isOpen: false, isOverlayShown: false })
  }

  handleOpen() {
    this.setState({ isOpen: true })

    if (this.state.isFullscreen) {
      this.rnd?.updatePosition({ x: 0, y: 0 })
      window.scrollTo(0, 0)
      this.handleSetBodyOverflow('hidden')
      this.setState({ isOverlayShown: true })
    }
  }

  handleSelect() {
    if (this.props.isSelected) {
      return
    }

    this.props.onSetSelectedRowId(this.props.rowId)
  }

  handleSetVirtualScrollerPointerEvents(value: 'auto' | 'none') {
    const viewScroller = document.querySelector('.MuiDataGrid-virtualScroller') as HTMLElement

    if (viewScroller) {
      viewScroller.style.pointerEvents = value
    }
  }

  handleDragStart() {
    this.handleSetVirtualScrollerPointerEvents('none')

    const scrollLeft = this.containerRef.current?.scrollLeft ?? null
    this.setState({ isDragging: true, scrollLeftBeforeDragging: scrollLeft })
  }

  handleDragStop() {
    this.handleSetVirtualScrollerPointerEvents('auto')

    this.setState({ isDragging: false, scrollLeftBeforeDragging: null })
    this.handleSelect()
  }

  handleResizeStop(ref: HTMLElement) {
    this.setState({
      storedWidth: Number(ref.style.width.substring(0, ref.style.width.length - 2)),
      storedHeight: Number(ref.style.height.substring(0, ref.style.height.length - 2)),
    })
  }

  handleContainerScroll() {
    // Нужно для скрытия дропдауна у autocomplete
    // Решение было получено в ходе решения этой проблемы:
    // https://github.com/mui/material-ui/issues/25564
    const documentActiveElement = document.activeElement as HTMLElement

    if (documentActiveElement.classList.contains('MuiAutocomplete-input')) {
      documentActiveElement.blur()
    }
  }

  render() {
    const { title, passedParams, onSetAllObjectData } = this.props

    const formTitle = passedParams.formCode ? `${title} - ${passedParams.formCode}` : title

    return (
      <>
        {/** Оверлей, чтобы в режиме полного экрана при задержке при изменении масштаба страницы
         * не было видно содержимого страницы вне формы */}
        {this.state.isOverlayShown && (
          <Paper
            sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100vh',
              zIndex: theme => theme.zIndex.fab,
            }}
          />
        )}

        <Box
          ref={this.wrapperRef}
          sx={{
            '.draggable-form__handler': {
              cursor: this.state.isOverlayShown ? 'default' : 'move !important',
            },
          }}
          onClick={() => this.handleSelect()}
        >
          <Rnd
            ref={c => {
              this.rnd = c
            }}
            bounds='window'
            cancel='.disable-dragging-container'
            disableDragging={this.props.disableDragging}
            dragHandlerClassName='.draggable-form__handler'
            enableResizing={this.state.isOpen && !this.state.isFullscreen}
            minHeight={DRAGGABLE_FORM_SIZES.CLOSED_HEIGHT}
            default={{
              x: 0,
              y: 0,
              ...this.getDimentions(),
            }}
            style={{
              backgroundColor: this.props.theme.palette.background.paper,
              boxShadow: '6px 6px 6px 0px #00000040',
              overflow: 'hidden',
              cursor: 'default !important',
              zIndex: this.props.isSelected
                ? this.props.theme.zIndex.modal + 1
                : this.props.theme.zIndex.modal,
            }}
            onDragStart={() => this.handleDragStart()}
            onDragStop={() => this.handleDragStop()}
            onResizeStop={(e, direction, ref, delta, position) => this.handleResizeStop(ref)}
          >
            <Stack sx={{ width: '100%', height: '100%' }}>
              <Box
                sx={{
                  color: this.props.theme.palette.color.formWindowHeader,
                  background: this.props.theme.palette.primary.main,
                  height: !this.state.isOpen ? '100%' : 'auto',
                  pl: 2,
                  pr: 1,
                  py: 0.1,
                }}
              >
                <Grid
                  container
                  alignItems='center'
                  className='draggable-form__handler'
                  justifyContent='space-between'
                >
                  <Grid item>
                    <Typography variant='subtitle1'>{formTitle}</Typography>
                  </Grid>
                  <Grid item>
                    {this.state.isOpen ? (
                      <>
                        <IconButton
                          onClick={() => {
                            this.handleMinimize()
                          }}
                        >
                          <CustomSvgIcon src={underline} sx={{ width: 20, height: 20 }} />
                        </IconButton>

                        {this.state.isFullscreen ? (
                          <IconButton
                            onClick={() => {
                              this.handleToggleFullscreen(false)
                            }}
                          >
                            <CustomSvgIcon src={copy} sx={{ width: 20, height: 20 }} />
                          </IconButton>
                        ) : (
                          <IconButton
                            ref={this.buttonResizeRef}
                            onClick={() => {
                              this.handleToggleFullscreen(true)
                            }}
                          >
                            <CustomSvgIcon src={copy} sx={{ width: 20, height: 20 }} />
                          </IconButton>
                        )}
                      </>
                    ) : (
                      <IconButton
                        onClick={() => {
                          this.handleOpen()
                        }}
                      >
                        <UnfoldMoreIcon
                          fontSize='small'
                          sx={{
                            transform: 'rotateZ(45deg)',
                            color: this.props.theme.palette.color.formWindowHeader,
                          }}
                        />
                      </IconButton>
                    )}
                    <IconButton
                      onClick={() =>
                        this.props.onRemoveSamePageForm(this.props.formDataId, this.state.isDirty)
                      }
                    >
                      <CloseIcon
                        fontSize='small'
                        sx={{ color: this.props.theme.palette.color.formWindowHeader }}
                      />
                    </IconButton>
                  </Grid>
                </Grid>
              </Box>
              <Box
                ref={this.containerRef}
                className='disable-dragging-container'
                sx={{
                  overflowY: 'auto',
                  height: '100%',
                  cursor: 'default !important',
                  border: theme => `2px solid ${theme.palette.primary.main}`,
                  borderTop: 0,
                }}
                onScroll={() => this.handleContainerScroll()}
              >
                {this.state.isOpen && (
                  <Box sx={{ p: 2, height: '100%' }}>
                    <DisplayForm
                      passedParams={this.props.passedParams}
                      onChangeDirty={this.updateDirty}
                      // onSetAllObjectData={onSetAllObjectData}
                    />
                  </Box>
                )}
              </Box>
            </Stack>
          </Rnd>
        </Box>
      </>
    )
  }
}
