export const REGEX = {
  LINE_BREAK: /[\r\n]+/g,
  SPACE: /\s+/g,
  ENGLISH_LETTERS: /^[a-zA-Z]+$/,
  RUSSIAN_LETTERS: /[\u0401\u0451\u0410-\u044f]/g,
  MODEL_CODE_REPLACE_PATTERN: /[^a-zA-Z0-9_-]/g,
  CONTAINER_ID: /(containerId):/g,
  REPLACE_ONLY_NUMBER: /(?!^-)\D/,
  ONLY_NUMBER: /^-?\d*\d*$/,
  ASSISTANT_FORBIDDEN_CHARS: /^[^()]*$/,
  HEX_COLORS: /^#?[0-9A-Fa-f]{0,6}$/,
  REF_BY_ID: /_ref/,
  FIELD_NAMES: /^(?:(.+?)_ref_)?(.+)$/,
}
