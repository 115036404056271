import { Fragment, useMemo, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { FIELD_VALUE_TYPE, GENERATOR_INPUT_TYPE } from '@constants'
import { FormInput } from '@microservices/wiskey-react-components'
import ClearIcon from '@mui/icons-material/Clear'
import { Box, Button, Divider, IconButton, SxProps } from '@mui/material'
import { AutocompleteOption } from '@types'

import { EmbeddedObject } from '@pages/GanttChart/components/GanttCreateOrEdit/types'

import { FieldPickerController } from '@components/hookFormControllers/FieldPickerController'
import { ModalWrapper } from '@components/ModalWrapper'

type ModalRefPathPickerProps = {
  isDisabled: boolean
  isRequired?: boolean
  isEdit?: boolean
  isFlatOptions?: boolean
  hasClearInInput?: boolean
  hasField?: boolean
  name: string
  label?: string
  embeddedObjectPickerControllerName: string
  placeHolder?: string
  sx?: SxProps
  fieldName?: string
  prefix?: string
  suffix?: string
  watchedObject?: AutocompleteOption<string> | null
  currentValue?: {
    pathStr: string
    pathArray: EmbeddedObject[]
    field: string
  }
  onChange?: () => void
  onSave?: () => void
  onRemove?: () => void
}

//todo почему не в src/components/ ?
export const ModalRefPathPicker = ({
  isDisabled,
  isEdit = false,
  isFlatOptions,
  isRequired = false,
  hasField = false,
  hasClearInInput = false,
  name,
  label,
  embeddedObjectPickerControllerName,
  placeHolder,
  sx,
  fieldName,
  prefix = '',
  suffix = '',
  watchedObject,
  currentValue,
  onChange,
  onSave,
  onRemove,
}: ModalRefPathPickerProps) => {
  const methods = useFormContext()
  const { watch, setValue, trigger } = methods
  const [show, setShow] = useState(false)
  const { t } = useTranslation()

  const watchedRefPath = watch(name)

  const handleClear = () => {
    setValue(name, '', { shouldDirty: true })
    fieldName && setValue(fieldName, '', { shouldDirty: true })
    setValue(
      embeddedObjectPickerControllerName,
      [{ objectCode: watchedObject?.id, refValueType: FIELD_VALUE_TYPE.OBJ_PK_LINK, field: null }],
      { shouldDirty: true }
    )
    onRemove?.()
  }

  const getRefPath = (embeddedObjects: EmbeddedObject[], name: string) => {
    if (embeddedObjects) {
      const sum = embeddedObjects
        .map(obj => obj.field)
        .filter(item => item)
        .join('.')
      setValue(name, sum)

      return sum
    }
  }

  const objList = watch(embeddedObjectPickerControllerName) as EmbeddedObject[]

  const getFieldName = (fieldName: string, fieldValue: string, prefix?: string) => {
    const fieldPlace = [prefix, fieldValue, suffix].filter(s => !!s).join('_')

    return setValue(`${fieldName}`, fieldPlace)
  }

  const fieldVal = useMemo(() => {
    return objList
      ?.map(obj => obj.field)
      .filter(item => item)
      .join('')
  }, [objList])

  const handleSave = () => {
    trigger(embeddedObjectPickerControllerName).then(value => {
      if (value) {
        getRefPath(objList, name)

        if (fieldName && hasField) {
          getFieldName(fieldName, fieldVal, prefix)
        }

        setShow(false)
        onSave?.()
      }

      onChange?.()
    })
  }

  const handleOpenModal = () => {
    setShow(true)
  }

  const handleCloseModal = () => {
    setValue(name, currentValue?.pathStr)
    setValue(embeddedObjectPickerControllerName, currentValue?.pathArray)
    fieldName && setValue(fieldName, currentValue?.field)

    setShow(false)
  }

  return (
    <Fragment key={String(name)}>
      <Box alignItems={'center'} display={'flex'} justifyContent={'space-between'} sx={sx}>
        <Box flex={1}>
          <FormInput
            inputType={GENERATOR_INPUT_TYPE.INPUT}
            label={label}
            name={name}
            placeholder={placeHolder ?? t('placeholder.refPath')}
            readOnly={true}
            rules={{
              required: isRequired,
            }}
            textFieldInputProps={{
              ...(hasClearInInput && {
                endAdornment: (
                  <IconButton
                    disabled={!watchedRefPath}
                    size={'small'}
                    sx={{ width: 36.5, py: '10px' }}
                    onClick={handleClear}
                  >
                    <ClearIcon fontSize={'small'} />
                  </IconButton>
                ),
              }),
            }}
          />
        </Box>
        <Box display={'flex'} flex={0.1} mb={1}>
          <Button
            disabled={isDisabled}
            variant='contained'
            sx={{
              margin: '0 15px',
            }}
            onClick={handleOpenModal}
          >
            {watchedRefPath ? t('ganttCreate.refEdit') : t('ganttCreate.refPick')}
          </Button>
          {!hasClearInInput && (
            <IconButton size={'small'} sx={{ width: 36.5 }} onClick={handleClear}>
              <ClearIcon fontSize={'small'} />
            </IconButton>
          )}
        </Box>
        {hasField && fieldName && (
          <Box hidden flex={0.4}>
            <FormInput
              inputType={GENERATOR_INPUT_TYPE.INPUT}
              name={fieldName}
              placeholder={placeHolder ?? t('placeholder.refPath')}
              readOnly={true}
              sx={{ margin: 0 }}
            />
          </Box>
        )}
      </Box>
      {show && (
        <ModalWrapper
          btnText={t('ganttCreate.common.apply')}
          isShow={show}
          onClose={handleCloseModal}
          onSave={handleSave}
        >
          <Box display={'flex'} justifyContent={'space-between'} mt={'15px'}>
            <Box width={'20%'}>{t('ganttCreate.modalPathPickerObjectsColumn')}</Box>
            <Divider flexItem orientation='vertical' />
            <Box width={'70%'}>{t('ganttCreate.modalPathPickerFieldsColumn')}</Box>
          </Box>
          <Box py={'15px'}>
            <FieldPickerController
              isEdit={isEdit}
              isFlatOptions={isFlatOptions}
              name={embeddedObjectPickerControllerName}
              watchedObject={watchedObject}
              onChange={onChange}
            />
          </Box>
        </ModalWrapper>
      )}
    </Fragment>
  )
}
