import { MouseEvent } from 'react'
import { DEFAULT_HEADER_STYLES_CLASSNAME, PINNED_COLUMN } from '@constants'
import {
  GridActionsCellItem,
  GridCellParams,
  GridEnrichedColDef,
  Radio,
} from '@microservices/wiskey-react-components'
import { ContentCopy, Delete, Edit } from '@mui/icons-material'
import { Checkbox } from '@mui/material'

type HandlerActionClick = (
  params: GridCellParams<any, any, any>,
  event: MouseEvent<HTMLButtonElement>
) => void

type DataGridActionsColumnParams = {
  onCopy?: HandlerActionClick
  onEdit?: HandlerActionClick
  onDelete?: HandlerActionClick
  onChangeDefault?: HandlerActionClick
  onAdd?: HandlerActionClick
  columnDef?: GridEnrichedColDef
  headerName?: string
  minWidth?: number
  defaultGanttChartId?: number
  disabledDelete?: boolean
  disabledChangeDefault?: boolean
}

export const generateDataGridActionsColumnForContext = ({
  onCopy,
  onEdit,
  onDelete,
  onChangeDefault,
  onAdd,
  columnDef,
  headerName,
  minWidth,
  defaultGanttChartId,
  disabledDelete,
  disabledChangeDefault,
}: DataGridActionsColumnParams): GridEnrichedColDef => {
  return {
    field: 'actions',
    type: 'actions',
    align: 'center',
    minWidth: minWidth || 120,
    resizable: false,
    hideable: false,
    headerClassName: `${DEFAULT_HEADER_STYLES_CLASSNAME}`,
    pinnedColumn: PINNED_COLUMN.RIGHT, // Для промежуточных расчётов. Не влияет на реальное закрепление.
    ...(headerName ? { headerName } : {}),
    getActions: (params: GridCellParams) => [
      ...(onAdd
        ? [
            <GridActionsCellItem
              key={params.id}
              icon={<Checkbox sx={{ padding: 0 }} />}
              label='Add'
              showInMenu={false}
              onClick={event => onAdd?.(params, event)}
            />,
          ]
        : []),
      ...(onCopy
        ? [
            <GridActionsCellItem
              key={params.id}
              icon={<ContentCopy />}
              label='Copy'
              showInMenu={false}
              onClick={event => onCopy?.(params, event)}
            />,
          ]
        : []),
      ...(onEdit
        ? [
            <GridActionsCellItem
              key={params.id}
              icon={<Edit />}
              label='Edit'
              showInMenu={false}
              onClick={event => onEdit?.(params, event)}
            />,
          ]
        : []),
      ...(onDelete
        ? [
            <GridActionsCellItem
              key={params.id}
              disabled={disabledDelete}
              icon={<Delete />}
              label='Delete'
              showInMenu={false}
              onClick={event => onDelete?.(params, event)}
            />,
          ]
        : []),
      ...(onChangeDefault && defaultGanttChartId
        ? [
            <GridActionsCellItem
              key={params.id}
              disabled={disabledChangeDefault}
              icon={<Radio checked={params.row.id === defaultGanttChartId} sx={{ padding: 0 }} />}
              label='Default'
              showInMenu={false}
              sx={{ opacity: disabledChangeDefault ? 0.6 : 1 }}
              onClick={event => onChangeDefault?.(params, event)}
            />,
          ]
        : []),
    ],
    ...columnDef,
  } as GridEnrichedColDef
}
