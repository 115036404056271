import { useTranslation } from 'react-i18next'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { Accordion, AccordionDetails, AccordionSummary, Grid, Typography } from '@mui/material'

import { useCopy } from './useCopy'
import values from './values'

export const AccordionConfigExamples = () => {
  const { t } = useTranslation()
  const handleWriteClipboardText = useCopy()

  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        {t('ganttCreate.actionConfigExamples.title')}
      </AccordionSummary>
      <AccordionDetails>
        <Typography color={'gray'} fontSize={12}>
          {t('ganttCreate.actionConfigExamples.subtitle')}
        </Typography>
        {Object.entries(values).map(([key, value]) => (
          <Grid
            key={key}
            container
            columnSpacing={4}
            mt={3}
            sx={{ cursor: 'copy' }}
            xs={12}
            onClick={() => handleWriteClipboardText(value)}
          >
            <Grid item>
              {key}
              <pre>{value}</pre>
            </Grid>
          </Grid>
        ))}
      </AccordionDetails>
    </Accordion>
  )
}
