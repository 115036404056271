import { GanttFormValues } from '@pages/GanttChart/components/GanttCreateOrEdit/types'

import { commonAPI } from '@redux/api/common.api'

import { GETEntity } from '../../types'

export const modelGanttAPI = commonAPI.injectEndpoints({
  endpoints: build => ({
    createGantt: build.mutation<GanttFormValues, GanttFormValues>({
      query: gantt => ({
        url: '/gantChart',
        method: 'POST',
        body: gantt,
      }),
      invalidatesTags: ['AllGantt', 'AllViews', 'SingleGantt'],
    }),
    fetchAllGantt: build.query({
      query: () => ({
        url: '/gantChart/all',
      }),
      providesTags: ['AllGantt'],
    }),
    fetchGanttById: build.query({
      query: id => ({
        url: `/gantChart/config/${id}`,
      }),
      providesTags: ['SingleGantt'],
    }),
    updateGantt: build.mutation<GanttFormValues, GanttFormValues>({
      query: gantt => ({
        url: '/gantChart',
        method: 'POST',
        body: gantt,
      }),
      invalidatesTags: ['AllGantt', 'SingleGantt'],
    }),
    deleteGanttById: build.mutation<GETEntity, string | number>({
      query: code => ({
        url: `/gantChart/${code}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['AllGantt'],
    }),
  }),
})

export const {
  useCreateGanttMutation,
  useFetchAllGanttQuery,
  useFetchGanttByIdQuery,
  useUpdateGanttMutation,
  useDeleteGanttByIdMutation,
} = modelGanttAPI
