import { ChangeEvent, FC, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ItemId, RenderItemParams } from '@atlaskit/tree'
import { MENU_TYPE } from '@constants'
import { usePaletteMode } from '@hooks'
import { useScrollToItem } from '@hooks'
import { Checkbox } from '@microservices/wiskey-react-components'
import { Add, Circle, Delete, Edit, KeyboardArrowDown } from '@mui/icons-material'
import {
  Box,
  Collapse,
  FormControlLabel,
  Grid,
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@mui/material'
import { blueGrey, grey } from '@mui/material/colors'

import { IconPicker } from '@components/IconPicker'

import { TreeItemControlPanelOptionType } from '../../../../types'
import { AddItem } from '../AddItem'

type ItemProps = Omit<RenderItemParams, 'item'> & {
  item: TreeItemControlPanelOptionType
  onDeleteTreeItem: (itemTreeId: ItemId, itemId?: number) => void
  onHideIconTitle: (itemId: ItemId, value: boolean) => void
  onChangeIconName: (itemId: ItemId, iconName: string | null) => void
}

export const Item: FC<ItemProps> = ({
  item,
  onCollapse,
  onExpand,
  provided,
  onDeleteTreeItem,
  onHideIconTitle,
  onChangeIconName,
  snapshot,
}) => {
  const mode = usePaletteMode()
  const { t } = useTranslation()
  const [showAddedItem, setShowAddedItem] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const collapseRef = useRef<HTMLDivElement>(null)

  const isDivider = item.data.menuType === MENU_TYPE.DIVIDER

  useEffect(() => {
    setShowAddedItem(false)
  }, [item])

  const { scrollToItem } = useScrollToItem({ ref: collapseRef, isScroll: showAddedItem })

  const handleAddClick = () => {
    handleShowItem(true)
    if (showAddedItem) {
      scrollToItem()
    }
  }

  const handleShowItem = (value: boolean) => {
    setIsEdit(false)
    setShowAddedItem(value)
  }

  const handleEditItem = () => {
    setIsEdit(true)
    setShowAddedItem(true)
  }

  const handleOpen = (): void => {
    if (item.isExpanded) {
      onCollapse(item.id)

      return
    }
    onExpand(item.id)
  }

  const handleDeleteitem = (): void => {
    onDeleteTreeItem(item.id, item.data.id)
  }

  const handleChangeChecbox = (_: ChangeEvent<HTMLInputElement>, checked: boolean) => {
    onHideIconTitle(item.id, checked)
  }

  const handleChangeIcon = (icon: string | null) => {
    onChangeIconName(item.id, icon)
  }

  return (
    <ListItem ref={provided.innerRef} {...provided.draggableProps} sx={{ py: 0 }}>
      <Box
        {...provided.dragHandleProps}
        sx={{
          transition: '0.1s',
          width: '100%',
          ...(snapshot.isDragging && {
            backgroundColor: mode === 'light' ? grey[300] : blueGrey[700],
          }),
        }}
      >
        <Grid container direction={'column'}>
          <Grid container item alignItems={'center'} sx={{ mb: 0, p: 1 }}>
            <Grid
              container
              alignItems={'center'}
              flexDirection={'row'}
              justifyContent={'space-between'}
              wrap='nowrap'
            >
              <Grid
                container
                item
                alignItems={'center'}
                flexDirection={'row'}
                flexWrap={'nowrap'}
                maxWidth={'calc(100% - 110px)'}
              >
                <ListItemIcon sx={{ minWidth: 'fit-content', mr: 1 }}>
                  {item.hasChildren ? (
                    <Grid container alignItems={'center'}>
                      <KeyboardArrowDown
                        sx={
                          item.isExpanded
                            ? { cursor: 'pointer' }
                            : { cursor: 'pointer', transform: 'rotate(-90deg)' }
                        }
                        onClick={handleOpen}
                      />
                      {item.data.menuType === MENU_TYPE.ICON && (
                        <IconPicker icon={item.data.iconName} onChange={handleChangeIcon} />
                      )}
                    </Grid>
                  ) : item.data.menuType === MENU_TYPE.ICON ? (
                    <IconPicker icon={item.data.iconName} onChange={handleChangeIcon} />
                  ) : (
                    <Circle sx={{ fontSize: 6, ml: 1, mr: 1 }} />
                  )}
                </ListItemIcon>
                <ListItemText
                  primary={item.data?.title}
                  secondary={!item.hasChildren ? item.data?.elementTitle : null}
                  {...(item.data.menuType === MENU_TYPE.ICON && {
                    secondary: item.data?.elementTitle
                      ? `${item.data.iconName} (${item.data?.elementTitle})`
                      : item.data.iconName,
                  })}
                  sx={{
                    ml: 1,
                    '& .MuiListItemText-primary': {
                      maxWidth: 'fit-content',
                      overflowX: 'hidden',
                      textOverflow: 'ellipsis',
                    },
                  }}
                />
              </Grid>
              <Grid container item flex={1} flexWrap={'nowrap'} justifyContent={'flex-end'}>
                {item.data.menuType === MENU_TYPE.ICON && (
                  <FormControlLabel
                    checked={item.data.showTitle}
                    label={t('controlPanelCreate.form.addItem.showTitle')}
                    sx={{ '& .MuiTypography-root': { whiteSpace: 'nowrap' } }}
                    control={
                      <Checkbox
                        checked={item.data.showTitle}
                        size={'small'}
                        sx={{
                          color: theme => theme.palette.grey['800'],
                          '&.Mui-checked': {
                            color: theme => theme.palette.grey['800'],
                          },
                        }}
                        onChange={handleChangeChecbox}
                      />
                    }
                  />
                )}

                {!isDivider && (
                  <IconButton onClick={handleAddClick}>
                    <ListItemIcon sx={{ minWidth: 'fit-content' }}>
                      <Add fontSize='small' />
                    </ListItemIcon>
                  </IconButton>
                )}
                <IconButton onClick={handleEditItem}>
                  <ListItemIcon sx={{ minWidth: 'fit-content' }}>
                    <Edit fontSize='small' />
                  </ListItemIcon>
                </IconButton>
                <IconButton onClick={handleDeleteitem}>
                  <ListItemIcon sx={{ minWidth: 'fit-content' }}>
                    <Delete fontSize='small' />
                  </ListItemIcon>
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Collapse ref={collapseRef} in={showAddedItem}>
              <Box
                sx={{
                  mx: 2,
                  my: 1,
                }}
              >
                <AddItem isEdit={isEdit} treeItem={item} onClose={() => handleShowItem(false)} />
              </Box>
            </Collapse>
          </Grid>
        </Grid>
      </Box>
    </ListItem>
  )
}
