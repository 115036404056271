import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export type AvailableGanttType = { id: number; title: string; path: string }

const initialState: {
  gantts: AvailableGanttType[]
} = {
  gantts: [],
}

const availableGantts = createSlice({
  name: 'availableGantts.reducer',
  initialState,
  reducers: {
    setAvailableGantt(state, action: PayloadAction<AvailableGanttType>) {
      const newGantt = action.payload

      if (!state.gantts.find(({ id }) => id === newGantt.id)) {
        state.gantts.push(newGantt)
      }
    },
  },
})

export default availableGantts.reducer
export const { setAvailableGantt } = availableGantts.actions
