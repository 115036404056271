import { Typography } from '@mui/material'

import { useCopy } from './useCopy'
import values from './values'

export const BtnCopyConfigExample = ({ name }: { name: keyof typeof values }) => {
  const copy = useCopy()

  const handleClick = () => {
    const value = values[name]
    copy(value)
  }

  return (
    <Typography
      borderBottom='1px dotted black'
      mr={1}
      sx={{ cursor: 'copy' }}
      onClick={handleClick}
    >
      Copy {name}
    </Typography>
  )
}
