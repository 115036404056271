import Airplane from '@assets/images/airplane.svg'
import AirportMap from '@assets/images/airport-map.svg'
import AlertPanel from '@assets/images/alert-panel.svg'
import BrigadeEditor from '@assets/images/brigade-editor.svg'
import BrigadeMode from '@assets/images/brigade-mode.svg'
import BrigadePlanMode from '@assets/images/brigade-plan-mode.svg'
import CurrentTime from '@assets/images/current-time.svg'
import EndBar from '@assets/images/end-bar.svg'
import EventPanel from '@assets/images/event-planet.svg'
import FlightList from '@assets/images/flight-list.svg'
import Funnel from '@assets/images/funnel.svg'
import LinkedTasks from '@assets/images/linked-tasks.svg'
import Minus from '@assets/images/minus.svg'
import Plus from '@assets/images/plus.svg'
import Printer from '@assets/images/printer.svg'
import ResourceView from '@assets/images/resource-view.svg'
import ResourceViewCut from '@assets/images/resource-view-cut.svg'
import ResourceViewCutFilter from '@assets/images/resource-view-cut-filter.svg'
import ResourceViewFilter from '@assets/images/resource-view-filter.svg'
import Scale100 from '@assets/images/scale100.svg'
import SearchAssistant from '@assets/images/search-assistant.svg'
import StartBar from '@assets/images/start-bar.svg'
import SystemAlertPanel from '@assets/images/system-alert-panel.svg'
import TaskView from '@assets/images/task-view.svg'

import { IconType } from '@components/AppBar/components/IconSection'

export const ICONS: Record<string, IconType> = {
  Printser: {
    name: 'Printser',
    src: Printer,
  },
  Scale100: {
    name: 'Scale100',
    src: Scale100,
  },
  Plus: {
    name: 'Plus',
    src: Plus,
  },
  Minus: {
    name: 'Minus',
    src: Minus,
  },
  StartBar: {
    name: 'StartBar',
    src: StartBar,
  },
  CurrentTime: {
    name: 'CurrentTime',
    src: CurrentTime,
  },
  EndBar: {
    name: 'EndBar',
    src: EndBar,
  },
  Funnel: {
    name: 'Funnel',
    src: Funnel,
  },
  ResourceViewFilter: {
    name: 'ResourceViewFilter',
    src: ResourceViewFilter,
  },
  ResourceViewCut: {
    name: 'ResourceViewCut',
    src: ResourceViewCut,
  },
  ResourceViewCutFilter: {
    name: 'ResourceViewCutFilter',
    src: ResourceViewCutFilter,
  },
  SearchAssistant: {
    name: 'SearchAssistant',
    src: SearchAssistant,
  },
  FlightList: {
    name: 'FlightList',
    src: FlightList,
  },
  BrigadeEditor: {
    name: 'BrigadeEditor',
    src: BrigadeEditor,
  },
  BrigadeMode: {
    name: 'BrigadeMode',
    src: BrigadeMode,
  },
  BrigadePlanMode: {
    name: 'BrigadePlanMode',
    src: BrigadePlanMode,
  },
  LinkedTasks: {
    name: 'LinkedTasks',
    src: LinkedTasks,
  },
  AlertPanel: {
    name: 'AlertPanel',
    src: AlertPanel,
  },
  EventPanel: {
    name: 'EventPanel',
    src: EventPanel,
  },
  SystemAlertPanel: {
    name: 'SystemAlertPanel',
    src: SystemAlertPanel,
  },
  AirportMap: {
    name: 'AirportMap',
    src: AirportMap,
  },
  Airplane: {
    name: 'Airplane',
    src: Airplane,
  },
  ResourceView: {
    name: 'ResourceView',
    src: ResourceView,
  },
  TaskView: {
    name: 'TaskView',
    src: TaskView,
  },
}
