import { FC, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import DeleteIcon from '@mui/icons-material/Delete'
import DoneIcon from '@mui/icons-material/Done'
import EditIcon from '@mui/icons-material/Edit'
import { Box, Grid, IconButton } from '@mui/material'
import { UpdatedVariableType } from '@types'

import {
  VariableItem,
  VariableItemProps,
} from '@pages/GanttChart/components/GanttCreateOrEdit/components/GanttConfiguration/components/TimelineAdditionalFields/VariableItem'

export const VariableItemRow: FC<
  VariableItemProps & {
    onSave?: (value: UpdatedVariableType) => void
    onDelete?: (value: UpdatedVariableType) => void
  }
> = ({
  controllerNamePathArray,
  field,
  index,
  controllerNameField,
  controllerNamePathStr,
  onRemove,
  watchValue,
  watchedObject,
  onSave,
  onDelete,
}) => {
  const [isEdit, setEdit] = useState(!watchValue.pathStr)
  const { trigger } = useFormContext()

  const handleDoneClick = () => {
    trigger(controllerNameField).then(value => {
      if (value) {
        onSave?.({
          ...watchValue,
          order: index,
          pathArray: watchValue.pathArray.map((data, index) => ({ ...data, order: index })),
        })
        setEdit(false)
      }
    })
  }

  const handleEdit = () => setEdit(true)

  const handleDelete = () => {
    onDelete?.({ ...watchValue })
    onRemove(index)
  }

  return (
    <Grid
      container
      item
      alignItems={'center'}
      flexWrap={'nowrap'}
      sx={{
        position: 'relative',
        borderBottom: theme => theme.palette.border.customTable,
        mt: 1,
        px: 3,
      }}
    >
      {isEdit ? (
        <VariableItem
          key={field.id}
          hideRemove
          controllerNameField={controllerNameField}
          controllerNamePathArray={controllerNamePathArray}
          controllerNamePathStr={controllerNamePathStr}
          field={field}
          index={index}
          watchValue={watchValue}
          watchedObject={watchedObject}
          onRemove={onRemove}
        />
      ) : (
        <Grid container my={1.3} xs={11}>
          <Grid item mt={0.4} xs={6}>
            {watchValue.field}
          </Grid>
          <Grid item mb={0.4} xs={5}>
            {watchValue.pathStr}
          </Grid>
        </Grid>
      )}

      <Box sx={{ position: 'absolute', right: 40, bottom: 10 }}>
        {isEdit ? (
          <IconButton
            disabled={!watchValue.pathStr || !watchedObject}
            size={'small'}
            sx={{ width: 36.5 }}
            onClick={handleDoneClick}
          >
            <DoneIcon fontSize={'small'} sx={{ height: 24 }} />
          </IconButton>
        ) : (
          <IconButton
            disabled={!watchedObject}
            size={'small'}
            sx={{ width: 36.5 }}
            onClick={handleEdit}
          >
            <EditIcon />
          </IconButton>
        )}
        <IconButton size={'small'} sx={{ width: 36.5 }} onClick={handleDelete}>
          <DeleteIcon />
        </IconButton>
      </Box>
    </Grid>
  )
}
