import { createApi } from '@reduxjs/toolkit/query/react'

import { GETObjectDataRecordParams, ObjectDataRecord } from '../../types'

import { baseQueryWithNotifications } from './config'

export const refetchApi = createApi({
  reducerPath: 'refetchApi',
  baseQuery: baseQueryWithNotifications,
  endpoints: build => ({
    refetchObjectDataRecordById: build.query<ObjectDataRecord, GETObjectDataRecordParams>({
      query: ({ objectCode, objectId }) => ({
        url: `router/storage/object/${objectCode}/id/${objectId}`,
      }),
    }),
  }),
})

export const { useRefetchObjectDataRecordByIdQuery } = refetchApi
