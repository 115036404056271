import { useMemo } from 'react'
import { AVAILABLES, EVENT_CODE_GANTT } from '@constants'

import {
  GanttActionType,
  GanttFormValues,
} from '@pages/GanttChart/components/GanttCreateOrEdit/types'

type Availables = {
  availableMoves: string[]
  availableResizes: string[]
}

export const useGetAvailableMovesAndResizes = (config: GanttFormValues) => {
  return useMemo(() => {
    const availables: Availables = {
      availableMoves: [],
      availableResizes: [],
    }

    config?.timeline?.forEach(timelineItem => {
      timelineItem?.actions?.forEach((action: GanttActionType) => {
        switch (action.eventCode) {
          case EVENT_CODE_GANTT.ON_SEGMENT_RESIZE:
            availables.availableResizes.push(AVAILABLES.ON_SEGMENT_RESIZE)

            break
          case EVENT_CODE_GANTT.ON_BACKLOG_SEGMENT_RESIZE:
            availables.availableResizes.push(AVAILABLES.ON_BACKLOG_SEGMENT_RESIZE)

            break
          case EVENT_CODE_GANTT.ON_SEGMENT_INNER_MOVE:
            availables.availableMoves.push(AVAILABLES.ON_SEGMENT_INNER_MOVE)

            break
          case EVENT_CODE_GANTT.ON_SEGMENT_OUTER_MOVE:
            availables.availableMoves.push(AVAILABLES.ON_SEGMENT_OUTER_MOVE)

            break
          case EVENT_CODE_GANTT.ON_BACKLOG_SEGMENT_INNER_MOVE:
            availables.availableMoves.push(AVAILABLES.ON_BACKLOG_SEGMENT_INNER_MOVE)

            break
          case EVENT_CODE_GANTT.ON_BACKLOG_SEGMENT_OUTER_MOVE:
            availables.availableMoves.push(AVAILABLES.ON_BACKLOG_SEGMENT_OUTER_MOVE)

            break
        }
      })
    })

    return availables
  }, [config])
}
