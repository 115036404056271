import { useMemo } from 'react'
import { EVENT_CODE_GANTT, GANTT_PARTS } from '@constants'
import { ActionData } from '@microservices/gantt-react-component/dist/ganttTypes/general'

import { useRunAction } from '@pages/ConfiduredGantt/hooks/useRunAction'

import {
  AXIS_TYPE,
  GanttActionType,
  GanttFormValues,
} from '@gantt/components/GanttCreateOrEdit/types'

export type ActionParams = ActionData

type HandlerMap = {
  onDoubleBoxClick?: ((params: ActionParams) => void)[]
  onDoubleRowXClick: ((params: ActionParams) => void)[]
  onDoubleRowYClick: ((params: ActionParams) => void)[]
  onDoubleCellClick: ((params: ActionParams) => void)[]
  onBoxResize: ((params: ActionParams) => void)[]
  onBoxMove: ((params: ActionParams) => void)[]
}

export const useGetHandlerMap = (config: GanttFormValues, path: string, id: string) => {
  const runAction = useRunAction(config, path, id)

  return useMemo(() => {
    const handlers: HandlerMap = {
      onDoubleBoxClick: [],
      onDoubleRowXClick: [],
      onDoubleCellClick: [],
      onDoubleRowYClick: [],
      onBoxResize: [],
      onBoxMove: [],
    }

    config?.timeline?.forEach(timelineItem => {
      timelineItem?.actions?.forEach((action: GanttActionType) => {
        const runActionX = (callback_data: ActionParams, isExecuted = true) => {
          if (isExecuted && isObjectCodeExist(callback_data)) {
            runAction(action, callback_data, timelineItem.actions, AXIS_TYPE.X)
          }
        }

        const isObjectCodeExist = (data: ActionParams) =>
          data?.data?.objectCode === timelineItem.data?.id

        const isTargetBacklog = (data: ActionParams) =>
          data?.metaData?.target === GANTT_PARTS.BACKLOG

        const isEqualTargetSource = (data: ActionParams) =>
          data?.metaData?.source === data?.metaData?.target

        switch (action.eventCode) {
          case EVENT_CODE_GANTT.ON_SEGMENT_CLICK:
            handlers.onDoubleBoxClick?.push(data =>
              runActionX(data, data.config?.link?.systemName === action.target)
            )

            break
          case EVENT_CODE_GANTT.ON_BAR_CLICK:
            handlers.onDoubleBoxClick?.push(data => runActionX(data))

            break
          case EVENT_CODE_GANTT.ON_ROW_CLICK:
            handlers.onDoubleRowXClick.push(data => runActionX(data))

            break
          case EVENT_CODE_GANTT.ON_SEGMENT_RESIZE:
          case EVENT_CODE_GANTT.ON_BACKLOG_SEGMENT_RESIZE:
            handlers.onBoxResize.push(data => runActionX(data))

            break
          case EVENT_CODE_GANTT.ON_SEGMENT_INNER_MOVE:
            handlers.onBoxMove.push(data =>
              runActionX(data, isEqualTargetSource(data) && !isTargetBacklog(data))
            )

            break
          case EVENT_CODE_GANTT.ON_SEGMENT_OUTER_MOVE:
            handlers.onBoxMove.push(data =>
              runActionX(data, !isEqualTargetSource(data) && isTargetBacklog(data))
            )

            break
          case EVENT_CODE_GANTT.ON_BACKLOG_SEGMENT_INNER_MOVE:
            handlers.onBoxMove.push(data =>
              runActionX(data, isEqualTargetSource(data) && isTargetBacklog(data))
            )

            break
          case EVENT_CODE_GANTT.ON_BACKLOG_SEGMENT_OUTER_MOVE:
            handlers.onBoxMove.push(data =>
              runActionX(data, !isEqualTargetSource(data) && !isTargetBacklog(data))
            )

            break
        }
      })
    })

    config?.resource?.actions?.forEach((action: GanttActionType) => {
      const runActionY = (callback_data: ActionParams, isExecuted = true) => {
        if (isExecuted) {
          runAction(action, callback_data, config?.resource?.actions, AXIS_TYPE.Y)
        }
      }

      switch (action.eventCode) {
        case EVENT_CODE_GANTT.ON_ROW_CLICK:
          handlers.onDoubleRowYClick.push(data => runActionY(data))

          break
        case EVENT_CODE_GANTT.ON_CELL_CLICK:
          handlers.onDoubleCellClick.push(data =>
            runActionY(data, action.target === data?.data?.colDef.headerName)
          )

          break
      }
    })

    return handlers
  }, [config])
}
