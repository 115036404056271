import { ReactNode, useMemo } from 'react'
import { FormProvider } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { RenderItemParams } from '@atlaskit/tree'
import { CONTEXT_MENU_TABS, GENERATOR_INPUT_TYPE } from '@constants'
import { Button, FormInput } from '@microservices/wiskey-react-components'
import { Grid, Typography } from '@mui/material'
import { TreeItemMenuPointOptionType } from '@types'

import { ConfigField } from '@pages/GanttChart/components/GanttCreateOrEdit/types'

import { Tab, TabPanel, Tabs } from '@components/Tabs'

import { PageContentLayout } from '@layouts/PageContentLayout'

import { AddMenuPointDialog } from './components/AddMenuPointDialog'
import { Item } from './components/MenuTab/Item'
import { MenuList } from './components/MenuTab/MenuList'
import { VariablesTab } from './components/VariablesTab'
import { useContextMenuCreateOrEdit } from './hooks/useContextMenuCreateOrEdit'

export type VariableType = ConfigField & { id?: number }

export const ContextMenuCreateOrEdit = () => {
  const { t } = useTranslation()
  const { data, state, handlers } = useContextMenuCreateOrEdit()

  const { objects, currentContextMenu } = data
  const {
    methods,
    currentTab,
    editTreeItemForm,
    fields,
    showAddDialog,
    tree,
    objectsOptions,
    watchedObject,
    globalDirty,
    watchVariables,
  } = state

  const {
    setTree,
    handleDeleteTreeItem,
    handleEditTreeItem,
    handleClickAddTreeItem,
    handleClickAddMenuPoint,
    handleDragEnd,
    handleSaveMenuPoint,
    handleSaveContextMenu,
    handleChangeTab,
    handleCloseMenuPointDialog,
    handleAddItem,
    handleRemoveItem,
    handleSaveVariable,
    handleDeleteVariable,
    handleCancelContextMenu,
  } = handlers

  const treeNames = useMemo(
    () => Object.values(tree?.items ?? {}).map(op => op.data.title.toLowerCase()),
    [tree]
  )

  const renderItem = ({ item, ...props }: RenderItemParams): ReactNode => {
    return (
      <Item
        {...props}
        item={item as TreeItemMenuPointOptionType}
        onAddTreeItem={handleClickAddTreeItem}
        onDeleteTreeItem={handleDeleteTreeItem}
        onEditTreeItem={handleEditTreeItem}
      />
    )
  }

  return (
    <PageContentLayout sx={{ p: 1 }}>
      <Typography mb={1} variant='h5'>
        {t(`contextMenuCreate.title`)}
      </Typography>
      <FormProvider {...methods}>
        <FormInput
          inputType={GENERATOR_INPUT_TYPE.INPUT}
          label={t('contextMenuCreate.form.title.label')}
          labelSx={{ width: 170, minWidth: 170 }}
          name={'title'}
          placeholder={t('contextMenuCreate.form.title.placeholder')}
        />
        <Grid container gap={1}>
          <Grid item flexGrow={1}>
            <FormInput
              autocompleteOptions={objectsOptions}
              disabled={Boolean(currentContextMenu)}
              inputType={GENERATOR_INPUT_TYPE.AUTOCOMPLETE}
              label={t('contextMenuCreate.form.object.label')}
              labelSx={{ width: 170, minWidth: 170 }}
              name={'object'}
              placeholder={t('contextMenuCreate.form.object.placeholder')}
            />
          </Grid>
        </Grid>

        <Grid container alignItems={'center'} justifyContent={'space-between'}>
          <Tabs
            allowScrollButtonsMobile
            scrollButtons
            value={currentTab}
            variant='scrollable'
            onChange={handleChangeTab}
          >
            <Tab
              label={t('contextMenuCreate.tabs.menuPoints')}
              value={CONTEXT_MENU_TABS.MENU_POINT}
            />
            <Tab
              label={t('contextMenuCreate.tabs.variables')}
              value={CONTEXT_MENU_TABS.VARIABLES}
            />
          </Tabs>
          {currentTab === CONTEXT_MENU_TABS.MENU_POINT && (
            <Button variant='contained' onClick={handleClickAddMenuPoint}>
              {t('contextMenuCreate.addMenuPoint')}
            </Button>
          )}
          {currentTab === CONTEXT_MENU_TABS.VARIABLES && (
            <Button disabled={!watchedObject} variant='contained' onClick={handleAddItem}>
              {t('contextMenuCreate.addVariable')}
            </Button>
          )}
        </Grid>

        <TabPanel index={CONTEXT_MENU_TABS.MENU_POINT} value={currentTab}>
          <MenuList
            renderItem={renderItem}
            setTree={setTree}
            tree={tree}
            onDragEnd={handleDragEnd}
          />
        </TabPanel>
        <TabPanel index={CONTEXT_MENU_TABS.VARIABLES} value={currentTab}>
          <VariablesTab
            variables={fields}
            onDeleteVariable={handleDeleteVariable}
            onRemove={handleRemoveItem}
            onSaveVariable={handleSaveVariable}
          />
        </TabPanel>

        <Grid container gap={1} justifyContent={'flex-end'} width={'100%'}>
          <Grid item>
            <Button disabled={!globalDirty} variant={'contained'} onClick={handleSaveContextMenu}>
              {t('contextMenuCreate.form.saveButton')}
            </Button>
          </Grid>
          <Grid item>
            <Button variant={'outlined'} onClick={handleCancelContextMenu}>
              {t('contextMenuCreate.form.cancelButton')}
            </Button>
          </Grid>
        </Grid>
      </FormProvider>

      {(showAddDialog || editTreeItemForm) && (
        <AddMenuPointDialog
          item={editTreeItemForm}
          treeNames={treeNames}
          variables={watchVariables}
          onClose={handleCloseMenuPointDialog}
          onSave={handleSaveMenuPoint}
        />
      )}
    </PageContentLayout>
  )
}
