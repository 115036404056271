import { MouseEvent, useState } from 'react'
import { useTranslation } from 'react-i18next'
import check from '@assets/images/check.svg'
import { FIELD_VALUE_TYPE, FILTERS_SA, FILTERS_SA_TYPE, OBJECT_FIELD_TYPE } from '@constants'
import { FilterAlt } from '@mui/icons-material'
import { Box, Grid, ListItemText, Menu } from '@mui/material'

import { CustomSvgIcon } from '@components/CustomSvgIcon'
import { ListItemButton } from '@components/UserNavList/components/ListItem'

export type SearchRuleType = { id: FILTERS_SA_TYPE; label: string }

export const StartAdornmentFilterRules = ({
  disabled,
  value,
  objectFieldType,
  fieldType,
  setValue,
  name,
}: {
  disabled?: boolean
  name: string
  value: SearchRuleType
  objectFieldType: OBJECT_FIELD_TYPE
  fieldType: FIELD_VALUE_TYPE
  setValue: (value: SearchRuleType) => void
}) => {
  const { t } = useTranslation()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const handleClick = (event: MouseEvent<HTMLDivElement> & { target: HTMLDivElement }) => {
    if (disabled) {
      return
    }

    event.preventDefault()
    event.stopPropagation()

    if (event.target && !event.target.classList.contains('MuiButtonBase-root')) {
      setAnchorEl(event.currentTarget)
    }
  }

  const onChangeValue = (filter: SearchRuleType) => {
    setValue(filter)
    handleCloseMenu()
  }

  const handleCloseMenu = () => {
    setAnchorEl(null)
  }

  return (
    <>
      <Box
        title={`${t('searchAssistant.searchRule')} : ${value.id}`}
        sx={theme => ({
          width: '20px',
          height: '20px',
          pb: 0.5,
          ...(disabled && { opacity: 0.7 }),
          '.MuiSvgIcon-root': {
            fill: theme.palette.color.divider,
          },
          '&:hover': {
            cursor: disabled ? 'default' : 'pointer',
            ...(!disabled && { background: 'rgba(0, 0, 0, 0.04)' }),
          },
        })}
        onClick={handleClick}
        onDoubleClick={event => event.stopPropagation()}
      >
        <FilterAlt fontSize='small' />
      </Box>
      <Menu
        disableScrollLock
        anchorEl={anchorEl}
        anchorReference='anchorEl'
        open={Boolean(anchorEl)}
        onClose={handleCloseMenu}
        onDoubleClick={event => event.stopPropagation()}
      >
        {Object.values(FILTERS_SA).map(filter =>
          filter.types.includes(objectFieldType) &&
          filter.types.includes(fieldType) &&
          !filter.excludeNames.includes(name) &&
          !filter.includeInName.some(word => name.includes(word)) ? (
            <ListItemButton
              key={filter.value.id}
              isChild
              selected={filter.value.id === value.id}
              sx={{
                '&:hover, &.Mui-selected': {
                  bgcolor: theme => theme.palette.background.hovered,
                },
                pl: 3,
              }}
              onClick={() => onChangeValue(filter.value)}
              onDoubleClick={event => event.stopPropagation()}
            >
              {filter.value.id === value.id && (
                <Grid item alignSelf={'end'} left={0} position={'absolute'}>
                  <CustomSvgIcon imgStyle={{}} src={check} />
                </Grid>
              )}
              <ListItemText
                sx={{
                  m: 0,
                  '& .MuiTypography-root': {
                    lineHeight: '24px',
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                  },
                }}
              >
                {filter.value.label}
              </ListItemText>
            </ListItemButton>
          ) : null
        )}
      </Menu>
    </>
  )
}
