import { FC, useState } from 'react'
import more from '@assets/images/more.svg'
import { MIN_AUTOCOMPLETE_POPPER_WIDTH } from '@constants'
import { Select, SelectProps } from '@microservices/wiskey-react-components'
import { IconButton } from '@mui/material'

import { CustomSvgIcon } from '@components/CustomSvgIcon'

const BOOLEAN_OPTIONS = [
  { id: 0, name: 'true' },
  { id: 1, name: 'false' },
]

export type SelectBooleanProps = {
  isDialog?: boolean
  dotsIcon?: boolean
} & Omit<SelectProps, 'options'>

export const SelectBoolean: FC<SelectBooleanProps> = ({
  isDialog,
  value,
  onChange,
  disabled,
  dotsIcon = true,
  ...props
}) => {
  const [open, setOpen] = useState(false)

  return (
    <Select
      {...props}
      disabled={disabled}
      open={open}
      options={BOOLEAN_OPTIONS}
      size='small'
      value={value}
      MenuProps={{
        sx: {
          zIndex: theme => theme.zIndex.tooltip,
          '& ul': {
            p: 0,
          },
          '& li': {
            display: 'block',
            mt: 0,
            p: 0,
            minWidth: MIN_AUTOCOMPLETE_POPPER_WIDTH,
            m: 0,
            height: isDialog ? '25px' : '36px',
            pl: 2,
          },
        },
      }}
      endAdornment={
        <>
          {dotsIcon && (
            <IconButton
              sx={{
                width: 24,
                height: 23,
                cursor: 'pointer',
                display: 'grid',
                placeContent: 'center',
                position: 'relative',
                right: '10px',
                pt: 0.1,
                pointerEvents: 'auto',
              }}
              onClick={() => setOpen(true)}
            >
              <CustomSvgIcon
                src={more}
                sx={{
                  width: 16,
                }}
              />
            </IconButton>
          )}
        </>
      }
      sx={{
        position: 'relative',
        top: 2.6,
        border: theme => theme.palette.border.field,
        background: theme => (disabled ? 'none' : theme.palette.background.searchAssistantLabel),
        pl: '6px',
        '*': {
          transition: '0.2s ease-in',
        },
        color: theme => theme.palette.color.field,
        // opacity: 0.8,
        borderRadius: 0,
        '& input:-webkit-autofill': {
          '-webkit-box-shadow': theme =>
            `0 0 0 100px ${theme.palette.background.fieldUpdated} inset`,
        },
        '.MuiOutlinedInput-notchedOutline': {
          border: 'none !important',
        },
        '.Mui-disabled': {
          background: theme =>
            disabled ? `${theme.palette.background.fieldDisabled} !important` : 'none',
          '-webkit-text-fill-color': theme => `${theme.palette.color.field} !important`,
        },
        '.MuiTypography-root': {
          color: theme => theme.palette.color.default,
        },
        '.Mui-error': {
          background: theme => `${theme.palette.background.fieldError} !important`,
        },
        '& input:hover': {
          border: theme => theme.palette.border.field,
          background: 'red',
        },
        '.MuiSelect-select': {
          py: 0,
          pl: props.startAdornment ? 1 : 2,
          '*': {
            transition: '0.2s ease-in',
          },
          color: theme => theme.palette.color.field,
          borderRadius: 0,
          background: theme => theme.palette.background.searchAssistantLabel,
          '& input:-webkit-autofill': {
            '-webkit-box-shadow': theme =>
              `0 0 0 100px ${theme.palette.background.fieldUpdated} inset`,
          },
          '.MuiOutlinedInput-notchedOutline': {
            border: 'none !important',
          },
        },
        '& .MuiSelect-icon': {
          display: 'none',
        },
        pr: '0px !important',
      }}
      onChange={onChange}
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
    />
  )
}
