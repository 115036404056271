import { CSSProperties, useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import JsxParser from 'react-jsx-parser'
import Editor from 'react-simple-code-editor'
import { highlight, languages } from 'prismjs'
import { MAX_INPUT_LENGTH } from '@constants'
import { checkValidJS, ErrorValidJSType, generateDefaultObjectByFields } from '@helpers'
import { Box, Typography } from '@mui/material'
import { ObjectFieldDTO } from '@types'

import 'prismjs/components/prism-jsx'
import 'prismjs/components/prism-json'

import 'prismjs/themes/prism.css'

type EditorBaseProps = {
  language: 'js' | 'json' | 'jsx'
  value: string
  placeholder?: string
  // style?: (invalid: boolean) => CSSProperties | undefined
  style?: CSSProperties | undefined
  noValidate?: boolean
  isJsx?: boolean
  invalid?: boolean
  objectFields?: ObjectFieldDTO[]
  validator?: (value: string) => ErrorValidJSType | undefined
  onChange?: (value: string) => void
  onInvalid?: (state: boolean) => void
}

export const EditorBase = ({
  language,
  value,
  placeholder,
  style,
  noValidate = false,
  isJsx,
  objectFields,
  invalid = false,
  validator,
  onChange,
  onInvalid,
}: EditorBaseProps) => {
  const { t } = useTranslation()

  const srcObj = generateDefaultObjectByFields(objectFields || [])

  const [valueJs, setValueJs] = useState(value || '')
  const [errorMessage, setErrorMessage] = useState('')

  const setError = useCallback((validateObj: ErrorValidJSType): void => {
    setErrorMessage(validateObj.error ? (validateObj.message as string) : '')
    onInvalid?.(validateObj.error)
  }, [])

  const validateValue = (value: string): void => {
    if (noValidate) {
      setError({ error: false, message: null })
      return
    }

    if (validator) {
      const validatedObj = validator(value)

      if (validatedObj?.error) {
        setError(validatedObj)
        return
      }

      setError({ error: false, message: null })
      return
    }

    const validatedObj = checkValidJS(value, { srcObj })

    if (!isJsx && validatedObj.error) {
      setError(validatedObj)
      return
    }

    setError({ error: false, message: null })
  }

  const handleError = useCallback((error: Error) => {
    if (error) {
      setError({ error: true, message: `${t('error.valueJS')}` })
      return
    }

    setError({ error: false, message: null })
  }, [])

  const handleScriptValueChange = useCallback(
    (value: string) => {
      if (!value) {
        setError({ error: false, message: null })
      }

      validateValue(value)
      setValueJs(value)
      onChange?.(value)
    },
    [onChange]
  )

  return (
    <>
      <Editor
        autoFocus
        highlight={code => highlight(code, languages[language], language)}
        maxLength={MAX_INPUT_LENGTH}
        padding={10}
        placeholder={placeholder}
        style={style}
        value={valueJs}
        onValueChange={handleScriptValueChange}
      />
      <Box hidden>
        {isJsx && (
          <JsxParser showWarnings bindings={{ srcObj }} jsx={valueJs} onError={handleError} />
        )}
      </Box>
      <Typography color={'error'}>{errorMessage}</Typography>
    </>
  )
}
