import ReactDOM from 'react-dom/client'
import { createBrowserHistory } from 'history'

import { ROUTES } from './constants/routes'
import App from './containers/App'
import AuthService from './services/AuthService'
import reportWebVitals from './reportWebVitals'

import './index.scss'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

const history = createBrowserHistory()

const renderApp = () => {
  root.render(<App />)

  if (!localStorage.getItem('context')) {
    history.push(ROUTES.CHOOSE_CONTEXT)
  }
}

AuthService.init(renderApp)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
