import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useLocation, useParams } from 'react-router-dom'
import { ROUTES } from '@constants'

import { useFetchContextByIdQuery } from '@redux/api'

import { FormContextType } from '../types'

import { useHandlers } from './useHandlers'

export const useContextCreateOrEdit = () => {
  const location = useLocation() as unknown as Location & { state?: { id?: string } }
  const copyId = location.state?.id
  const { id } = useParams()

  const isCreate = ROUTES.CONFIG_CONTEXTS_CREATE === location.pathname
  const isEdit = !!id

  const methods = useForm<FormContextType>({
    defaultValues: {
      id: null,
      title: '',
      searchView: null,
      selectedViews: [],
      unselectedViews: [],
      searchGanttChart: null,
      selectedGanttCharts: [],
      unselectedGanttCharts: [],
      defaultGanttChartId: undefined,
      controlPanel: null,
    },
  })

  const { getValues, reset } = methods

  const { data: contextById, isLoading: isLoadingContextById } = useFetchContextByIdQuery(
    id ?? copyId,
    {
      skip: !id && !copyId,
    }
  )

  const { state, handlers } = useHandlers({
    methods,
    contextById,
  })

  useEffect(() => {
    if (contextById) {
      reset({
        ...getValues(),
        id: contextById.id,
        title: contextById.title,
      })
    }
  }, [contextById])

  return {
    data: {
      methods,
      isCreate,
      isEdit,
      id: id || copyId,
      isLoadingContextById,
      contextById,
    },
    state: {
      ...state,
    },
    handlers: {
      ...handlers,
    },
  }
}
