import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { AddPhotoAlternate, Cancel } from '@mui/icons-material'
import { Badge, Box, Button, Grid, IconButton } from '@mui/material'

import { CustomSvgIcon } from '@components/CustomSvgIcon'

import { IconMenu } from './components'
import { ICONS } from './constants'

type IconPickerProps = {
  icon: string | null | undefined
  onChange?: (icon: string | null) => void
}

export const IconPicker: FC<IconPickerProps> = ({ icon, onChange }) => {
  const { t } = useTranslation()

  const [selectedIcon, setSelectedIcon] = useState<string | null>(icon || null)
  const [selectedIconSrc, setSelectedIconSrc] = useState<string | null>(
    icon ? ICONS[icon].src : null
  )
  const [isOpenIconMenu, setIsOpenIconMenu] = useState<boolean>(false)

  const handleChangeIcon = (
    event: React.SyntheticEvent<EventTarget> | null,
    value: string | null
  ) => {
    event && event.stopPropagation()

    if (value) {
      const iconObj = ICONS[value]

      setSelectedIconSrc(iconObj.src)
    }

    setSelectedIcon(value)
    onChange?.(value)
  }

  return (
    <Box>
      {isOpenIconMenu && (
        <IconMenu
          isShow={isOpenIconMenu}
          selectedIcon={selectedIcon}
          onChange={handleChangeIcon}
          onClose={() => setIsOpenIconMenu(false)}
        />
      )}
      {selectedIcon && selectedIconSrc ? (
        <Grid item alignItems='center' justifyContent='center'>
          <Button
            size='small'
            sx={{ p: '1px', minWidth: 40 }}
            title={selectedIcon}
            onClick={() => setIsOpenIconMenu(true)}
          >
            <Badge
              badgeContent={
                <Box
                  sx={{ background: 'white', borderRadius: '50%' }}
                  title={t('removeIcon')}
                  onClick={e => handleChangeIcon(e, null)}
                >
                  <Cancel
                    sx={{
                      height: 14,
                      width: 14,
                      color: 'red',
                      ':hover': {
                        color: '#8B0000',
                      },
                    }}
                  />
                </Box>
              }
            >
              <CustomSvgIcon src={selectedIconSrc} sx={{ width: '100%', height: 26 }} />
            </Badge>
          </Button>
        </Grid>
      ) : (
        <IconButton
          sx={{ p: '1px', minWidth: 40, minHeight: 40 }}
          title={t('addIcon')}
          onClick={() => setIsOpenIconMenu(true)}
        >
          <AddPhotoAlternate sx={{ height: 26, width: 26 }} />
        </IconButton>
      )}
    </Box>
  )
}
