import { getParamsStringSafe } from '@helpers'

import { ActionParams } from '@pages/ConfiduredGantt/hooks/useGetHandlerMap'

import { GanttFormValues } from '@gantt/components/GanttCreateOrEdit/types'

export const getData = {
  X(data: ActionParams, config: GanttFormValues) {
    const timeline = config.timeline.find(tml => tml.data?.id === data?.data?.objectCode)
    const key = timeline ? getParamsStringSafe(timeline?.key) : ''

    return {
      objectCode: data?.data?.objectCode,
      objectId: data?.data?.[key],
    }
  },
  Y(data: ActionParams, config: GanttFormValues) {
    const key = getParamsStringSafe(config.resource.key)

    return {
      objectCode: config?.resource?.data?.id,
      objectId: data?.data?.row?.[key],
    }
  },
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const stopPropagation = (data: ActionParams) => data?.metaData?.event?.stopPropagation?.()
