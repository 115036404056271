import i18next from 'i18next'
import { FormInputProps } from '@microservices/wiskey-react-components'
import { GridColDef, GridColumns } from '@mui/x-data-grid'
import { ColumnDef } from '@tanstack/react-table'

import {
  ConfigRowsType,
  GETActionView,
  GETContextMenu,
  GETDictionary,
  GETEntityColumn,
  GETField,
  ViewRow,
} from '../types'

import { GENERATOR_INPUT_TYPE } from './forms'

export const SIZE_PAGE = '20'
export const VIEW_ROWS_AMOUNT = 10
export const EMBEDDED_VIEW_ROWS_AMOUNT = 5

export enum TABLE_DESCRIPTION {
  MODIFY = 'modify',
  COMMON = 'common',
}

export enum BIND_TYPE {
  FIELD = 'field',
  JSX = 'jsx',
  JS = 'js',
}

export enum GANTT_BIND_TYPE {
  FIELD = 'field',
  JS = 'js',
  STATIC = 'static',
}

export const VIEW_COLUMNS: ColumnDef<GETEntityColumn>[] = [
  {
    accessorKey: 'code',
    header: i18next.t('viewColumnsTable.id'),
    meta: { flex: 1 },
  },
  {
    accessorKey: 'title',
    header: i18next.t('viewColumnsTable.title'),
    meta: { flex: 1 },
  },
  {
    accessorKey: 'bindType',
    header: i18next.t('viewColumnsTable.bindType'),
    meta: { flex: 1 },
  },
  {
    accessorKey: 'value',
    header: i18next.t('viewColumnsTable.value'),
    meta: { flex: 1 },
  },
  {
    accessorKey: 'valueType',
    header: i18next.t('viewColumnsTable.valueType'),
    meta: { flex: 1 },
  },
  {
    accessorKey: 'columnToSort',
    header: i18next.t('viewColumnsTable.columnToSort'),
    meta: { flex: 1 },
  },
  {
    accessorKey: 'pinnedColumn',
    header: i18next.t('viewColumnsTable.pinnedColumn'),
    meta: { flex: 1 },
  },
]

export const VIEW_ACTION_COLUMNS: ColumnDef<GETActionView>[] = [
  {
    accessorKey: 'code',
    header: i18next.t('viewActionsTable.id'),
    meta: { flex: 1 },
  },
  {
    accessorKey: 'title',
    header: i18next.t('viewActionsTable.title'),
    meta: { flex: 1 },
  },
  {
    accessorKey: 'eventCode',
    header: i18next.t('viewActionsTable.eventCode'),
    meta: { flex: 1 },
  },
  {
    accessorKey: 'actionCode',
    header: i18next.t('viewActionsTable.actionCode'),
    meta: { flex: 1 },
  },
  {
    accessorKey: 'params',
    header: i18next.t('viewActionsTable.params'),
    meta: { flex: 1 },
  },
  {
    accessorKey: 'objectFormCode',
    header: i18next.t('viewActionsTable.objectFormCode'),
    meta: { flex: 1 },
  },
  {
    accessorKey: 'contextMenu',
    header: i18next.t('viewActionsTable.contextMenu'),
    meta: { flex: 1 },
    cell: props => {
      const value = props.getValue() as GETContextMenu | null
      return value?.title
    },
  },
  {
    accessorKey: 'target',
    header: i18next.t('viewActionsTable.targetColumn'),
    meta: { flex: 1 },
    cell: props => {
      const value = props.getValue() as ViewRow | null
      return value?.title
    },
  },
]

export const CONFIG_COLUMNS: GridColumns<ConfigRowsType> = [
  {
    field: 'id',
    headerName: i18next.t('configColumnsTable.id'),
  },
  {
    field: 'code',
    headerName: i18next.t('configColumnsTable.code'),
    flex: 1,
  },
  {
    field: 'title',
    headerName: i18next.t('configColumnsTable.title'),
    flex: 1,
  },
  {
    field: 'objectTitle',
    headerName: i18next.t('configColumnsTable.objectTitle'),
    flex: 1,
  },
]

export const CONFIG_CONTROL_PANELS_COLUMNS: GridColumns<ConfigRowsType> = [
  { field: 'id', headerName: i18next.t('configColumnsTable.id') },
  {
    field: 'title',
    headerName: i18next.t('configColumnsTable.title'),
    flex: 1,
  },
]

export const FORM_COLUMNS: ColumnDef<GETField>[] = [
  {
    accessorKey: 'code',
    header: i18next.t('formColumnsTable.code'),
    meta: { flex: 1 },
  },
  {
    accessorKey: 'title',
    header: i18next.t('formColumnsTable.title'),
    meta: { flex: 1 },
  },
  {
    accessorKey: 'bindType',
    header: i18next.t('formColumnsTable.bindType'),
    meta: { flex: 1 },
  },
  {
    accessorKey: 'value',
    header: i18next.t('formColumnsTable.value'),
    meta: { flex: 1 },
  },
  {
    accessorKey: 'valueType',
    header: i18next.t('formColumnsTable.valueType'),
    meta: { flex: 1 },
  },
]

export const VIEW_OR_FORM_CREATE_INPUTS = ({
  bindingValues = [],
  bindingValuesTypes = [],
}: {
  bindingValues?: GETDictionary[]
  bindingValuesTypes?: GETDictionary[]
}): FormInputProps[] => [
  {
    name: 'rowBindingType',
    inputType: GENERATOR_INPUT_TYPE.SELECT,
    placeholder: i18next.t('placeholder.bindType'),
    label: 'Binding',
    selectOptions: bindingValues?.map(cat => ({ id: cat.id, name: cat.code })),
    rules: { required: true, validate: value => value !== i18next.t('placeholder.bindType') },
  },
  {
    name: 'rowBindingValueTypeCode',
    inputType: GENERATOR_INPUT_TYPE.SELECT,
    placeholder: i18next.t('placeholder.valueType'),
    label: 'Value Type',
    selectOptions: bindingValuesTypes?.map(cat => ({ id: cat.id, name: cat.code })),
    rules: { required: true, validate: value => value !== i18next.t('placeholder.valueType') },
  },
  {
    name: 'rowBindingValue',
    inputType: GENERATOR_INPUT_TYPE.INPUT,
    placeholder: i18next.t('placeholder.value'),
    label: 'Value',
    rules: { validate: value => value.trim().length !== 0 },
  },
]

export const DICTIONARY_TABLE_COLUMNS: GridColDef[] = [
  {
    field: 'id',
    headerName: i18next.t('dictionaryTable.id'),
  },
  {
    field: 'code',
    headerName: i18next.t('dictionaryTable.code'),
    width: 300,
  },
  {
    field: 'title',
    headerName: i18next.t('dictionaryTable.title'),
    flex: 1,
  },
  {
    field: 'categoryTitle',
    headerName: i18next.t('dictionaryTable.categoryTitle'),
    flex: 1,
  },
]

export const OBJECT_FIELD_CHANGES_COLUMNS: GridColDef[] = [
  {
    field: 'changeDate',
    headerName: i18next.t('objectFieldChangesColumnsTable.changeDate'),
    flex: 1,
  },
  {
    field: 'author',
    headerName: i18next.t('objectFieldChangesColumnsTable.author'),
    flex: 1,
  },
  {
    field: 'field',
    headerName: i18next.t('objectFieldChangesColumnsTable.field'),
    flex: 1,
  },
  {
    field: 'newValue',
    headerName: i18next.t('objectFieldChangesColumnsTable.newValue'),
    flex: 1,
  },
  {
    field: 'oldValue',
    headerName: i18next.t('objectFieldChangesColumnsTable.oldValue'),
    flex: 1,
  },
]

export const SEARCH_ASSISTANT_TABLE_COLUMNS: GridColDef[] = [
  { field: 'id', headerName: i18next.t('searchAssistantTable.id'), flex: 1, minWidth: 50 },
  {
    field: 'code',
    headerName: i18next.t('searchAssistantTable.iternalID'),
    flex: 1,
    minWidth: 170,
  },
  { field: 'title', headerName: i18next.t('searchAssistantTable.title'), flex: 1, minWidth: 170 },
  {
    field: 'objectTitle',
    headerName: i18next.t('searchAssistantTable.object'),
    flex: 1,
    minWidth: 170,
  },
  // {
  //   field: 'valueParams',
  //   headerName: i18next.t('searchAssistantTable.value_params'),
  //   flex: 1,
  //   minWidth: 170,
  // },
  // {
  //   field: 'value',
  //   headerName: i18next.t('searchAssistantTable.value'),
  //   flex: 1,
  //   minWidth: 170,
  //   sortable: false,
  //   valueFormatter: params =>
  //     params.value.map((value: GETCriteriaValueType) => value.name).join(', '),
  // },
  // {
  //   field: 'fieldType',
  //   headerName: i18next.t('searchAssistantTable.field_type'),
  //   flex: 1,
  //   minWidth: 170,
  // },
  // {
  //   field: 'objectValue',
  //   headerName: i18next.t('searchAssistantTable.objectValue'),
  //   flex: 1,
  //   minWidth: 170,
  // },
]

export const CONTEXTS_COLUMNS: GridColumns<ConfigRowsType> = [
  {
    field: 'title',
    headerName: i18next.t('configColumnsTable.title'),
    flex: 1,
  },
]

export const CONFIG_CONTEXT_MENU_COLUMNS: GridColumns<ConfigRowsType> = [
  {
    field: 'id',
    headerName: i18next.t('configColumnsTable.id'),
  },
  {
    field: 'title',
    headerName: i18next.t('configColumnsTable.title'),
    flex: 1,
  },
  {
    field: 'objectTitle',
    headerName: i18next.t('configColumnsTable.objectTitle'),
    flex: 1,
  },
]

export const LIST_CONTROL_COLUMNS = VIEW_COLUMNS

export const LIST_CONTROL_ACTION_COLUMNS = VIEW_ACTION_COLUMNS
