import { FC, ReactNode, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import i18next from 'i18next'
import isObject from 'lodash/isObject'
import {
  BIND_TYPE,
  DROPDOWN_FILTER_TYPES,
  ENTITY,
  FIELD_VALUE_FORMAT,
  FIELD_VALUE_TYPE,
  FORMATS_DATE_OPTIONS,
  GENERATOR_INPUT_TYPE,
  MAX_INPUT_LENGTH,
  MODAL_TYPE,
  OBJECT_FIELD_TYPE,
  REGEX,
} from '@constants'
import {
  checkValidJS,
  generateDefaultObjectByFields,
  getEntityTypeForRequest,
  getObjectValueName,
  isObjectValueType,
  mapValueTypeToValueFormat,
} from '@helpers'
import { usePrompt } from '@hooks'
import { FormGenerator, FormInputProps, ModalWrapper } from '@microservices/wiskey-react-components'
import { FormInputsType } from '@microservices/wiskey-react-components/dist/types'
import { DoneAll, ReportProblemOutlined as AlertIcon } from '@mui/icons-material'
import { Box, CircularProgress, Grid, Typography } from '@mui/material'

import { ObjectValueOptionsType } from '@pages/EntityCreateOrEdit/components/AddColumnDialog/hooks'
import { PageContext } from '@pages/FormCreateOrEdit'

import { DropDownInputWithFitlers } from '@components/DropDownInputWithFitlers'
import { ScriptValueDialog } from '@components/ScriptValueDialog'

import {
  useFetchAllFormByObjectCodeQuery,
  useFetchAllViewQuery,
  useFetchObjectByCodeQuery,
} from '@redux/api'

import { FilterDialog } from '../../../../components/FilterDialog'
import {
  AutocompleteOption,
  GETDropDownFilter,
  GETEntityFilter,
  ObjectFieldDTO,
  PayloadCreateField,
} from '../../../../types'

type FieldForm = {
  id?: number | string
  code: string
  bindType?: BIND_TYPE
  title: string
  value: string
  valueType: FIELD_VALUE_TYPE | null
  objectValue?: string
  objectFormCode?: AutocompleteOption | string | null
  embeddedObjectCode?: string
  objectFormTitle?: string
  required: boolean
  editField: boolean
  params?: string
  json?: boolean
  dropDownListCode?: AutocompleteOption | string | null
  dropDownWindowCode?: AutocompleteOption | string | null
  dropDownListFilters: GETDropDownFilter[]
  dropDownWindowFilters: GETDropDownFilter[]
  isDefaultPlaceholder: boolean
  placeholderValue: string
  formatDate: AutocompleteOption | null
  asCheckbox: boolean
  asDuration: boolean
}

const getDefaultValue = (): FieldForm => {
  return {
    code: '',
    bindType: i18next.t('placeholder.bindType'),
    title: '',
    value: i18next.t('placeholder.value'),
    valueType: null,
    objectValue: i18next.t('placeholder.objectValue'),
    objectFormCode: null,
    required: false,
    editField: true,
    params: '',
    json: false,
    dropDownListCode: null,
    dropDownWindowCode: null,
    dropDownListFilters: [],
    dropDownWindowFilters: [],
    isDefaultPlaceholder: true,
    placeholderValue: '',
    formatDate: null,
    asCheckbox: false,
    asDuration: false,
  }
}

export const AddFieldDialog: FC = () => {
  const { t } = useTranslation()
  const defaultValues: FieldForm = getDefaultValue()
  const methods = useForm<FieldForm>({ defaultValues })
  const [isLoading, setLoading] = useState(true)
  const [isShowFilterDialog, setShowFilterDialog] = useState(false)
  const [currentTypeFilter, setCurrentTypeFilter] = useState<DROPDOWN_FILTER_TYPES | null>(null)
  const [currentFilter, setCurrentFilter] = useState<GETEntityFilter | null>(null)

  const {
    handleSubmit,
    reset,
    formState: { isDirty },
    watch,
    setValue,
  } = methods

  const watchBinding = watch('bindType')
  const watchObjectValue = watch('objectValue')
  const watchEdit = watch('editField')
  const watchJson = watch('json')

  const {
    addField,
    currentRow,
    handleCloseModal,
    showDialog,
    modalType,
    editField,
    formCode,
    modifyObjectFields,
    objectFieldsForFilter, // Исходные поля
    bindingValuesFieldRender,
    bindingValuesColumns,
    handleSetModalProperty,
    toggleOpenScriptValueDialog,
    isShowScriptValueDialog,
    objectCode,
    mainTable: { rows: fields },
  } = useContext(PageContext)
  usePrompt({ when: isDirty })
  const [objectField, setObjectField] = useState<ObjectFieldDTO>()
  const embeddedObjectCode = objectField?.model ?? ''
  const isObjectLinkedValue = objectField?.type === OBJECT_FIELD_TYPE.OBJECT_LINK
  const isDatetimeTypeValue = objectField?.valueType === FIELD_VALUE_TYPE.DATETIME
  const valueType = objectField?.valueType || null
  const { data: objectForField } = useFetchObjectByCodeQuery(embeddedObjectCode, {
    skip: !embeddedObjectCode,
  })
  const { data: forms } = useFetchAllFormByObjectCodeQuery(embeddedObjectCode, {
    skip: !embeddedObjectCode,
  })
  const { data: dropdownEntities } = useFetchAllViewQuery(
    {
      objectCode: embeddedObjectCode,
      viewType: getEntityTypeForRequest(ENTITY.DROP_DOWN_ENTITY),
    },
    { refetchOnMountOrArgChange: true, skip: !embeddedObjectCode }
  )

  const srcObj = generateDefaultObjectByFields(objectFieldsForFilter || [])

  const watchValue = watch('value')
  const watchDropdownList = watch('dropDownListCode')
  const watchDropdownWindow = watch('dropDownWindowCode')
  const watchDefaultPlaceholder = watch('isDefaultPlaceholder')
  const watchAsCheckbox = watch('asCheckbox')
  const watchAsDuration = watch('asDuration')

  useEffect(() => {
    const field = objectFieldsForFilter?.find(field => field.name === watchValue)
    setObjectField(field ? field : undefined)
  }, [objectFieldsForFilter, watchValue])

  // Когда поля asСheckbox и asDuration скрываются, нужно ресетить их значения, чтобы случайно не отправилось true
  // при иных valueType
  useEffect(() => {
    if (objectField?.type === OBJECT_FIELD_TYPE.ENUM) {
      return
    }

    if (valueType !== FIELD_VALUE_TYPE.INTEGER && valueType !== FIELD_VALUE_TYPE.BOOLEAN) {
      if (watchAsCheckbox && isDirty) {
        setValue('asCheckbox', false)
      }
    }

    if (valueType !== FIELD_VALUE_TYPE.INTEGER) {
      if (watchAsDuration && isDirty) {
        setValue('asDuration', false)
      }
    }
  }, [valueType])

  const optionsValue = useMemo(() => {
    return (
      modifyObjectFields
        ?.map(({ id, name, isAlreadyUsed, type, isPk, required }) => ({
          id,
          name,
          isAlreadyUsed,
          fieldType: type,
          isPk,
          isObjectLevelRequired: required,
        }))
        .filter(({ name }) => name !== '_id') || []
    )
  }, [modifyObjectFields])

  const currentValue = useMemo(
    () => objectFieldsForFilter?.find(({ name }) => name === currentRow?.value),
    [objectFieldsForFilter, currentRow]
  )

  const dropdownEntityOptions: AutocompleteOption[] = useMemo(
    () =>
      dropdownEntities?.data.map(entity => ({
        id: entity.code,
        label: entity.title,
      })) ?? [],
    [dropdownEntities]
  )

  const formCodeOptions: AutocompleteOption[] = useMemo(
    () =>
      forms?.map(form => ({
        id: form.code,
        label: form.title,
      })) ?? [],
    [forms, embeddedObjectCode]
  )

  const filterObjectValueOptions = useCallback(
    (objectFields: ObjectFieldDTO[]) =>
      objectFields.map(objectField => {
        const isAlreadyUsed = Boolean(
          fields?.find(field => {
            return field?.value === watchValue && field.objectValue === objectField.name
          })
        )

        return { id: objectField.id, name: objectField.name, isAlreadyUsed }
      }),
    [fields, watchValue, currentRow]
  )

  const objectValueOptions = useMemo(
    () =>
      filterObjectValueOptions(
        objectForField?.fields.filter(obj => !isObjectValueType(obj.valueType)) || []
      ),
    [objectForField, filterObjectValueOptions]
  )

  const bindTypeOptions = useMemo(
    () =>
      bindingValuesColumns
        ?.map(cat => ({
          id: cat.id,
          name: cat.code,
        }))
        .filter(cat => !(cat.name === BIND_TYPE.FIELD && optionsValue.length === 0)) || [],
    [bindingValuesColumns, optionsValue]
  )

  const defaultRequired = objectField && objectField.required
  const valueIsNotEntered = watchValue === i18next.t('placeholder.value') || !watchValue

  const isUserRequiredCheckboxDisabled =
    defaultRequired || watchBinding !== BIND_TYPE.FIELD || valueIsNotEntered

  const isObjectValueDisabledByDropdownList =
    watchDropdownList === t('placeholder.dropDownList') ? false : watchDropdownList !== null

  const isObjectValueDisabledByDropdownWindow =
    watchDropdownWindow === t('placeholder.dropDownWindow') ? false : watchDropdownWindow !== null

  const objectLinkedInputs: FormInputsType[] = [
    {
      name: 'formCodeRow',
      inputs: [
        ...(watchJson
          ? [
              {
                name: 'params',
                inputType: GENERATOR_INPUT_TYPE.TEXTAREA,
                placeholder: t('placeholder.params'),
                label: t('label.params'),
                autocompleteOptions: formCodeOptions,
                rules: { required: watchJson },
              },
            ]
          : [
              {
                name: 'objectFormCode',
                inputType: GENERATOR_INPUT_TYPE.AUTOCOMPLETE,
                placeholder: t('placeholder.objectFormCode'),
                label: t('label.objectFormCode'),
                autocompleteOptions: formCodeOptions,
              },
            ]),
        {
          name: 'json',
          inputType: GENERATOR_INPUT_TYPE.CHECKBOX,
          label: t('label.json'),
          labelPlacement: 'end',
        },
      ],
    },
    {
      name: 'objectValue',
      inputType: GENERATOR_INPUT_TYPE.SELECT,
      placeholder: t('placeholder.objectValue'),
      label: t('label.objectValue'),
      selectOptions: objectValueOptions,
      renderOptionSelect: (option: ObjectValueOptionsType) => {
        return option.isAlreadyUsed ? (
          <>
            <DoneAll sx={{ fontSize: '0.9rem', position: 'absolute', left: 2 }} />
            <Typography ml={1}>{option.name}</Typography>
          </>
        ) : (
          option.name
        )
      },
      ...(watchObjectValue !== i18next.t('placeholder.objectValue') && {
        renderValueSelect: value => value,
      }),
      rules: {
        required: true,
        validate: (value: string) => value !== i18next.t('placeholder.value'),
      },
      // disabled: isObjectValueDisabledByDropdownList || isObjectValueDisabledByDropdownWindow,
      rules: {
        required: true,
        validate: (value: string) => value !== i18next.t('placeholder.objectValue'),
      },
    },
  ]

  const formatDateInput: FormInputsType[] = [
    {
      name: 'formatDate',
      inputType: GENERATOR_INPUT_TYPE.AUTOCOMPLETE,
      placeholder: 'Format Date',
      label: 'Format Date',
      autocompleteOptions: FORMATS_DATE_OPTIONS,
    },
  ]

  const shouldDisplayAsCheckboxField =
    (valueType === FIELD_VALUE_TYPE.INTEGER || valueType === FIELD_VALUE_TYPE.BOOLEAN) &&
    objectField?.type !== OBJECT_FIELD_TYPE.ENUM

  const valueFieldForFieldBinding: FormInputsType[] = [
    {
      name: 'valueTypeRow',
      inputs: [
        {
          name: 'value',
          inputType: GENERATOR_INPUT_TYPE.SELECT,
          label: t('label.value'),
          placeholder: t('placeholder.value'),
          disabled: !watchBinding || watchBinding === i18next.t('placeholder.bindType'),
          selectOptions: optionsValue,
          MenuProps: { PaperProps: { sx: { maxHeight: 300 } } },
          renderOptionSelect: (option: ObjectValueOptionsType) => {
            return (
              <Box display='flex' justifyContent='space-between' width='100%'>
                <Box alignItems='center' display='flex'>
                  {option.isAlreadyUsed && (
                    <DoneAll sx={{ fontSize: '0.9rem', position: 'absolute', left: 2 }} />
                  )}
                  <Typography ml={1}>
                    {getObjectValueName(option.name, option.fieldType, option.isPk)}
                  </Typography>
                </Box>
                {option.isObjectLevelRequired && (
                  <Box sx={{ display: 'flex' }} title={t('form.fieldObjectRequired')}>
                    <AlertIcon
                      sx={{
                        color: theme => theme.palette.color.warning,
                        fontSize: '1.3rem',
                      }}
                    />
                  </Box>
                )}
              </Box>
            )
          },
          ...(watchValue !== i18next.t('placeholder.value') && {
            renderValueSelect: value => {
              const option = optionsValue.find(el => el.name === value)

              if (option) {
                const nameWithPostfix = getObjectValueName(
                  option.name,
                  option.fieldType,
                  option.isPk
                )

                return (
                  <Box title={option.name !== nameWithPostfix ? nameWithPostfix : undefined}>
                    {value as ReactNode}
                  </Box>
                )
              }

              return value
            },
          }),
          rules: {
            required: true,
            validate: (value: string) => value !== i18next.t('placeholder.value'),
          },
        } as FormInputProps,
        ...(shouldDisplayAsCheckboxField
          ? [
              {
                name: 'asCheckbox',
                inputType: GENERATOR_INPUT_TYPE.CHECKBOX,
                label: t('label.asCheckbox'),
                labelPlacement: 'end',
                disabled: !!watchAsDuration,
              } as FormInputProps,
            ]
          : []),
        ...(valueType === FIELD_VALUE_TYPE.INTEGER
          ? [
              {
                name: 'asDuration',
                inputType: GENERATOR_INPUT_TYPE.CHECKBOX,
                label: t('label.asDuration'),
                labelPlacement: 'end',
                disabled: !!watchAsCheckbox,
              } as FormInputProps,
            ]
          : []),
      ],
    },
    {
      name: 'valueType',
      inputType: GENERATOR_INPUT_TYPE.INPUT,
      label: t('label.valueType'),
      placeholder: t('placeholder.valueType'),
      disabled: true,
      value: valueType,
    },
  ]

  const valueFieldForJsBinding: FormInputsType[] = [
    {
      name: 'value',
      inputType: GENERATOR_INPUT_TYPE.TEXTAREA,
      placeholder: t('placeholder.fieldValue'),
      label: t('label.value'),
      maxLengthInput: MAX_INPUT_LENGTH,
      readOnly: true,
      rules: {
        required: true,
        maxLength: MAX_INPUT_LENGTH,
        validate: (value: string) => {
          if (checkValidJS(value, { srcObj }).error) {
            return t('error.valueJS')
          }
        },
      },
      additionalBtn: {
        isEnabled: true,
        text: 'edit',
        color: 'primary',
        variant: 'contained',
        onClick: () => toggleOpenScriptValueDialog(true),
      },
    },
  ]

  const fieldInputs: FormInputsType[] = [
    {
      name: 'code',
      inputType: GENERATOR_INPUT_TYPE.INPUT,
      placeholder: t('placeholder.internalId'),
      label: t('label.internalId'),
      replacePattern: REGEX.MODEL_CODE_REPLACE_PATTERN,
      disabled: modalType === MODAL_TYPE.EDIT,
      rules: {
        required: true,
        validate: (value: string) => {
          if (fields.find(field => field.code === value && currentRow?.code !== value)) {
            return `${value} is already in used!`
          }
        },
      },
    },
    {
      name: 'titleRow',
      inputs: [
        {
          name: 'title',
          inputType: GENERATOR_INPUT_TYPE.INPUT,
          placeholder: t('placeholder.title'),
          label: t('label.title'),
          rules: { validate: (value: string) => value.trim().length !== 0 },
        },
        {
          name: 'required',
          inputType: GENERATOR_INPUT_TYPE.CHECKBOX,
          label: t('label.userRequired'),
          labelPlacement: 'end',
          disabled: isUserRequiredCheckboxDisabled,
        },
        {
          name: 'editField',
          inputType: GENERATOR_INPUT_TYPE.CHECKBOX,
          label: t('label.editField'),
          labelPlacement: 'end',
          ...(watchBinding === BIND_TYPE.JS && { disabled: true }),
        },
      ],
    },

    {
      name: 'placeholderRow',
      inputs: [
        {
          name: 'placeholderValue',
          inputType: GENERATOR_INPUT_TYPE.INPUT,
          placeholder: t('placeholder.placeholder'),
          label: t('label.placeholder'),
          disabled: watchDefaultPlaceholder,
        },
        {
          name: 'isDefaultPlaceholder',
          inputType: GENERATOR_INPUT_TYPE.CHECKBOX,
          label: t('label.placeholderCheckbox'),
          labelPlacement: 'end',
        },
      ],
    },
    {
      name: 'bindType',
      inputType: GENERATOR_INPUT_TYPE.SELECT,
      placeholder: t('placeholder.bindType'),
      label: t('label.bindType'),
      selectOptions: bindTypeOptions,
      onChangeSelect: value => {
        if (value === BIND_TYPE.JS) {
          setValue('editField', false)
        }

        if (value === BIND_TYPE.FIELD) {
          setValue('editField', true)
        }
      },
      rules: { required: true, validate: value => value !== t('placeholder.bindType') },
    },
    ...(isObjectLinkedValue ? objectLinkedInputs : []),
    ...(watchBinding === BIND_TYPE.JS ? valueFieldForJsBinding : valueFieldForFieldBinding),
    ...(isDatetimeTypeValue ? formatDateInput : []),
  ]

  const dropwDownListFormInput: FormInputsType = {
    name: 'dropDownListCode',
    inputType: GENERATOR_INPUT_TYPE.AUTOCOMPLETE,
    placeholder: t('placeholder.dropDownList'),
    label: t('label.dropDownList'),
    autocompleteOptions: dropdownEntityOptions,
  }

  const dropwDownWindowFormInput: FormInputsType = {
    name: 'dropDownWindowCode',
    inputType: GENERATOR_INPUT_TYPE.AUTOCOMPLETE,
    placeholder: t('placeholder.dropDownWindow'),
    label: t('label.dropDownWindow'),
    autocompleteOptions: dropdownEntityOptions,
  }

  useEffect(() => {
    if (currentRow) {
      const field = objectFieldsForFilter?.find(field => field.name === watchValue)

      reset({
        ...currentRow,
        valueType,
        objectFormCode: currentRow?.objectFormCode
          ? {
              id: currentRow?.objectFormCode,
              label: currentRow?.objectFormTitle,
            }
          : null,
        params: currentRow.params ? JSON.stringify(currentRow.params) : '',
        json: Boolean(currentRow.params),
        required: Boolean(currentRow?.userRequired),
        dropDownListCode: !currentRow.dropDownList
          ? null
          : {
              id: currentRow.dropDownList.code,
              label: currentRow.dropDownList.title,
            },
        dropDownWindowCode: !currentRow.dropDownWindow
          ? null
          : {
              id: currentRow.dropDownWindow.code,
              label: currentRow.dropDownWindow.title,
            },
        dropDownListFilters: currentRow.dropDownListFilters || [],
        dropDownWindowFilters: currentRow.dropDownWindowFilters || [],
        placeholderValue: currentRow.placeholderValue || '',
        isDefaultPlaceholder: Boolean(currentRow.isDefaultPlaceholder),
        formatDate: currentRow.formatDate
          ? { id: currentRow.formatDate, label: currentRow.formatDate }
          : null,
        asCheckbox:
          currentRow.valueFormat === FIELD_VALUE_FORMAT.NUMBER ||
          currentRow.valueFormat === FIELD_VALUE_FORMAT.BOOLEAN,
        asDuration: currentRow.valueFormat === FIELD_VALUE_FORMAT.TIME,
      })
      setLoading(false)
      setObjectField(field ? field : undefined)
    }
  }, [currentRow])

  useEffect(() => {
    if (objectField?.required) {
      setValue('required', true)
    }
  }, [objectField])

  useEffect(() => {
    handleSetModalProperty?.('field')
  }, [])

  useEffect(() => {
    if (currentRow?.userRequired && watchBinding === BIND_TYPE.JS) {
      setValue('required', false)
    }
  }, [watchBinding])

  const handleSave = (data: FieldForm) => {
    const {
      id = Date.now(),
      code,
      title,
      value,
      objectValue,
      objectFormCode,
      bindType = BIND_TYPE.FIELD,
      required,
      params,
      json,
      dropDownListCode,
      dropDownWindowCode,
      dropDownListFilters,
      dropDownWindowFilters,
      isDefaultPlaceholder,
      placeholderValue,
      formatDate,
      asCheckbox,
      asDuration,
      editField: isEdit,
    } = data

    const valueFormat = {
      valueFormat: objectField?.valueType
        ? mapValueTypeToValueFormat(objectField?.valueType, asCheckbox, asDuration)
        : null,
    }

    const requestBody: PayloadCreateField = {
      code,
      title,
      value,
      bindType,
      id,
      formCode,
      editField: isEdit,
      objectValue: isObjectLinkedValue ? objectValue : undefined,
      embeddedObjectCode: isObjectLinkedValue ? embeddedObjectCode : undefined,
      userRequired: watchBinding === BIND_TYPE.FIELD ? required : null,
      ...(json
        ? { params }
        : {
            objectFormCode:
              isObject(objectFormCode) && objectFormCode?.id
                ? objectFormCode.id.toString()
                : undefined,
          }),
      dropDownListCode:
        isObject(dropDownListCode) && dropDownListCode?.id ? (dropDownListCode?.id as string) : '',
      dropDownWindowCode:
        isObject(dropDownWindowCode) && dropDownWindowCode?.id
          ? (dropDownWindowCode?.id as string)
          : '',
      dropDownListFilters: dropDownListFilters || [],
      dropDownWindowFilters: dropDownWindowFilters || [],
      placeholderValue,
      isDefaultPlaceholder,
      formatDate: formatDate ? formatDate.label : null,
      ...valueFormat,
    }

    if (modalType === MODAL_TYPE.EDIT && currentRow) {
      editField?.(requestBody)
        .unwrap()
        .then(() => handleCloseModal())
        .catch(console.error)

      return
    }

    addField?.(requestBody)
      .unwrap()
      .then(() => handleCloseModal())
      .catch(console.error)

    reset(defaultValues)
  }

  const [isFieldsLoading, setFieldsLoading] = useState(true)
  useEffect(() => {
    if (bindingValuesFieldRender) {
      setFieldsLoading(false)
    }
  }, [bindingValuesFieldRender])

  useEffect(() => {
    if (watchValue !== currentRow?.value) {
      setValue('objectFormCode', null)

      if (objectField?.required === false) {
        setValue('required', false)
      }
    }
  }, [watchValue, objectField])

  useEffect(() => {
    if (watchBinding === BIND_TYPE.JS) {
      const value =
        currentRow?.value && currentRow.bindType === BIND_TYPE.JS ? currentRow.value : ''

      setValue('value', value)
    }
    if (watchBinding === BIND_TYPE.FIELD) {
      const value =
        currentRow?.value && currentRow.bindType === BIND_TYPE.FIELD
          ? currentRow.value
          : t('placeholder.value')

      setValue('value', value)
    }
  }, [watchBinding])

  const handleDoubleClickChip = (filter: GETEntityFilter, type: DROPDOWN_FILTER_TYPES) => {
    setCurrentFilter(filter)
    setCurrentTypeFilter(type)
    setShowFilterDialog(true)
  }

  const handleAddFilter = (type: DROPDOWN_FILTER_TYPES) => {
    setShowFilterDialog(true)
    setCurrentTypeFilter(type)
  }

  const handleResetFilterDialog = () => {
    setShowFilterDialog(false)
    setCurrentFilter(null)
    setCurrentTypeFilter(null)
  }

  const handleSubmitFilter = (filter: GETEntityFilter) => {
    if (currentTypeFilter) {
      const newFilter = { ...filter, type: currentTypeFilter }
      const isList = currentTypeFilter === DROPDOWN_FILTER_TYPES.LIST
      const inputName = isList ? 'dropDownListFilters' : 'dropDownWindowFilters'
      const updatedFilters = (isList ? watchedDropDownListFilters : watchedDropDownWindowFilters)
        .map(item => (item.id === filter.id ? newFilter : item))
        .map(item => (item.id === filter.id ? newFilter : item))

      if (filter.id) {
        setValue(inputName, updatedFilters, {
          shouldDirty: true,
        })

        handleResetFilterDialog()

        return
      }

      setValue(
        inputName,
        [...(isList ? watchedDropDownListFilters : watchedDropDownWindowFilters), newFilter],
        {
          shouldDirty: true,
        }
      )
      handleResetFilterDialog()
    }
  }

  const handleDeleteFilter = (filter: GETEntityFilter, type: DROPDOWN_FILTER_TYPES) => {
    if (type === DROPDOWN_FILTER_TYPES.LIST) {
      setValue(
        'dropDownListFilters',
        watchedDropDownListFilters.filter(({ id }) => id !== filter.id),
        { shouldDirty: true }
      )
    }
    if (type === DROPDOWN_FILTER_TYPES.WINDOW) {
      setValue(
        'dropDownWindowFilters',
        watchedDropDownWindowFilters.filter(({ id }) => id !== filter.id),
        { shouldDirty: true }
      )
    }
  }

  const handleCloseFilterDialog = (isDirty: boolean) => {
    if (isDirty && !confirm(t('notifications.leave'))) {
      return
    }

    handleResetFilterDialog()
  }

  const watchedDropDownListFilters = watch('dropDownListFilters')
  const watchedDropDownWindowFilters = watch('dropDownWindowFilters')

  return (
    <ModalWrapper
      containerStyle={{ px: 0.5, py: 0.5, borderRadius: 0 }}
      disabledSave={!isDirty}
      isShow={showDialog}
      title={`${t(`addDialog.${modalType}`)} Field`}
      onClose={() => handleCloseModal(isDirty)}
      onSave={handleSubmit(handleSave)}
    >
      {isShowFilterDialog && (
        <FilterDialog
          filter={currentFilter}
          isShow={isShowFilterDialog}
          onClose={handleCloseFilterDialog}
          onSubmit={handleSubmitFilter}
        />
      )}
      {isLoading && modalType === MODAL_TYPE.EDIT ? (
        <Grid container alignItems={'center'} justifyContent={'center'} my={3}>
          <CircularProgress />
        </Grid>
      ) : (
        <Box pb={0.25} pt={1}>
          <FormProvider {...methods}>
            {isShowScriptValueDialog && (
              <ScriptValueDialog
                windowBoundaries
                isJsx={watchBinding === BIND_TYPE.JSX}
                isShow={isShowScriptValueDialog}
                modalType={modalType}
                objectFields={objectFieldsForFilter}
                value={watchValue}
                onClose={() => toggleOpenScriptValueDialog(false)}
                onSave={value => setValue('value', value, { shouldDirty: true })}
              />
            )}
            <form>
              <FormGenerator inputs={fieldInputs} loading={isFieldsLoading} mainGridSpacing={0} />
              {isObjectValueType(valueType) && (
                <>
                  <DropDownInputWithFitlers
                    filters={watchedDropDownListFilters}
                    inputs={[dropwDownListFormInput]}
                    loading={isFieldsLoading}
                    watchInput={watchDropdownList}
                    onAddFilter={() => handleAddFilter(DROPDOWN_FILTER_TYPES.LIST)}
                    onDeleteFilter={filter =>
                      handleDeleteFilter(filter, DROPDOWN_FILTER_TYPES.LIST)
                    }
                    onDoubleClickFilter={filter =>
                      handleDoubleClickChip(filter, DROPDOWN_FILTER_TYPES.LIST)
                    }
                  />
                  <DropDownInputWithFitlers
                    filters={watchedDropDownWindowFilters}
                    inputs={[dropwDownWindowFormInput]}
                    loading={isFieldsLoading}
                    watchInput={watchDropdownWindow}
                    onAddFilter={() => handleAddFilter(DROPDOWN_FILTER_TYPES.WINDOW)}
                    onDeleteFilter={filter =>
                      handleDeleteFilter(filter, DROPDOWN_FILTER_TYPES.WINDOW)
                    }
                    onDoubleClickFilter={filter =>
                      handleDoubleClickChip(filter, DROPDOWN_FILTER_TYPES.WINDOW)
                    }
                  />
                </>
              )}
            </form>
          </FormProvider>
        </Box>
      )}
    </ModalWrapper>
  )
}
