import { useCallback } from 'react'
import { get } from 'react-hook-form'
import { v4 as uuid } from 'uuid'
import { ENTITY, FORM_TYPE } from '@constants'
import { getInitialPositionWindow, getParamsStringSafe, getVerifyFormCode } from '@helpers'
import { useAppDispatch } from '@hooks'
import { DIALOG_WINDOW_TYPE, MouseEventWithView } from '@types'

import { ActionParams } from '@pages/ConfiduredGantt/hooks/useGetHandlerMap'

import { openFormDialog } from '@redux/reducers/dialogWindowManager.reducer'
import { createHistoryByView } from '@redux/reducers/formHistory.reducer'

import { GanttActionType, GanttFormValues } from '@gantt/components/GanttCreateOrEdit/types'

export const useOpenForm = (config: GanttFormValues, path: string, id: string) => {
  const dispatch = useAppDispatch()

  return useCallback(
    (action: GanttActionType, data: ActionParams, details: any, actions: GanttActionType[]) => {
      const verifiedFormCode = getVerifyFormCode({
        eventCode: action.eventCode,
        row: data?.data,
        actions: actions.map(action => ({
          ...action,
          objectFormCode: get(action, 'actionField.form.id'),
          params: getParamsStringSafe(action.actionField),
        })),
      })

      const dialogId = uuid()

      if (verifiedFormCode) {
        dispatch(
          createHistoryByView({
            id: dialogId,
            form: {
              formCode: verifiedFormCode,
              objectCode: details.objectCode,
              objectId: details.objectId,
              event: FORM_TYPE.VIEW,
              isWindow: true,
              title: verifiedFormCode,
              id: dialogId,
            },
            view: {
              objectCode: details.objectCode,
              title: config.title || '',
              viewPath: path,
              id: dialogId,
            },
          })
        )

        dispatch(
          openFormDialog({
            id: dialogId,
            parentDialogId: null,
            type: DIALOG_WINDOW_TYPE.FORM,
            title: null,
            meta: {
              formCode: verifiedFormCode,
              viewCode: config?.code,
              path,
              event: FORM_TYPE.VIEW,
              globalId: dialogId,
              entityType: ENTITY.GANTT_CHART,
              viewId: id,
              ...details,
            },
            initialPosition: getInitialPositionWindow(data?.metaData?.event as MouseEventWithView),
          })
        )
      }

      return true
    },
    [config?.code, id, path]
  )
}
