import { KeyboardEvent, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { MODAL_TYPE, ROUTES } from '@constants'
import { POSTCopyContext } from '@types'

import { useCopyContextMutation, useDeleteContextMutation } from '@redux/api'

import { ContextRowType } from '../types'

export const useHandlers = () => {
  const navigate = useNavigate()

  const [showCopyDialog, setShowCopyDialog] = useState<boolean>(false)
  const [copyContextData, setCopyContextData] = useState<ContextRowType | null>(null)

  const [copyContext, { isLoading: isLoadingCopyContext }] = useCopyContextMutation()
  const [deleteContext] = useDeleteContextMutation()

  const handleCreateContext = () => navigate(MODAL_TYPE.CREATE)

  const handleEditContext = (id: number) => navigate(`${ROUTES.CONFIG_CONTEXTS_EDIT}/${id}`)

  const handleCopyContext = (data: POSTCopyContext) => {
    const { title, copyId } = data

    copyContext({ title, copyId })
      .unwrap()
      .then(res => {
        setShowCopyDialog(false)
        navigate(`${ROUTES.CONFIG_CONTEXTS_EDIT}/${res.id}`)
      })
  }

  const handleDeleteContext = (id: number) => deleteContext(id)

  const handleShowDialog = (data: ContextRowType) => {
    setCopyContextData(data)
    setShowCopyDialog(true)
  }

  const handleCloseDialog = () => {
    setCopyContextData(null)
    setShowCopyDialog(false)
  }

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.code === 'Enter') {
      e.preventDefault()
      e.stopPropagation()
    }
  }

  return {
    state: {
      showCopyDialog,
      copyContextData,
      isLoadingCopyContext,
    },
    handlers: {
      handleShowDialog,
      handleCloseDialog,
      handleCopyContext,
      handleCreateContext,
      handleEditContext,
      handleDeleteContext,
      handleKeyDown,
    },
  }
}
