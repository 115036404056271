import { useTranslation } from 'react-i18next'
import { GENERATOR_INPUT_TYPE } from '@constants'
import { FormInput } from '@microservices/wiskey-react-components'
import { Box } from '@mui/material'

import { TableWithActions } from '@pages/Contexts/components/TableWithActions'

import { VARIABLE_COLUMNS } from '../constants/columns'

export const Variables = () => {
  const { t } = useTranslation()

  return (
    <Box>
      <FormInput
        inputType={GENERATOR_INPUT_TYPE.AUTOCOMPLETE}
        label={t('contexts.title.variable')}
        name='variableName'
        placeholder={t('contexts.placeholder.selectVariable')}
        autocompleteOptions={[]}
        //loading={loading}
      />
      <TableWithActions
        columns={VARIABLE_COLUMNS}
        loading={false}
        rows={[]}
        //onDelete={onDeleteContext}
      />
    </Box>
  )
}
