// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { getEnrichedParams, transformResponseWithPagination } from '@helpers'
import { FetchBaseQueryMeta } from '@reduxjs/toolkit/query'
import { fetchGanttChartDataBody, GanttChartFilters, GanttType, GetGanttChartData } from '@types'

import { GanttFormValues } from '@gantt/components/GanttCreateOrEdit/types'

import { commonAPI } from './common.api'

export const ganttChartApi = commonAPI.injectEndpoints({
  endpoints: build => ({
    updateGanttChartProperty: build.mutation<any, { param: string }>({
      query: ({ param }) => ({
        url: `/gantChart`,
        method: 'POST',
        body: {
          param,
        },
      }),
    }),
    fetchGanttChartData: build.query<any, { params: fetchGanttChartDataBody }>({
      query: ({ params: { sort, filters, ...rest } }) => ({
        url: `/gantChart/data/${rest.id}?${getEnrichedParams(
          ['sort', 'filters'],
          [sort || undefined, filters || undefined]
        )}`,
        params: rest,
      }),
      transformResponse: (output: GetGanttChartData, meta: FetchBaseQueryMeta) =>
        transformResponseWithPagination<GetGanttChartData>(output, meta),
    }),
    fetchGanttChartBacklog: build.query<any, { params: fetchGanttChartDataBody }>({
      query: ({ params: { sort, filters, ...rest } }) => ({
        url: `/gantChart/backlog/${rest.id}?${getEnrichedParams(
          ['filters'],
          [filters || undefined]
        )}`,
        params: rest,
      }),
    }),
    fetchGanttChartConfig: build.query<GanttFormValues, { id: number | string }>({
      query: ({ id, ...rest }) => ({
        url: `/gantChart/config/${id}`,
        params: rest,
      }),
    }),
    fetchGanttChart: build.query<GanttType[], void>({
      query: () => ({
        url: `/gantChart/all`,
      }),
    }),
    deleteGanttChart: build.mutation<any, { id: number | string }>({
      query: ({ id }) => ({
        url: `/gantChart/${id}`,
        method: 'DELETE',
      }),
    }),
    fetchTimeLineData: build.mutation<
      any,
      { viewId: string; timelineId: string; filters: GanttChartFilters; objectCode: string }
    >({
      query: ({ viewId, timelineId, filters, objectCode }) => ({
        url: `/gantChart/${viewId}/timeline/${timelineId}?${getEnrichedParams(
          ['filters'],
          [filters || undefined]
        )}`,
        method: 'GET',
        params: { objectCode },
      }),
    }),
    updateGanttBox: build.mutation<
      any,
      { viewId: number; timelineId: string; objectCode: string; changes: Record<string, any> }
    >({
      query: ({ viewId, timelineId, objectCode, changes }) => ({
        url: `/router/storage/object/gantt/${viewId}`,
        method: 'POST',
        params: { timelineId, objectCode },
        body: { ...changes },
      }),
    }),
    runAction: build.mutation<
      any,
      { objectId: string; command: string; objectCode: string; statusCode?: string }
    >({
      query: ({ objectId, command, objectCode, statusCode, ...body }) => ({
        url: `/router/object/${objectId}/state/${command}`,
        method: 'POST',
        params: { objectCode, statusCode },
        body,
      }),
    }),
  }),
})

export const {
  useUpdateGanttBoxMutation,
  useUpdateGanttChartPropertyMutation,
  useFetchGanttChartDataQuery,
  useFetchGanttChartConfigQuery,
  useFetchGanttChartBacklogQuery,
  useFetchGanttChartQuery,
  useDeleteGanttChartMutation,
  useFetchTimeLineDataMutation,
  useRunActionMutation,
} = ganttChartApi
