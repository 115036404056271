import { GETAllObject } from 'src/types/api/objects.api'
import { getEnrichedSortParam, transformResponseWithPagination } from '@helpers'
import { FetchBaseQueryMeta } from '@reduxjs/toolkit/query/react'

import {
  GETEnumForObject,
  GETObject,
  GETObjectData,
  GETObjectDataParams,
  GETObjectDataParamsEnriched,
  GETObjectDataRecordEnrichedParams,
  GETObjectDataRecordParams,
  GETObjectEnumParams,
  GETObjectFieldChanges,
  GETObjectFieldChangesParams,
  ObjectDataRecord,
  ObjectFieldChangesDTO,
  POSTObjectData,
  POSTObjectDataRecordEnrichedPositionParams,
  PUTObjectData,
  ReturnObjectDataRecordEnrichedPosition,
} from '../../types'

import { commonAPI } from './common.api'

export const objectAPI = commonAPI.injectEndpoints({
  endpoints: build => ({
    fetchAllObject: build.query<GETAllObject, void>({
      query: () => ({
        url: 'router/models/object/list',
      }),
    }),
    fetchObjectByCode: build.query<GETObject, string | void>({
      query: code => ({
        url: `router/models/object/code/${code}`,
      }),
    }),
    fetchEnumForObject: build.query<GETEnumForObject[], GETObjectEnumParams>({
      query: ({ code, name }) => ({
        url: `router/models/object/code/${code}/field/code/${name}/enums`,
      }),
    }),
    fetchObjectDataByCode: build.mutation<GETObjectData, GETObjectDataParams>({
      query: ({ code, page, size, sort, filter }) => ({
        url: `router/storage/object/list/${code}`,
        params: {
          code,
          page,
          size,
          sort,
          filter,
        },
      }),
      transformResponse: (output: ObjectDataRecord[], meta: FetchBaseQueryMeta) =>
        transformResponseWithPagination<ObjectDataRecord[]>(output, meta),
    }),
    fetchObjectDataFilterByCodeEnriched: build.mutation<GETObjectData, GETObjectDataParamsEnriched>(
      {
        query: ({
          code,
          page,
          size,
          sort,
          filter,
          viewId,
          sortKeysForQuickSearch,
          searchValue,
          searchRule,
          body,
        }) => ({
          url: `router/storage/object/list/${code}/enriched?${getEnrichedSortParam(sort)}`,
          method: 'POST',
          params: {
            code,
            page,
            size,
            viewId,
            filter,
            searchValue,
            searchRule,
            sortKeysForQuickSearch,
          },
          body,
        }),
        extraOptions: {
          showNotification: false,
        },
        transformResponse: (output: ObjectDataRecord[], meta: FetchBaseQueryMeta) =>
          transformResponseWithPagination<ObjectDataRecord[]>(output, meta),
        // providesTags: ['ObjectData'],
      }
    ),
    createObjectDataRecord: build.mutation<ObjectDataRecord, POSTObjectData>({
      query: ({ objectCode, formCode, record }) => ({
        url: `router/storage/object/${objectCode}/form/code/${formCode}`,
        method: 'POST',
        body: { ...record, _id: undefined },
      }),
      invalidatesTags: ['ObjectData'],
    }),
    updateObjectDataRecord: build.mutation<ObjectDataRecord, PUTObjectData>({
      query: ({ objectCode, formCode, record }) => ({
        url: `router/storage/object/${objectCode}/form/code/${formCode}`,
        method: 'POST',
        body: record,
      }),
      invalidatesTags: ['ObjectData', 'AuditData'],
    }),
    fetchObjectDataRecordById: build.query<ObjectDataRecord, GETObjectDataRecordParams>({
      query: ({ objectCode, objectId }) => ({
        url: `router/storage/object/${objectCode}/id/${objectId}`,
      }),
      providesTags: ['ObjectData'],
    }),
    fetchObjectDataRecordEnrichedById: build.mutation<
      ObjectDataRecord[],
      GETObjectDataRecordEnrichedParams
    >({
      query: ({ objectCode, ids, viewId }) => ({
        url: `router/storage/object/${objectCode}/enriched/id`,
        params: { ids, viewId },
      }),
    }),
    fetchObjectDataRecordEnrichedPositionById: build.mutation<
      GETObjectData,
      POSTObjectDataRecordEnrichedPositionParams
    >({
      query: ({ objectCode, body, sort, ...params }) => ({
        url: `router/storage/object/${objectCode}/enriched/positioned/id?${getEnrichedSortParam(
          sort
        )}`,
        method: 'POST',
        params,
        body,
      }),
      transformResponse: (
        output: ReturnObjectDataRecordEnrichedPosition,
        meta: FetchBaseQueryMeta
      ) => transformResponseWithPagination<ReturnObjectDataRecordEnrichedPosition>(output, meta),
    }),
    fetchObjectData: build.mutation<ObjectDataRecord, GETObjectDataRecordParams>({
      query: ({ objectCode, objectId, expands }) => ({
        url: `router/storage/object/${objectCode}/id/${objectId}`,
        params: { expands },
      }),
    }),
    getFetchObjectData: build.query<ObjectDataRecord, GETObjectDataRecordParams>({
      query: ({ objectCode, objectId, expands }) => ({
        url: `router/storage/object/${objectCode}/id/${objectId}`,
        params: { expands },
      }),
    }),
    fetchObjectFieldChanges: build.mutation<GETObjectFieldChanges, GETObjectFieldChangesParams>({
      query: ({ objectCode, objectId, page, size, sort, filter }) => ({
        url: `router/audit/object/${objectCode}/id/${objectId}`,
        params: {
          page,
          size,
          sort,
          filter,
        },
      }),
      // providesTags: ['AuditData'],
      transformResponse: (output: ObjectFieldChangesDTO[], meta: FetchBaseQueryMeta) =>
        transformResponseWithPagination<ObjectFieldChangesDTO[]>(output, meta),
    }),
    fetchListControlOrDropdownObjectDataEnriched: build.mutation<
      GETObjectData,
      GETObjectDataParamsEnriched
    >({
      query: ({
        page,
        size,
        sort,
        formObjectCode,
        elementId,
        valueId,
        fieldId,
        dropDownEntityType,
        formObjectId,
        viewId,
        body,
        filter,
        searchValue,
        searchRule,
        sortKeysForQuickSearch,
      }) => ({
        url: `router/storage/object/list/enriched?${getEnrichedSortParam(sort)}`,
        method: 'POST',
        params: {
          viewId,
          dropDownEntityType,
          page,
          size,
          formObjectCode,
          formObjectId,
          elementId,
          valueId,
          fieldId,
          filter,
          searchValue,
          searchRule,
          sortKeysForQuickSearch,
        },
        body,
      }),
      extraOptions: {
        showNotification: false,
      },
      transformResponse: (output: ObjectDataRecord[], meta: FetchBaseQueryMeta) =>
        transformResponseWithPagination<ObjectDataRecord[]>(output, meta),
      // providesTags: ['ObjectData'],
    }),
    fetchObjectByCodeNoCache: build.mutation<GETObject, string | void>({
      query: ({ code }) => ({
        url: `router/models/object/code/${code}`,
      }),
    }),
  }),
})

export const {
  useFetchAllObjectQuery,
  useFetchObjectByCodeQuery,
  useFetchEnumForObjectQuery,
  useFetchObjectDataFilterByCodeEnrichedMutation,
  useFetchObjectDataByCodeMutation,
  useFetchObjectFieldChangesMutation,
  useCreateObjectDataRecordMutation,
  useUpdateObjectDataRecordMutation,
  useFetchObjectDataRecordByIdQuery,
  useFetchObjectDataMutation,
  useFetchObjectDataRecordEnrichedByIdMutation,
  useFetchObjectDataRecordEnrichedPositionByIdMutation,
  useFetchListControlOrDropdownObjectDataEnrichedMutation,
  useGetFetchObjectDataQuery,
  useFetchObjectByCodeNoCacheMutation,
} = objectAPI
