import { useCallback } from 'react'
import { ACTION_CODE } from '@constants'

import { useExecuteCommand } from '@pages/ConfiduredGantt/hooks/useExecuteCommand'
import { ActionParams } from '@pages/ConfiduredGantt/hooks/useGetHandlerMap'
import { useOpenForm } from '@pages/ConfiduredGantt/hooks/useOpenForm'
import { getData, stopPropagation } from '@pages/ConfiduredGantt/utils'

import {
  AXIS_TYPE,
  GanttActionType,
  GanttFormValues,
} from '@gantt/components/GanttCreateOrEdit/types'

export const useRunAction = (config: GanttFormValues, path: string, id: string) => {
  const openFormModel = useOpenForm(config, path, id)
  const executeCommand = useExecuteCommand(config, path, id)

  return useCallback(
    (action: GanttActionType, data: ActionParams, actions: GanttActionType[], axis: AXIS_TYPE) => {
      switch (action.actionCode) {
        case ACTION_CODE.EXECUTE_COMMAND: {
          executeCommand(action, data, getData[axis](data, config), actions)

          break
        }
        case ACTION_CODE.OPEN_FORM_MODEL: {
          openFormModel(action, data, getData[axis](data, config), actions)

          break
        }
      }

      stopPropagation(data)
    },
    [config?.code, id, path]
  )
}
