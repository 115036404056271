import { FC, useState } from 'react'
import { RegisterOptions, useController, useFormContext } from 'react-hook-form'
import { isNotEmpty } from '@helpers'
import { AutocompleteOption } from '@microservices/wiskey-react-components/dist/AutoComplete'

import { GETObject, ObjectDataRecord } from '../../../types'
import { ObjectPicker, ObjectPickerProps } from '../../ui/ObjectPicker'

type ObjectPickerControllerProps = {
  rules?: RegisterOptions
  name: string
  isDialogWindow: boolean
} & Omit<
  ObjectPickerProps,
  | 'value'
  | 'onChange'
  | 'setValue'
  | 'invalid'
  | 'onLoadObject'
  | 'onChangeInDropdownEntityMode'
  | 'dataValue'
>
export const ObjectPickerController: FC<ObjectPickerControllerProps> = ({
  rules,
  name,
  objectValue = '',
  ...props
}) => {
  const { control, setValue } = useFormContext()
  const {
    field: { onChange, value },
    fieldState: { invalid },
  } = useController({
    name,
    control,
    rules,
  })
  const [objectPickerValue, setObjectPickerValue] = useState<
    AutocompleteOption | AutocompleteOption[] | null
  >(value)
  const [dataValue, setDataValue] = useState('')

  const onLoadObject = (data: ObjectDataRecord, objectModel: GETObject) => {
    if (data) {
      const pkField = objectModel.fields.find(field => field.isPk)
      const id = data[pkField!.name] ?? ''
      const label = data[objectValue] ?? ''

      setDataValue(label)
      setValue(name, id)
      setObjectPickerValue({ label: `${label}`, id })
      return
    }

    setValue(name, null)
    setObjectPickerValue(null)
  }

  const handleChange = (value: AutocompleteOption | AutocompleteOption[] | null) => {
    if (!Array.isArray(value) && isNotEmpty(value?.id)) {
      setObjectPickerValue(value)

      onChange(value?.id)
      return
    }
    onChange(null)
  }

  const handleChangeInDropdownEntityMode = (objectId: string) => {
    onChange(objectId)
  }

  const handleChangeInput = (value: string) => {
    if (!value) {
      onChange(value)
    }
  }

  return (
    <ObjectPicker
      dataValue={dataValue}
      invalid={invalid}
      name={name}
      objectValue={objectValue}
      value={objectPickerValue || value}
      onChange={handleChange}
      onChangeInDropdownEntityMode={handleChangeInDropdownEntityMode}
      onChangeInput={handleChangeInput}
      onLoadObject={onLoadObject}
      {...props}
    />
  )
}
