import { useEffect, useMemo } from 'react'
import { RouteObject, useNavigate, useRoutes } from 'react-router-dom'
import i18next from 'i18next'
import moment from 'moment-timezone'
import { COMMON_PARAMETERS, ELEMENT_TYPE, ENTITY, ROUTES, TIMEZONES, USER_ROLES } from '@constants'
import { getLocalStorageContext, getOptionRoute, transformCommonParametersToValues } from '@helpers'
import { useAppSelector, useAuth } from '@hooks'
import { GETControlPanelLineItem } from '@types'

import { ConfiguredGantt } from '@pages/ConfiduredGantt'
import { ConfigContextMenu } from '@pages/ConfigContextMenu'
import { ConfigControlPanels } from '@pages/ConfigControlPanels'
import { Dictionary } from '@pages/ConfigDictionary'
import { ConfigEntity } from '@pages/ConfigEntity'
import { ConfigForms } from '@pages/ConfigForms'
import { ConfiguredEntityPage } from '@pages/ConfiguredEntityPage'
import { ContextCreateOrEdit } from '@pages/ContextCreateOrEdit'
import { ContextMenuCreateOrEdit } from '@pages/ContextMenuCreateOrEdit'
import { Contexts } from '@pages/Contexts'
import { ControlPanelsCreateOrEdit } from '@pages/ControlPanelsCreateOrEdit'
import { FormCreateOrEdit } from '@pages/FormCreateOrEdit'
import { FormPage } from '@pages/FormPage'
import { Parameters } from '@pages/Parameters'

import { ChooseContext } from '@components/ChooseContext'
import { SwitchRouter } from '@components/SwitchRouter'

import { useFetchContextControlPanelByIdNoCacheMutation } from '@redux/api'
import { useFetchParameterPropertiesQuery } from '@redux/api/parameters.api'
import { AvailableGanttType } from '@redux/reducers/availableGantts.reducer'

import { AppLayout } from '@layouts/AppLayout'

import GanttChart, { GanttConfiguration, GanttCreateOrEdit } from '@gantt/GanttConfigLazy'

import { generateEntityChildrenRoutes } from './helpers'

export const MainRoute = () => {
  const { hasRole } = useAuth()
  const navigate = useNavigate()
  const context = getLocalStorageContext()

  const { gantts: availableGantts } = useAppSelector(state => state.availableGantts)

  const [fetchControlPanel, { data: menu }] = useFetchContextControlPanelByIdNoCacheMutation({
    fixedCacheKey: 'initial-context',
  })

  useEffect(() => {
    if (context) {
      fetchControlPanel(context?.id)
        .unwrap()
        .catch(error => {
          if (error.status === 404) {
            localStorage.removeItem('context')
            navigate(ROUTES.CHOOSE_CONTEXT)
          }
        })
    }
  }, [context?.id])

  const { data: commonParameters } = useFetchParameterPropertiesQuery({
    code: COMMON_PARAMETERS.STATE,
  })
  const initialCommonParameters = useMemo(
    () => transformCommonParametersToValues(commonParameters),
    [commonParameters]
  )

  useEffect(() => {
    if (initialCommonParameters) {
      moment.tz.setDefault(initialCommonParameters.timezone || TIMEZONES.MOSCOW)
      moment.updateLocale('en', {
        week: {
          dow: 1,
        },
      })
    }
  }, [initialCommonParameters])

  const addGanttRoutes = (gantts: AvailableGanttType[], routes: RouteObject[] = []) => {
    gantts.forEach(gantt => {
      routes.push({
        path: gantt.path,
        element: (
          <ConfiguredGantt
            key={gantt.path}
            entityCode={`${gantt.id}`}
            entityId={gantt.id}
            path={gantt.path}
            timezone={initialCommonParameters.timezone}
            title={gantt.title}
            type={ENTITY.GANTT_CHART}
          />
        ),
        children: [
          {
            path: '/' + gantt.path + ROUTES.FORM,
            element: <FormPage />,
          },
        ],
      })
    })

    return routes
  }

  // Создание роутов для динамически конфигурируемых страниц
  const createConfiguredRoutes = (menu: GETControlPanelLineItem[], routes: RouteObject[] = []) => {
    menu.forEach(menuItem => {
      const path = getOptionRoute(menuItem)

      if (menuItem.elementId && menuItem.elementType === ELEMENT_TYPE.VIEW) {
        routes.push({
          path,
          element: (
            <ConfiguredEntityPage
              entityCode={menuItem.elementCode || null}
              entityId={menuItem.elementId || null}
              title={menuItem.elementTitle}
              type={ENTITY.VIEW}
              path={path}
              // For rerender
              key={path}
            />
          ),
          children: [
            // TODO открывается форма вместо View, на той же странице в Outlet.
            //  Неизвестно потребуется ли этот функционал после доработки с открытием Form и View через диалоговые окна.
            {
              path: '/' + path + ROUTES.FORM,
              element: <FormPage />,
            },
          ],
        })
      }

      if (menuItem.elementId && menuItem.elementType === ELEMENT_TYPE.GANTT_CHART) {
        routes.push({
          path,
          element: (
            <ConfiguredGantt
              key={path}
              entityCode={menuItem.elementCode}
              entityId={menuItem.elementId!}
              path={path}
              timezone={initialCommonParameters.timezone}
              title={menuItem.elementTitle}
              type={ENTITY.GANTT_CHART}
            />
          ),
          children: [
            {
              path: '/' + path + ROUTES.FORM,
              element: <FormPage />,
            },
          ],
        })
      }

      if (menuItem.childrenMenu) {
        createConfiguredRoutes(menuItem.childrenMenu, routes)
      }
    })

    return routes
  }

  const renderByRoles = (roles: USER_ROLES[], routes: RouteObject[]): RouteObject[] => {
    return hasRole(roles) ? routes : []
  }

  const routes: RouteObject[] = useMemo(
    () => [
      ...renderByRoles(
        [USER_ROLES.ADMIN],
        [
          {
            element: <Contexts />,
            path: ROUTES.CONFIG_CONTEXTS,
            children: [
              {
                path: ROUTES.CONFIG_CONTEXTS_CREATE,
                element: <ContextCreateOrEdit />,
              },
              {
                path: ROUTES.CONFIG_CONTEXTS_EDIT_ID,
                element: <ContextCreateOrEdit />,
              },
            ],
          },
          {
            element: <ConfigEntity type={ENTITY.VIEW} />,
            path: ROUTES.CONFIG_VIEWS,
            children: generateEntityChildrenRoutes(ENTITY.VIEW),
          },
          {
            element: <ConfigEntity type={ENTITY.LIST_CONTROL} />,
            path: ROUTES.CONFIG_LIST_CONTROLS,
            children: generateEntityChildrenRoutes(ENTITY.LIST_CONTROL),
          },
          {
            element: <GanttChart />,
            path: ROUTES.CONFIG_GANTT,
            children: [
              {
                path: ROUTES.CONFIG_GANTT_CREATE,
                element: <GanttCreateOrEdit />,
                children: [
                  {
                    element: <GanttConfiguration />,
                    index: true,
                  },
                ],
              },
              {
                path: ROUTES.CONFIG_GANTT_EDIT,
                element: <GanttCreateOrEdit />,
                children: [
                  {
                    element: <GanttConfiguration />,
                    index: true,
                    path: ROUTES.CONFIG_GANTT_EDIT_CODE,
                  },
                ],
              },
            ],
          },
          {
            element: <ConfigEntity type={ENTITY.DROP_DOWN_ENTITY} />,
            path: ROUTES.CONFIG_DROP_DOWN_ENTITY,
            children: generateEntityChildrenRoutes(ENTITY.DROP_DOWN_ENTITY),
          },
          {
            element: <ConfigForms />,
            path: ROUTES.CONFIG_FORMS,
            children: [
              {
                path: ROUTES.CONFIG_FORMS_CREATE,
                element: <FormCreateOrEdit />,
              },
              {
                path: ROUTES.CONFIG_FORMS_EDIT_CODE,
                element: <FormCreateOrEdit />,
              },
            ],
          },
          {
            element: <ConfigControlPanels />,
            path: ROUTES.CONFIG_CONTROL_PANELS,
            children: [
              {
                path: ROUTES.CONFIG_CONTROL_PANELS_CREATE,
                element: <ControlPanelsCreateOrEdit />,
              },
              {
                path: ROUTES.CONFIG_CONTROL_PANELS_EDIT_ID,
                element: <ControlPanelsCreateOrEdit />,
              },
            ],
          },
          {
            element: <ConfigContextMenu />,
            path: ROUTES.CONFIG_CONTEXT_MENU,
            children: [
              {
                path: ROUTES.CONFIG_CONTEXT_MENU_CREATE,
                element: <ContextMenuCreateOrEdit />,
              },
              {
                path: ROUTES.CONFIG_CONTEXT_MENU_EDIT_ID,
                element: <ContextMenuCreateOrEdit />,
              },
            ],
          },
          {
            element: <Dictionary />,
            path: ROUTES.DICTIONARY,
          },
          // {
          //   element: <SearchAssistant />,
          //   path: ROUTES.SEARCH_ASSISTANT,
          // },
          {
            element: <Parameters />,
            path: ROUTES.PARAMETERS,
          },
        ]
      ),
      {
        element: (
          <ConfiguredEntityPage
            path={ROUTES.SEARCH}
            title={i18next.t('searchAssistant.title')}
            type={ENTITY.VIEW}
          />
        ),
        path: ROUTES.SEARCH,
        children: [
          {
            path: '/' + ROUTES.SEARCH + ROUTES.FORM,
            element: <FormPage />,
          },
        ],
      },
      ...(menu?.panel?.lines
        ? menu.panel.lines.map(line => createConfiguredRoutes(line.items)).flat()
        : []),
      ...addGanttRoutes(availableGantts),
      {
        element: <SwitchRouter {...initialCommonParameters} />,
        path: '*',
      },
      {
        element: <ChooseContext />,
        path: ROUTES.CHOOSE_CONTEXT,
      },
      ...(!initialCommonParameters.ganttChart
        ? [
            {
              path: ROUTES.EMPTY,
            },
          ]
        : [{ path: initialCommonParameters.ganttChart }]),
    ],
    [menu, initialCommonParameters, availableGantts]
  )

  const appLayout = <AppLayout>{useRoutes(routes)}</AppLayout>

  return appLayout
}
