import {
  GANTT_BIND_TYPE_LIST,
  SectionType,
} from '@pages/GanttChart/components/GanttCreateOrEdit/types'

export const getGanttSegmentDefaultValue = (): SectionType => {
  return {
    id: '',
    title: {
      bindType: GANTT_BIND_TYPE_LIST.STATIC,
      static: '',
    },
    tooltip: {
      bindType: GANTT_BIND_TYPE_LIST.FIELD_ARRAY,
      field_array: [{ pathStr: '', pathArray: [], field: '' }],
    },
    datetimeStart: {
      bindType: GANTT_BIND_TYPE_LIST.FIELD,
      field: {
        field: '',
        pathStr: '',
        pathArray: [],
      },
    },
    datetimeEnd: {
      bindType: GANTT_BIND_TYPE_LIST.FIELD,
      field: {
        field: '',
        pathStr: '',
        pathArray: [],
      },
    },
    duration: {
      bindType: GANTT_BIND_TYPE_LIST.FIELD,
      field: {
        field: '',
        pathStr: '',
        pathArray: [],
      },
    },
    link: {
      axisY: {
        bindType: GANTT_BIND_TYPE_LIST.FIELD,
        field: {
          field: '',
          pathStr: '',
          pathArray: [],
        },
      },
      axisX: {
        bindType: GANTT_BIND_TYPE_LIST.FIELD,
        field: {
          field: '',
          pathStr: '',
          pathArray: [],
        },
      },
      resourceKeyField: 'sectionResource_',
      resourceLinkField: 'sectionLink_',
      systemName: '',
    },
    background: {
      bindType: GANTT_BIND_TYPE_LIST.STATIC,
      static: '',
    },
    labels: {
      leftTopTitle: {
        bindType: GANTT_BIND_TYPE_LIST.STATIC,
        static: '',
      },
      bottomTitle: {
        bindType: GANTT_BIND_TYPE_LIST.STATIC,
        static: '',
      },
      leftBottomTitle: {
        bindType: GANTT_BIND_TYPE_LIST.STATIC,
        static: '',
      },
      rightBottomTitle: {
        bindType: GANTT_BIND_TYPE_LIST.STATIC,
        static: '',
      },
      rightTopTitle: {
        bindType: GANTT_BIND_TYPE_LIST.STATIC,
        static: '',
      },
      topTitle: {
        bindType: GANTT_BIND_TYPE_LIST.STATIC,
        static: '',
      },
    },
  }
}
