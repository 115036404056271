import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import { t } from 'i18next'
import { BIND_TYPE } from '@constants'
import { getColumnParametersFromArray, isObjectValueType } from '@helpers'
import { usePrompt } from '@hooks'
import { ObjectFieldDTO, ParameterPropertyType } from '@types'

import { transformTextParamsData } from '@pages/Parameters/helpers'

import { GetTextParamsHandle } from '@components/TextSettings'

import { useFetchObjectByCodeQuery } from '@redux/api'

import { GanttColumnForm } from '@gantt/components/GanttCreateOrEdit/types'

import { PageContext } from '../../../GanttCreateOrEdit'
import { getGanttColumnDefaultValue } from '../helpers/getGanttColumnDefaultValue'

import { useHandlers } from './useHandlers'
import { useInputs } from './useInputs'

export const useAddGanttFieldDialog = () => {
  const defaultValues: GanttColumnForm = getGanttColumnDefaultValue()
  const methods = useForm<GanttColumnForm>({
    defaultValues: defaultValues,
  })
  const columnHeaderSettingsRef = useRef<GetTextParamsHandle>(null)
  const columnTextSettingsRef = useRef<GetTextParamsHandle>(null)
  const [isLoading, setLoading] = useState(true)
  const [objectField, setObjectField] = useState<ObjectFieldDTO>()

  const {
    handleSubmit,
    reset,
    formState: { isDirty: isDirtyBasicParameters },
    setValue,
    clearErrors,
    trigger,
    watch,
    getValues,
  } = methods

  const watchBinding = watch('bindType')
  const watchValue = watch('value')
  const watchPinning = watch('pinning')
  const watchUseParameters = watch('useParameters')

  const valueType = watch('field.field.pathArray')?.at(-1)?.valueType || null
  const objectCode = objectField?.model ?? ''
  const isObjectLinkedValue = objectField?.type === 'object_link'

  const {
    currentRow,
    onSetModalProperty,
    objectCodeResource: viewObjectCode,
    rawObjectFields,
    bindingValuesColumns,
  } = useContext(PageContext)

  const { data: objectForField } = useFetchObjectByCodeQuery(objectCode, { skip: !objectCode })

  const { headerProperties, textProperties } = getColumnParametersFromArray(
    currentRow?.parameters || []
  )

  const transformedHeaderProperties = useMemo(() => {
    const arr = []
    if (headerProperties) {
      for (const [key, value] of Object.entries(headerProperties)) {
        arr.push({ key: key, value: value })
      }
    }
    return arr
  }, [headerProperties])

  const transformedTextProperties = useMemo(() => {
    const arr = []
    if (textProperties) {
      for (const [key, value] of Object.entries(textProperties)) {
        arr.push({ key: key, value: value, num: 1, id: 1 })
      }
    }
    return arr
  }, [textProperties])

  const initialColumnHeaderStyles = useMemo(() => {
    return transformTextParamsData(transformedHeaderProperties as any as ParameterPropertyType[])
  }, [currentRow])
  const initialColumnTextStyles = useMemo(() => {
    return transformTextParamsData(transformedTextProperties as any as ParameterPropertyType[])
  }, [currentRow])

  const filterObjectValueOptions = useCallback(
    (fields: ObjectFieldDTO[]): ObjectFieldDTO[] => {
      return fields
    },
    [watchValue, currentRow]
  )

  const objectValueOptions = useMemo(
    () =>
      filterObjectValueOptions(objectForField?.fields || []).map(obj => ({
        id: obj.id,
        name: obj.name,
      })) ?? [],
    [filterObjectValueOptions, objectForField?.fields]
  )

  const filterObjectFields = (fields?: ObjectFieldDTO[]): ObjectFieldDTO[] =>
    fields?.filter(field => {
      if (isObjectValueType(field.valueType)) {
        return true
      }
    }) || []

  const filteredObjectFields = useMemo(
    () => filterObjectFields(rawObjectFields),
    [rawObjectFields, objectValueOptions]
  )

  const modifyObjectFields = useMemo(
    () => filteredObjectFields.map(obj => ({ id: obj.id, name: obj.name })),
    [filteredObjectFields]
  )

  const currentValueObj = rawObjectFields?.find(obj => obj.name === currentRow?.value)
  const valueOptions = useMemo(
    () => [...modifyObjectFields, ...(currentValueObj ? [currentValueObj] : [])],
    [modifyObjectFields, currentValueObj]
  )

  const bindTypeOptions = useMemo(
    () =>
      bindingValuesColumns?.map(cat => ({
        id: cat.id,
        name: cat.code,
      })),
    [bindingValuesColumns, valueOptions]
  )

  useEffect(() => {
    const field = rawObjectFields?.find(field => field.name === watchValue)
    setObjectField(field ? field : undefined)
    clearErrors()

    if (currentRow?.value === field?.name) {
      setValue('objectValue', currentRow?.objectValue)
      return
    }

    if (watchBinding !== BIND_TYPE.JS && watchBinding !== BIND_TYPE.JSX) {
      setValue('objectValue', t('placeholder.objectValue'))
    }
  }, [rawObjectFields, watchValue, currentRow, objectCode])

  const triggerValidation = async () => await trigger('objectValue')

  useEffect(() => {
    if (watchValue && objectValueOptions.length === 0) {
      triggerValidation()
    }
  }, [objectValueOptions])

  useEffect(() => {
    if (currentRow) {
      reset({
        ...currentRow,
        valueType,
        order: currentRow.order?.toString(),
        pinnedColumn: currentRow.pinnedColumn
          ? { id: currentRow.pinnedColumn, label: currentRow.pinnedColumn }
          : null,
        pinning: Boolean(currentRow.pinnedColumn),
        useParameters: Boolean(currentRow?.useLocalParameters),
      })

      setLoading(false)
    }
  }, [currentRow])

  useEffect(() => {
    onSetModalProperty?.('column')
  }, [])

  useEffect(() => {
    if (watchBinding === BIND_TYPE.JS || watchBinding === BIND_TYPE.JSX) {
      const value =
        currentRow?.value &&
        (currentRow.bindType === BIND_TYPE.JS || currentRow?.bindType === BIND_TYPE.JSX)
          ? currentRow.value
          : ''

      setValue('value', value)
    }

    if (watchBinding === BIND_TYPE.FIELD && !watchValue) {
      setValue('value', t('placeholder.value'))
    }
  }, [watchBinding])

  const { state, handlers } = useHandlers({
    watchBinding,
    watchUseParameters,
    isDirty: isDirtyBasicParameters,
    isObjectLinkedValue,
    handleSubmit,
    viewObjectCode: viewObjectCode || '',
    columnHeaderSettingsRef,
    columnTextSettingsRef,
    initialColumnHeaderStyles,
    initialColumnTextStyles,
    getValues,
    valueType,
  })

  const { isTextParamsDirty, isShowResetConfirmModal } = state
  const {
    handleSave,
    handleTextExternalDirty,
    handleHeaderExternalDirty,
    handleSetValuesColumnStyles,
    handleClickReset,
    handleResetParams,
    handleSetShowResetConfirmModal,
  } = handlers

  const isDirty = isDirtyBasicParameters || isTextParamsDirty

  usePrompt({ when: isDirty })

  const { columnInputs, otherColumnInputs } = useInputs({
    watchBinding,
    valueOptions,
    bindTypeOptions: bindTypeOptions || [],
    valueType,
    isObjectLinkedValue,
    objectValueOptions,
    watchPinning,
    currentRow,
  })
  return {
    isDirty,
    handleSave,
    isLoading,
    methods,
    columnInputs,
    otherColumnInputs,
    columnHeaderSettingsRef,
    columnTextSettingsRef,
    initialColumnHeaderStyles,
    initialColumnTextStyles,
    watchUseParameters,
    isShowResetConfirmModal,
    handleTextExternalDirty,
    handleHeaderExternalDirty,
    handleSetValuesColumnStyles,
    handleClickReset,
    handleResetParams,
    handleSetShowResetConfirmModal,
    watchBinding,
    bindTypeOptions,
  }
}
