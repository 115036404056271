export enum GanttEvents {
  SELF_UPDATED = 'SELF_UPDATED',
}

export type DetailSelfUpdate = {
  timelineIds: string[]
  objectCode: string
}

export function dispatchSelfUpdate(data: Record<string, string>) {
  window.dispatchEvent(
    new CustomEvent<DetailSelfUpdate>(GanttEvents.SELF_UPDATED, {
      detail: {
        timelineIds: [data.timelineId],
        objectCode: data.objectCode,
      },
    })
  )
}
