import { useContext, useMemo } from 'react'
import { UseFormReturn } from 'react-hook-form'
import i18next from 'i18next'
import { ACTION_CODE, EVENT_CODE_GANTT, GENERATOR_INPUT_TYPE, REGEX } from '@constants'
import { FormInputsType } from '@microservices/wiskey-react-components/dist/types'
import { SelectOption } from '@types'

import { PageContext } from '@gantt/components/GanttCreateOrEdit'
import { GANTT_BIND_TYPE_LIST, GanttActionType } from '@gantt/components/GanttCreateOrEdit/types'
import {
  getAvailableSegmentTarget,
  getAvailableXEvents,
  getBindTypeOptionsByStrings,
  getOptionByStr,
  isSegmentTarget,
} from '@gantt/helpers'

type useInputsParams = {
  actionTypeOptions: SelectOption[]
  eventOptions: SelectOption[]
  watchEventCode: string
  targetOptions: SelectOption[]
  methods: UseFormReturn<GanttActionType, any, undefined>
  watchActionCode?: ACTION_CODE
  commandOptions?: { id: string; label: string; default?: boolean }[]
}

export const useInputs = ({
  actionTypeOptions,
  eventOptions,
  watchEventCode,
  targetOptions,
  methods,
  watchActionCode,
  commandOptions,
}: useInputsParams) => {
  const { modalType, currentActionTimelineList, currentRow } = useContext(PageContext)
  const { setValue, resetField } = methods

  const handleChangeActionCode = (value: string | number) => {
    const getBind = () => {
      switch (value) {
        case ACTION_CODE.OPEN_CONTEXT_MENU:
          return GANTT_BIND_TYPE_LIST.JSON
        case ACTION_CODE.EXECUTE_COMMAND:
          return GANTT_BIND_TYPE_LIST.COMMAND
        default:
          return null
      }
    }

    if (value === ACTION_CODE.EXECUTE_COMMAND) {
      const defaultCommandOption = commandOptions?.find(item => item.default)
      if (defaultCommandOption) {
        setValue(`actionField.command`, {
          id: defaultCommandOption.id,
          label: defaultCommandOption.label,
        })
      }
    }

    const bind = getBind()

    resetField('eventCode')
    setValue('actionField.bindType', bind)
  }

  const eventOptionsList: SelectOption[] = useMemo(() => {
    if (watchActionCode === ACTION_CODE.OPEN_CONTEXT_MENU) {
      return getAvailableXEvents({
        actionEventsList: getBindTypeOptionsByStrings([EVENT_CODE_GANTT.ON_BAR_RIGHT_CLICK]),
        actionsList: currentActionTimelineList,
        currentRow,
      })
    }

    if (watchActionCode === ACTION_CODE.OPEN_TOOLTIP) {
      return [
        //todo next stage
        //on_tooltip_bar
        //on_tooltip_segment
      ]
    }

    if (watchActionCode === ACTION_CODE.EXECUTE_COMMAND) {
      return getAvailableXEvents({
        actionEventsList: getBindTypeOptionsByStrings([
          EVENT_CODE_GANTT.ON_SEGMENT_RESIZE,
          EVENT_CODE_GANTT.ON_BACKLOG_SEGMENT_RESIZE,
          EVENT_CODE_GANTT.ON_SEGMENT_INNER_MOVE,
          EVENT_CODE_GANTT.ON_SEGMENT_OUTER_MOVE,
          EVENT_CODE_GANTT.ON_BACKLOG_SEGMENT_INNER_MOVE,
          EVENT_CODE_GANTT.ON_BACKLOG_SEGMENT_OUTER_MOVE,
        ]).concat(eventOptions),
        actionsList: currentActionTimelineList,
        currentRow,
      })
    }
    return getAvailableXEvents({
      actionEventsList: eventOptions,
      actionsList: currentActionTimelineList,
      currentRow,
    })
  }, [watchActionCode])

  const actionCodeOptions = useMemo(
    () => [
      ...actionTypeOptions,
      getOptionByStr(ACTION_CODE.OPEN_CONTEXT_MENU),
      // getOptionByStr(ACTION_CODE.OPEN_TOOLTIP), //todo next stage
      getOptionByStr(ACTION_CODE.EXECUTE_COMMAND),
    ],
    [actionTypeOptions]
  )

  const actionInputs: FormInputsType[] = [
    {
      name: 'code',
      inputType: GENERATOR_INPUT_TYPE.INPUT,
      placeholder: i18next.t('actionInputs.code.placeholder'),
      replacePattern: REGEX.MODEL_CODE_REPLACE_PATTERN,
      label: i18next.t('actionInputs.code.label'),
      disabled: modalType === 'edit',
      rules: { required: true, validate: value => value.trim().length !== 0 },
    },
    {
      name: 'title',
      inputType: GENERATOR_INPUT_TYPE.INPUT,
      placeholder: i18next.t('actionInputs.title.placeholder'),
      label: i18next.t('actionInputs.title.label'),
      rules: { required: true, validate: value => value.trim().length !== 0 },
    },
    {
      name: 'actionCode',
      inputType: GENERATOR_INPUT_TYPE.SELECT,
      label: i18next.t('actionInputs.actionCode.label'),
      placeholder: i18next.t('placeholder.actionType'),
      selectOptions: actionCodeOptions,
      rules: { required: true, validate: value => value !== i18next.t('placeholder.actionType') },
      onChangeSelect: handleChangeActionCode,
    },
    {
      name: 'eventCode',
      inputType: GENERATOR_INPUT_TYPE.SELECT,
      label: i18next.t('actionInputs.eventCode.label'),
      placeholder: i18next.t('placeholder.event'),
      selectOptions: eventOptionsList,
      rules: { required: true, validate: value => value !== i18next.t('placeholder.event') },
    },
    {
      name: 'targetRow',
      inputs: isSegmentTarget(watchEventCode)
        ? [
            {
              name: 'target',
              inputType: GENERATOR_INPUT_TYPE.SELECT,
              label: i18next.t('actionInputs.segment.label'),
              placeholder: i18next.t('actionInputs.segment.placeholder'),
              selectOptions: getAvailableSegmentTarget({
                segmentTargets: targetOptions,
                actionsList: currentActionTimelineList,
                currentRow,
                currentEventCode: watchEventCode,
              }),
              rules: { required: true },
            },
          ]
        : [],
    },
  ]

  return actionInputs
}
