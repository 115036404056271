import { BindField, GANTT_BIND_TYPE_LIST } from '@gantt/components/GanttCreateOrEdit/types'

export function getParamsString(params: BindField) {
  switch (params?.bindType) {
    case GANTT_BIND_TYPE_LIST.STATIC: {
      return params.static
    }
    case GANTT_BIND_TYPE_LIST.JSON:
      if (params?.json) {
        return JSON.parse(params.json)
      }

      break
    case GANTT_BIND_TYPE_LIST.JS:
      if (params?.js) {
        return params.js
      }

      break
    case GANTT_BIND_TYPE_LIST.COMMAND:
      if (params?.command) {
        return params.command
      }

      break
    case GANTT_BIND_TYPE_LIST.FORM:
      if (params?.form) {
        return params.form
      }

      break
    case GANTT_BIND_TYPE_LIST.FIELD: {
      return params?.field?.field
    }
  }
}

export function getParamsStringSafe(params: BindField) {
  try {
    return getParamsString(params)
  } catch (e) {
    console.error('Bad Data', params)
    return undefined
  }
}
